import React, { useEffect, useState } from "react";
import { Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AsyncThunk } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { AppDispatch } from "../../../app/store";
import {
  SelectedZoneOrWorkplace,
  SelectedZoneOrWorkplaceType,
  setCapacitySelectedFloors,
  setIsSelected,
  setLoading,
  setSelectedBookingType,
  setSelectedDepartmentIds,
  setSelectedTimeframe,
  setSelectedWeekdays,
  setSelectedZoneOrWorkplace
} from "../../../features/Reports/slices/report.slice";
import { CapacityObject } from "../../../features/Reports/typings/reports.types";
import SelectionChips from "../selection-chips.component";
import { useRemoteFetchFloorReport } from "../../../hooks/Remote/Floor/useRemoteFetchFloorReport";
import DefaultSpinner from "../../LoadingSpinner/default-spinner.component";
import useDateSelector from "../../../hooks/useDateSelector/useDateSelector";
import { StartDatePicker } from "../DateSelector/DatePickers/StartDatePicker";
import { EndDatePicker } from "../DateSelector/DatePickers/EndDatePicker";
import { FetchReportParams, fetchReport } from "../../../features/Reports/thunks/report.thunk";
import { BookingType } from "../../../features/Booking-Form/typings/booking-inputs";
import BookingTypeSelector from "./booking-type-selector.partial";
import { Weekdays } from "../../Pickers/WeekdayPicker/weekday-picker.component";
import { useRemoteFetchUserInfoForAllUsers } from "../../../hooks/Remote/User/UserInfo/useRemoteFetchUserInfoForAllUsers";
import { WeekdaysSelector } from "./weekdays-selector.component";
import { DepartmentSelector } from "./department-selector.component";
import * as _ from "lodash";
import FloorSingleSelector from "../floor-single-selector.component";

type FilterTypState = "floor" | "location" | SelectedZoneOrWorkplaceType | undefined;
type P = { zoneBooking: boolean; entityData: (CapacityObject | undefined)[] | undefined };

const DateAndTypeSelector: React.FC<P> = ({ zoneBooking, entityData }) => {
  const {
    currentFloors,
    initialTimeframe,
    zoneRestrictions,
    isLoading,
    calculateWithoutCosts,
    selectedTimeframe,
    selectedZoneOrWorkplace,
    selectedBookingType,
    selectedWeekdays,
    selectedDepartmentIds
  } = useSelector((state: RootState) => state.report);
  const {
    userInformation: { sub, placeTypes, zoneTypes },
    userRoles: { isHrAdmin }
  } = useSelector((state: RootState) => state.login);

  const { t } = useTranslation();
  const theme = useTheme();
  const isBrowser = useMediaQuery(theme.breakpoints.up(768));
  const dispatch = useDispatch();

  const [fetchEnabled, setFetchEnabled] = useState(false);
  const [filterTyp, setFilterTyp] = useState<FilterTypState[]>(["floor", undefined]);

  // used for only internal time change with date selector
  const [changeTime, setChangeTime] = useState({
    timeframeStart: selectedTimeframe?.timeframeStart || DateTime.local().toISO(),
    timeframeEnd: selectedTimeframe?.timeframeEnd || DateTime.local().toISO()
  });

  const [departmentOpt, setDepartmentOpt] = useState<{ id: string; name: string }[]>([]);
  const [selectedDepartmentFilter, setSelectedDepartmentFilter] = useState<string[]>([]);

  const basicParams = {
    userId: sub,
    calculateWithoutCosts: true,
    calcPerDay: true,
    calcPerWeekday: true,
    calcPerWorkplace: false, // filterTyp !== "location",
    calcPerHour: true,
    reportType: "capacityManager",
    start: selectedTimeframe?.timeframeStart as string,
    end: selectedTimeframe?.timeframeEnd as string,
    companyId: zoneRestrictions?.company?.id,
    weekdays: selectedWeekdays
  };

  const defaultParams = {
    cost: calculateWithoutCosts,
    sub: sub,
    start: selectedTimeframe?.timeframeStart as string,
    end: selectedTimeframe?.timeframeEnd as string,
    filterType: filterTyp,
    zoneBooking: zoneBooking,
    companyId: zoneRestrictions?.company?.id,
    zoneBookingObject: zoneRestrictions?.object?.zoneBookingObject,
    status: true,
    update: false,
    weekdays: selectedWeekdays
  };

  const availableTypes = [
    ...placeTypes.filter(typ => typ.id === 1 || 2 || 4),
    ...zoneTypes.filter(typ => typ.id === 3)
  ];

  const { data: firstFloorReport, refetch: refetchFirstFloorReport } = useRemoteFetchFloorReport(
    {
      ...basicParams,
      "filter[]":
        selectedZoneOrWorkplace &&
        selectedZoneOrWorkplace.open &&
        selectedZoneOrWorkplace.floorInventoryId === currentFloors[0].id
          ? selectedZoneOrWorkplace.id
          : [currentFloors[0]?.id],
      filterType: filterTyp[0] ?? "floor",
      bookingType: selectedBookingType ?? null,
      departmentIds: selectedDepartmentIds,
      weekdays: selectedWeekdays
    },
    false
  );

  const { data: secondFloorReport, refetch: refetchSecondFloorReport } = useRemoteFetchFloorReport(
    {
      ...basicParams,
      "filter[]":
        selectedZoneOrWorkplace &&
        selectedZoneOrWorkplace.open &&
        currentFloors.length > 1 &&
        selectedZoneOrWorkplace.floorInventoryId === currentFloors[1].id
          ? selectedZoneOrWorkplace.id
          : [currentFloors[1]?.id],
      filterType: filterTyp[1] ?? "floor",
      bookingType: selectedBookingType ?? null,
      departmentIds: selectedDepartmentIds,
      weekdays: selectedWeekdays
    },
    false
  );

  const { fetchingFinished, fetchReports } = useDateSelector(
    refetchFirstFloorReport,
    refetchSecondFloorReport
  );

  const { data: remoteUsers } = useRemoteFetchUserInfoForAllUsers();

  const handleDepartmentSelect = (event: any, value: { id: string; name: string }[]) => {
    setSelectedDepartmentFilter(value.map(val => val.id));
    setFetchEnabled(true);
  };

  useEffect(() => {
    // have workplace booking type always preselected
    // if workplace does not exist preselect the first existing booking type in the list
    dispatch(setSelectedBookingType(availableTypes[0].name as BookingType));
  }, []);

  useEffect(() => {
    if (firstFloorReport != undefined) {
      dispatch(
        setCapacitySelectedFloors({
          id: currentFloors[0].id,
          capacity: firstFloorReport as object
        } as CapacityObject)
      );
    }
  }, [firstFloorReport]);

  useEffect(() => {
    if (secondFloorReport != undefined) {
      dispatch(
        setCapacitySelectedFloors({
          id: currentFloors[1].id,
          capacity: secondFloorReport as object
        } as CapacityObject)
      );
    }
  }, [secondFloorReport]);

  useEffect(() => {
    if (fetchingFinished(firstFloorReport, secondFloorReport, currentFloors)) {
      dispatch(setLoading(false));
      if (!isLoading) setFetchEnabled(false);
    }
  }, [firstFloorReport, secondFloorReport, isLoading]);

  useEffect(() => {
    refetchCapaReportWithSelected({
      defaultParams,
      currentFloors,
      fetchReport,
      selectedZoneOrWorkplace,
      selectedWeekdays,
      setFilterTyp,
      dispatch
    });
  }, [selectedZoneOrWorkplace, selectedTimeframe]);

  useEffect(() => {
    if (!remoteUsers) return;

    const bothFloorsUsersData = entityData?.flatMap(data => data?.capacity.distinctTotalUsers);
    const reportedUsers = remoteUsers.filter(user =>
      bothFloorsUsersData?.some(data => data?.includes(user.userId))
    );

    if (reportedUsers.length > 0) {
      const reportedDepartments = _.uniqBy(
        reportedUsers.map(user => ({
          id: user.departmentId ?? "null",
          name: user.departmentName ?? t("No Department")
        })),
        department => department.name
      );
      setDepartmentOpt(reportedDepartments);
    } else setDepartmentOpt([]);
  }, [remoteUsers, entityData]);

  return (
    <>
      {selectedTimeframe && (
        <>
          <Grid
            container
            data-testid="selector-container"
            alignItems={"center"}
            direction={isBrowser ? "row" : "column"}
            margin={isBrowser ? "" : "auto"}
          >
            {/* floor single selector before report filters */}
            <FloorSingleSelector zoneBooking={zoneBooking} />

            {/* start date picker */}
            <Grid item>
              <StartDatePicker
                selectedTimeframe={selectedTimeframe}
                initialTimeframe={initialTimeframe}
                onEnableFetch={() => setFetchEnabled(true)}
                changeTime={changeTime}
                setChangeTime={({ start, end }: { start: string; end: string }) =>
                  setChangeTime({ timeframeStart: start, timeframeEnd: end })
                }
              />
            </Grid>

            {/* end date picker */}
            <Grid item>
              <EndDatePicker
                selectedTimeframe={selectedTimeframe}
                initialTimeframe={initialTimeframe}
                onEnableFetch={() => setFetchEnabled(true)}
                changeTime={changeTime}
                setChangeTime={({ start, end }: { start: string; end: string }) =>
                  setChangeTime({ timeframeStart: start, timeframeEnd: end })
                }
              />
            </Grid>

            {/* booking type picker */}
            <Grid item>
              <BookingTypeSelector
                typToggle={selectedBookingType}
                setTypToggle={typToggle => {
                  dispatch(setSelectedBookingType(typToggle));

                  // if booking type toggle is differed from already selected ZoneOrWorkplace's booking type then remove the selection
                  /* istanbul ignore next */
                  if (
                    selectedZoneOrWorkplace &&
                    selectedZoneOrWorkplace.bookingType !== typToggle
                  ) {
                    dispatch(setIsSelected(false));
                    dispatch(
                      setSelectedZoneOrWorkplace({
                        id: [],
                        name: [],
                        type: SelectedZoneOrWorkplaceType.ZONE,
                        open: false,
                        floorInventoryId: 0,
                        bookingType: undefined
                      })
                    );
                  }
                }}
                onEnableFetch={() => setFetchEnabled(true)}
              />
            </Grid>

            {/* weekdays selector */}
            <Grid item sx={{ mr: 2 }}>
              <WeekdaysSelector
                weekdays={selectedWeekdays}
                setWeekdaysToggle={weekdays => dispatch(setSelectedWeekdays(weekdays))}
                onEnableFetch={() => setFetchEnabled(true)}
              />
            </Grid>

            {/* calculate button */}
            {fetchEnabled && (
              <Grid item>
                <Button
                  variant={"outlined"}
                  data-testid="calculate-btn"
                  color={"primary"}
                  onClick={() => {
                    dispatch(
                      setSelectedTimeframe({
                        timeframeStart: changeTime.timeframeStart,
                        timeframeEnd: changeTime.timeframeEnd
                      })
                    );
                    dispatch(setLoading(true));
                    dispatch(setSelectedDepartmentIds(selectedDepartmentFilter));
                    setTimeout(() => {
                      fetchReports(currentFloors);
                    }, 15);
                  }}
                >
                  {t("Calculate")}
                  {isLoading ? (
                    <DefaultSpinner
                      style={{ padding: 0, paddingLeft: "10px" }}
                      color="white"
                      size={20}
                    />
                  ) : null}
                </Button>
              </Grid>
            )}

            {/* chips when selecting place or zone */}
            <Grid item>
              <SelectionChips
                refetchOnRemove={() => {
                  currentFloors.forEach(floor =>
                    dispatch(
                      fetchReport({
                        ...defaultParams,
                        id: floor.id,
                        filterType: "floor"
                      })
                    )
                  );
                  dispatch(setSelectedBookingType(availableTypes[0].name as BookingType));
                }}
              />
            </Grid>
          </Grid>

          {/* filter by department */}
          {isHrAdmin && (
            <Grid item sx={{ mt: 1, mb: 2 }}>
              <DepartmentSelector
                departmentOpt={departmentOpt}
                handleDepartmentSelect={handleDepartmentSelect}
              />
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default DateAndTypeSelector;

type RefetchCapaReportWithSelected = {
  defaultParams: any;
  currentFloors: { id: number; open: boolean }[];
  fetchReport: AsyncThunk<void, FetchReportParams, any>;
  selectedZoneOrWorkplace: SelectedZoneOrWorkplace;
  selectedWeekdays: Weekdays;
  setFilterTyp: (t: ("floor" | SelectedZoneOrWorkplaceType | undefined)[]) => void;
  dispatch: AppDispatch;
};
export function refetchCapaReportWithSelected({
  defaultParams,
  currentFloors,
  fetchReport,
  selectedZoneOrWorkplace,
  selectedWeekdays,
  setFilterTyp,
  dispatch
}: RefetchCapaReportWithSelected) {
  if (selectedZoneOrWorkplace && selectedZoneOrWorkplace.open) {
    setFilterTyp([selectedZoneOrWorkplace.type, undefined]);
    dispatch(
      fetchReport({
        ...defaultParams,
        id: selectedZoneOrWorkplace.id,
        filterType: selectedZoneOrWorkplace.type,
        bookingType: selectedZoneOrWorkplace.bookingType,
        selectedWeekdays: selectedWeekdays
      })
    );
  } else {
    const secondFilterTyp = currentFloors.length > 1 && currentFloors[1].open;
    setFilterTyp(["floor", secondFilterTyp ? "floor" : undefined]);
  }
}
