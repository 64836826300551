import { Box, Button, Grid, Slider, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { LocationInventory } from "../../features/FloorManager/typings/location-inventory";
import {
  getAutomatedWorkplaceBookingConfiguration,
  setAutomatedBookingConfiguration
} from "../../utils/axios-requests";
import WeekdayPicker from "../Pickers/WeekdayPicker/weekday-picker.component";
import { InfoIconWithTooltip } from "../Title/InfoIcon";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";

export type P = {
  locations: LocationInventory[];
  setAutomatedOpen: (o: boolean) => void;
};

export type WeekdaySelection = {
  userId: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  mondayLocationInventoryId: number;
  tuesdayLocationInventoryId: number;
  wednesdayLocationInventoryId: number;
  thursdayLocationInventoryId: number;
  fridayLocationInventoryId: number;
  saturdayLocationInventoryId: number;
  sundayLocationInventoryId: number;
  automatedWorkplaceBookingLength: number;
};

/**
 * Automated Desk Booking component in the settings.
 * @description Adjust users automated seating preferences.
 */
const AutomatedDeskBooking: React.FC<P> = ({ locations, setAutomatedOpen }) => {
  const { userInformation } = useSelector((state: RootState) => state.login);
  const { meta: metaData } = useSelector((state: RootState) => state.login.userInformation.company);
  const { enqueueSnackbar } = useSnackbar();

  const [weekDaySelection, setWeekDaySelection] = useState<WeekdaySelection>({
    userId: userInformation.sub,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    mondayLocationInventoryId: 1,
    tuesdayLocationInventoryId: 1,
    wednesdayLocationInventoryId: 1,
    thursdayLocationInventoryId: 1,
    fridayLocationInventoryId: 1,
    saturdayLocationInventoryId: 1,
    sundayLocationInventoryId: 1,
    automatedWorkplaceBookingLength: 4
  });
  const { t } = useTranslation();

  useEffect(() => {
    getAutomatedWorkplaceBookingConfiguration().then(r => setWeekDaySelection(r.data));
  }, []);

  const postAutomatedSettings = () => {
    setAutomatedBookingConfiguration(weekDaySelection)
      .then(() => {
        enqueueSnackbar(t("_activateAutomatedBookingAssistantSuccess"), { variant: "success" });
        setAutomatedOpen(false);
      })
      .catch(() => {
        enqueueSnackbar(t("_activateAutomatedBookingAssistantError"), { variant: "error" });
      });
  };

  return (
    <>
      <Grid container direction={"row"} alignItems={"center"}>
        <Typography style={{ padding: "10px 0 10px 0" }} variant={"h6"}>
          {t("_automatedBookingAssistantTitle")}
        </Typography>
        <InfoIconWithTooltip tooltipText={t("_automatedBookingAssistantInfo")} />
      </Grid>
      <WeekdayPicker
        withLocations={true}
        withTeam={false}
        locations={locations}
        setInputs={setWeekDaySelection}
        inputs={weekDaySelection}
      />
      <Grid container>
        <Box sx={{ mt: 3, mb: 2, pl: 1 }}>
          <Typography variant={"body2"}>{t("Automated Booking Duration in weeks")}</Typography>

          <Slider
            aria-labelledby="discrete-slider-always"
            step={1}
            marks
            style={{ marginLeft: "-2px" }}
            valueLabelDisplay="auto"
            max={
              metaData.advancedBookingSettings?.find(s => s.bookingType === BookingType.WORKPLACE)
                ?.maxWeeksInAdvance || 6
            }
            aria-label="Booking Norm Hours"
            value={weekDaySelection.automatedWorkplaceBookingLength}
            onChange={(event: any, value: number | number[]) =>
              setWeekDaySelection((prevState: WeekdaySelection) => ({
                ...prevState,
                automatedWorkplaceBookingLength: value as number
              }))
            }
          />
        </Box>
      </Grid>

      <Button
        data-testid="post-automated-setting-btn"
        variant="contained"
        onClick={postAutomatedSettings}
      >
        {t("Confirm")}
      </Button>
    </>
  );
};

export default AutomatedDeskBooking;
