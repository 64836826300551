import { Box, Fade, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../app/helpers";
import selectedEntitiesSelector from "../selectors/selectedEntitiesSelector";
import { nextStep, setStep } from "../slices/steps.slice";
import fetchAdminLocations from "../thunks/fetchAdminLocations";
import fetchFloors from "../thunks/fetchFloors";
import { FloorManagerContent } from "./FloorManagerContent";
import { StepTab } from "./types";
import { FloorManagerStepper } from "./FloorManagerStepper";
import { RootState } from "../../../app/rootReducer";
import { Timeframe } from "../../../features/FloorManager/typings/shared/timeframe";
import { selectFloor } from "../slices/selected.slice";
import { setFloorLoading } from "../slices/floors.slice";
import { useRemoteFetchFloorTimeframes } from "../../../hooks/Remote/FloorTimeframes/useRemoteFetchFloorTimeframes";

/**
 * Floor Manager Navigation Stepper for selecting location and floor (and timeframe).
 * After selecting floor, location and timeframe, the FloorPlanView is displayed.
 * @constructor
 */
function FloorManagerNavigation() {
  const [selectedFloorId, setSelectedFloorId] = useState<number>(0);
  const [floorTimeframes, setFloorTimeframes] = useState<Timeframe[]>([]);

  const { data: fetchedfloorTimeframes, refetch: refetchFloorTimeframes } =
    useRemoteFetchFloorTimeframes(selectedFloorId);

  const { t } = useTranslation();

  const steps: StepTab[] = [
    {
      heading: t("Choose the location you want to edit"),
      label: t("Select location")
    },
    {
      heading: t("Choose a floor of "),
      label: t("Select floor")
    },
    {
      heading: t("Select the version you want to edit"),
      label: t("Edit floor")
    }
  ];

  const dispatch = useDispatch();
  const activeStep = useSelector((state: RootState) => state.floorManager.activeStep);
  const selected = useSelector(selectedEntitiesSelector);

  const handleStep = (step: number) => dispatch(setStep(step));

  useEffect(() => {
    dispatch(fetchAdminLocations());
  }, [dispatch]);

  useEffect(() => {
    if (fetchedfloorTimeframes) {
      setFloorTimeframes(fetchedfloorTimeframes);
      dispatch(setFloorLoading(false));
      dispatch(selectFloor(selectedFloorId));

      if (activeStep < 2) dispatch(nextStep());
    }
  }, [fetchedfloorTimeframes]);

  useEffect(() => {
    if (selected.location) {
      dispatch(fetchFloors(selected.location.id));
    }
  }, [selected.location, dispatch]);

  return (
    <Fade mountOnEnter unmountOnExit in={!selected.timeframe}>
      <Box data-testid="floor-manager-nav-step-box">
        <Typography variant="h5" sx={{ textAlign: "left", padding: "20px 0 20px 0" }}>
          {steps[activeStep]?.heading + " " + (activeStep === 1 ? selected?.location?.name : "")}
        </Typography>

        <FloorManagerStepper
          activeStep={activeStep}
          handleStep={handleStep}
          selected={selected}
          steps={steps}
          selectedFloorId={selectedFloorId}
        />
        <FloorManagerContent
          activeStep={activeStep}
          selected={selected}
          floorTimeframes={floorTimeframes}
          setFloorTimeframes={setFloorTimeframes}
          selectedFloorId={selectedFloorId}
          setSelectedFloorId={setSelectedFloorId}
          refetchFloorTimeframes={refetchFloorTimeframes}
        />
      </Box>
    </Fade>
  );
}

export default FloorManagerNavigation;
