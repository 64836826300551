import { Box, Card, styled, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import useTimeFormat from "../../../../../hooks/useTimeFormat/useTimeFormat";
import { DateTime } from "luxon";

export const StyledTimeframeBasicCard = styled(Card, {
  name: "StyledTimeframeBasicCard"
})(() => ({
  display: "flex",
  width: 420,
  height: 55,
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: "16px",
  paddingRight: "16px",
  marginLeft: "4px",
  cursor: "pointer"
}));

/** basic item of timeframe list */
export function BasicTimeframeListItem({
  isDetailOpen,
  startDate,
  endDate,
  isActive,
  setShowTimeframeDetail
}: {
  isDetailOpen: boolean;
  startDate: string;
  endDate: string | null;
  isActive: boolean;
  setShowTimeframeDetail: (detail: string) => void;
}) {
  const { t } = useTranslation();
  const { dateFormat } = useTimeFormat();
  const theme = useTheme();

  const activeBoxBgColor =
    theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.grey[400];
  const handleShowTimeframeDetail = () => setShowTimeframeDetail(startDate);

  return (
    <>
      {!isDetailOpen && (
        <StyledTimeframeBasicCard
          data-testid="time-line-content-card"
          onClick={handleShowTimeframeDetail}
        >
          <Box sx={{ display: "flex" }}>
            <Typography fontWeight={"bold"}>{t("Version")}</Typography>
            <Typography sx={{ ml: 1, mr: 1 }}>{t("_versionFrom")}</Typography>
            <Typography>{startDate}</Typography>
            {endDate && (
              <>
                <Typography sx={{ px: 1 }}>-</Typography>
                <Typography>{DateTime.fromISO(endDate).toFormat(dateFormat)}</Typography>
              </>
            )}
          </Box>
          {isActive && (
            <Box sx={{ backgroundColor: activeBoxBgColor, px: 1.5, py: 0.4, borderRadius: 1 }}>
              <Typography sx={{ fontWeight: "bolder" }}>{t("Active")}</Typography>
            </Box>
          )}
        </StyledTimeframeBasicCard>
      )}
    </>
  );
}
