import { PropsWithChildren } from "react";
import { Box, Divider, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CSVLink } from "react-csv";
import { Icon } from "@iconify/react";
import undoIcon from "@iconify/icons-mdi/undo";
import redoIcon from "@iconify/icons-mdi/redo";
import identifierIcon from "@iconify/icons-mdi/identifier";
import qrcodeIcon from "@iconify/icons-mdi/qrcode";
import fileExcel from "@iconify/icons-mdi/file-excel";
import floorPlanIcon from "@iconify/icons-mdi/floor-plan";
import backburgerIcon from "@iconify/icons-mdi/backburger";
import downloadIcon from "@iconify/icons-mdi/download";
import { IFloorPayload } from "../../../Domain/Types/FloorPlan/FloorPayload.type";
import { ExcelData, useExcelPrint } from "../../../Hooks/useExcelPrint";
import { useTranslation } from "react-i18next";
import { HistoryStateActionType } from "../../../Hooks/useHistoryState/useHistoryState";

interface Props {
  floorPlanName: string;
  floorPlan: IFloorPayload;
  qrCodeOpen: boolean;
  setQrCodeOpen: (o: boolean) => void;
  showId: boolean;
  setShowId: (s: boolean) => void;
  onClickPublish: () => void;
  canUndo: boolean;
  canRedo: boolean;
  onClickAbort: () => void;
  isEditable: boolean;
  enablePublish: boolean;
  isFloorPlanForService: boolean;
  dispatchFloorPlan: (action: {
    type: HistoryStateActionType.UNDO | HistoryStateActionType.REDO;
  }) => void;
  printFloorPlan: () => void;
  changeFloorFrame: () => void;
}

export function TopToolbar({
  floorPlanName,
  onClickPublish,
  canUndo,
  canRedo,
  floorPlan,
  qrCodeOpen,
  setQrCodeOpen,
  showId,
  setShowId,
  onClickAbort,
  printFloorPlan,
  changeFloorFrame,
  isEditable,
  enablePublish,
  isFloorPlanForService,
  dispatchFloorPlan
}: PropsWithChildren<Props>) {
  const theme = useTheme();
  const { t } = useTranslation();

  const iconWidth = 26;
  const iconHeight = 26;
  const iconColor = theme.palette.mode === "light" ? "black" : "white";

  const excelFileData: ExcelData<any> = {
    excelHeaders: [
      {
        key: "inventoryId" as const,
        label: t("Inventory Id")
      },
      {
        key: "name" as const,
        label: t("Name")
      },
      {
        key: "categoryName" as const,
        label: t("Category Name")
      },
      {
        key: "assetId" as const,
        label: t("Asset Id")
      }
    ],
    excelData: floorPlan.places.map(desk => ({
      inventoryId: desk.inventoryId || 0,
      name: desk.inventory?.name || "",
      categoryName: desk.category?.name || "No Category",
      assetId: ""
    }))
  };

  const { CSVheaders, CSVData, separatorByLang } = useExcelPrint(excelFileData);

  const checkEnablePublish = (() => {
    if (isFloorPlanForService) return true;
    return !isEditable || enablePublish;
  })();

  const publishToolTip = (() => {
    if (!isEditable || isFloorPlanForService) return t("PublishActiveCannotBeUpdated");

    if (enablePublish) return t("PublishInventoriesRequirement");

    return t("Finish Editing");
  })();

  const disabledUndoRedoColor = "grey";

  return (
    <Box
      data-testid="plan-designer-top-toolbar-box"
      m={1}
      zIndex={10}
      height={50}
      sx={{
        border: "1px solid #00000088",
        backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#000000",
        borderRadius: 2,
        opacity: 0.95,
        flex: 1
      }}
      display={"flex"}
      alignItems={"center"}
    >
      <Tooltip title={t("Go back")}>
        <IconButton data-testid="tool-btn-goBack" onClick={onClickAbort} size="large">
          <Icon icon={backburgerIcon} width={iconWidth} height={iconHeight} />
        </IconButton>
      </Tooltip>

      <Typography>{floorPlanName}</Typography>
      <Box flex={1} />
      {isEditable && (
        <>
          {/* undo the changes */}
          <Tooltip title={t("Undo")}>
            <div>
              <IconButton
                data-testid="tool-btn-undo"
                onClick={() => dispatchFloorPlan({ type: HistoryStateActionType.UNDO })}
                disabled={!canUndo}
                size="large"
              >
                <Icon
                  icon={undoIcon}
                  color={canUndo ? iconColor : disabledUndoRedoColor}
                  width={iconWidth}
                  height={iconHeight}
                />
              </IconButton>
            </div>
          </Tooltip>

          {/* redo the changes */}
          <Tooltip title={t("Redo")}>
            <div>
              <IconButton
                data-testid="tool-btn-redo"
                onClick={() => dispatchFloorPlan({ type: HistoryStateActionType.REDO })}
                disabled={!canRedo}
                size="large"
              >
                <Icon
                  icon={redoIcon}
                  color={canRedo ? iconColor : disabledUndoRedoColor}
                  width={iconWidth}
                  height={iconHeight}
                />
              </IconButton>
            </div>
          </Tooltip>
        </>
      )}

      <Divider color={"#aaa"} orientation={"vertical"} flexItem />

      {/* show id toggle */}
      <Tooltip title={showId ? t("_toShowingId") : t("_toHideShowingId")}>
        <IconButton data-testid="tool-btn-showId" onClick={() => setShowId(!showId)} size="large">
          <Icon icon={identifierIcon} color={iconColor} width={iconWidth} height={iconHeight} />
        </IconButton>
      </Tooltip>

      {/* print qr codes */}
      <Tooltip title={t("Print QR codes for Workplaces")}>
        <IconButton
          data-testid="tool-btn-printQr"
          onClick={() => setQrCodeOpen(!qrCodeOpen)}
          size="large"
        >
          <Icon icon={qrcodeIcon} color={iconColor} width={iconWidth} height={iconHeight} />
        </IconButton>
      </Tooltip>

      {/* export as excel */}
      <CSVLink
        data={CSVData}
        filename={`${floorPlanName}.csv`}
        headers={CSVheaders}
        separator={separatorByLang}
      >
        <Tooltip title={t("Export as XLSX")}>
          <IconButton data-testid="tool-btn-excel" size="large">
            <Icon icon={fileExcel} color={iconColor} width={iconWidth} height={iconHeight} />
          </IconButton>
        </Tooltip>
      </CSVLink>

      {/* change background picture of floor frame */}
      {isEditable && (
        <Tooltip title={t("Change frame of plan_toolbar")}>
          <span>
            <IconButton
              data-testid="tool-btn-change-floorFrame"
              onClick={changeFloorFrame}
              size="large"
            >
              <Icon icon={floorPlanIcon} color={iconColor} width={iconWidth} height={iconHeight} />
            </IconButton>
          </span>
        </Tooltip>
      )}

      {/* print floor plan as pdf */}
      <Tooltip title={t("Print Floor Plan")}>
        <IconButton data-testid="tool-btn-printFloor" onClick={printFloorPlan} size="large">
          <Icon icon={downloadIcon} color={iconColor} width={iconWidth} height={iconHeight} />
        </IconButton>
      </Tooltip>

      {/* button to change background image for the floor. it relates to viewport size */}
      {/* <Tooltip title={t("Modify Floor Frame")}>
        <IconButton disabled>
          <Icon icon={wallpaperIcon} color={iconColor} width={iconWidth} height={iconHeight} />
        </IconButton>
      </Tooltip> */}

      <Divider color={"#aaa"} orientation={"vertical"} flexItem />

      {/* publish the floor plan */}
      <Tooltip title={publishToolTip}>
        <div>
          <LoadingButton
            data-testid="tool-btn-publish"
            onClick={onClickPublish}
            disabled={checkEnablePublish}
          >
            <Typography fontSize={12}>{t("Finish Editing")}</Typography>
          </LoadingButton>
        </div>
      </Tooltip>
    </Box>
  );
}
