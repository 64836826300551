import { LocationUsage } from "../features/Reports/typings/reports.types";

type EntityWithDuration<T> = T & {
  duration: LocationUsage;
};

export type AverageUsages = {
  notBookedPercentage: string;
  notCheckedInPercentage: string;
};

export function calculateAverageUsages(entities: EntityWithDuration<any>[]): AverageUsages {
  const totalCount = entities.length;
  let sumOfAllNotBookedPercentages = 0;
  let sumOfAllNotCheckedInPercentages = 0;

  entities.forEach(entity => {
    const duration = entity.duration?.find(
      (duration: LocationUsage) => duration.locationInventoryId === 0
    );
    if (duration?.splitDurations?.relativeNotBookedDuration !== undefined) {
      sumOfAllNotBookedPercentages += duration.splitDurations.relativeNotBookedDuration;
    }
    if (duration?.splitDurations?.relativeNotCheckedInDuration !== undefined) {
      sumOfAllNotCheckedInPercentages += duration.splitDurations.relativeNotCheckedInDuration;
    }
  });

  const notBookedPercentage =
    sumOfAllNotBookedPercentages !== 0 ? sumOfAllNotBookedPercentages / totalCount : 0;
  const formattedNotBookedPercentage = toPercentString(notBookedPercentage);

  const notCheckedInPercentage =
    sumOfAllNotCheckedInPercentages !== 0 ? sumOfAllNotCheckedInPercentages / totalCount : 0;
  const formattedNotCheckedInPercentage = toPercentString(notCheckedInPercentage);

  return {
    notBookedPercentage: formattedNotBookedPercentage + "%",
    notCheckedInPercentage: formattedNotCheckedInPercentage + "%"
  };
}

function toPercentString(value: number): string {
  const percentage = (value * 100).toFixed(0);
  return `${percentage}`;
}
