import { BookingRequestsActive } from "../../../components/BookingRequest/typings/booking-requests.types";
import { useRemoteQuery } from "../useRemote/useRemoteQuery";

export function useRemoteFetchActiveBookingRequests(mode: string, isAdmin: boolean) {
  return useRemoteQuery<BookingRequestsActive[]>({
    queryKey: "booking-Requests-active",
    url: "/booking-approval/active",
    enabled: mode === "active" && isAdmin
  });
}
