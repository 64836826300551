import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Company,
  RestrictionZone,
  UserZoneBookingObject
} from "../../Booking-Form/typings/booking.types";
import {
  CapacityObject,
  EquipmentFilter,
  MapMarkerLocation,
  ReportEntities,
  ReportFilters,
  TimeframeString
} from "../typings/reports.types";
import { BookingType } from "../../Booking-Form/typings/booking-inputs";
import { Weekdays } from "../../../components/Pickers/WeekdayPicker/weekday-picker.component";

export enum SelectedZoneOrWorkplaceType {
  ZONE = "zone",
  WORKPLACE = "workplace"
}

export type SelectedZoneOrWorkplace = {
  id: number[];
  name: string[];
  open: boolean;
  type: SelectedZoneOrWorkplaceType;
  floorInventoryId: number;
  bookingType: BookingType | undefined;
};

export type ReportState = {
  isLoading: boolean;
  calculateWithoutCosts: boolean;
  compareTo: { type: string; ids: number[] };
  currentFloors: { id: number; open: boolean }[];
  selectedZoneOrWorkplace: SelectedZoneOrWorkplace;
  selectedLocation: { id: number; open: boolean; sub: string };
  mapMarkerLocations?: MapMarkerLocation[];
  capacitySelectedWorkplaceOrZone?: CapacityObject;
  capacitySelectedLocations?: CapacityObject[];
  capacitySelectedFloors?: CapacityObject[];
  selectableEntities?: ReportEntities[];
  initialTimeframe?: TimeframeString;
  selectedTimeframe?: TimeframeString;
  zoneRestrictions?: RestrictionZone;
  equipment: EquipmentFilter;
  filterOptions: ReportFilters;
  filterSelected: ReportFilters;
  isSelected: boolean;
  selectedBookingType: BookingType | null;
  selectedWeekdays: Weekdays;
  selectedDepartmentIds: string[];
};

const initialState: ReportState = {
  isLoading: false,
  calculateWithoutCosts: true,
  selectedLocation: { id: -1, open: false },
  currentFloors: [{ id: -1, open: false }],
  zoneRestrictions: {
    company: { id: 0, name: "" },
    object: { id: 0, zoneBookingObject: "", type: "", name: "" }
  },
  equipment: {},
  filterOptions: {},
  filterSelected: {},
  isSelected: false,
  selectedWeekdays: {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
    sunday: false
  },
  selectedDepartmentIds: []
} as unknown as ReportState;

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setIsSelected(state, action: PayloadAction<ReportState["isSelected"]>) {
      state.isSelected = action.payload;
    },
    setMarkers(state, action: PayloadAction<ReportState["mapMarkerLocations"]>) {
      state.mapMarkerLocations = action.payload;
    },
    setCompareTo(state, action: PayloadAction<ReportState["compareTo"]>) {
      state.compareTo = action.payload;
    },
    setInitialTimeframe(state, action: PayloadAction<ReportState["initialTimeframe"]>) {
      state.initialTimeframe = action.payload;
    },
    setCalculateWithoutCosts(state, action: PayloadAction<ReportState["calculateWithoutCosts"]>) {
      state.calculateWithoutCosts = action.payload;
    },
    setSelectedTimeframe(state, action: PayloadAction<ReportState["selectedTimeframe"]>) {
      state.selectedTimeframe = action.payload;
    },
    setLoading(state, action: PayloadAction<ReportState["isLoading"]>) {
      state.isLoading = action.payload;
    },
    setCurrentFloors(state, action: PayloadAction<ReportState["currentFloors"]>) {
      state.currentFloors = action.payload;
    },
    setSelectedLocation(state, action: PayloadAction<ReportState["selectedLocation"]>) {
      state.selectedLocation = action.payload;
    },
    setZoneRestrictions(
      state,
      action: PayloadAction<{
        object?: UserZoneBookingObject;
        company?: Company;
      }>
    ) {
      if (action.payload?.object && action.payload?.company) {
        state.zoneRestrictions = action.payload as RestrictionZone;
      }
      if (action.payload?.object && state.zoneRestrictions?.object) {
        state.zoneRestrictions.object = action.payload?.object;
      }
      if (action.payload?.company && state.zoneRestrictions?.company) {
        state.zoneRestrictions.company = action.payload?.company;
      }
    },
    setSelectedZoneOrWorkplace(
      state,
      action: PayloadAction<ReportState["selectedZoneOrWorkplace"]>
    ) {
      state.selectedZoneOrWorkplace = action.payload;
    },
    setCapacitySelectedWorkplaceOrZone(
      state,
      action: PayloadAction<ReportState["capacitySelectedWorkplaceOrZone"]>
    ) {
      state.capacitySelectedWorkplaceOrZone = action.payload;
    },
    setCapacitySelectedLocations(state, action: PayloadAction<CapacityObject>) {
      if (state.capacitySelectedLocations) {
        const alreadyFetched = state.capacitySelectedLocations.some(
          location => location.id === action.payload.id
        );
        if (alreadyFetched) {
          state.capacitySelectedLocations = [
            ...state.capacitySelectedLocations.filter(floor => floor.id !== action.payload.id),
            action.payload
          ];
        } else {
          state.capacitySelectedLocations = [...state.capacitySelectedLocations, action.payload];
        }
      } else state.capacitySelectedLocations = [action.payload];
    },
    setCapacitySelectedFloors(state, action: PayloadAction<CapacityObject>) {
      if (state.capacitySelectedFloors) {
        const alreadyFetched = state.capacitySelectedFloors.some(
          location => location.id === action.payload.id
        );
        if (alreadyFetched) {
          state.capacitySelectedFloors = [
            ...state.capacitySelectedFloors.filter(floor => floor.id !== action.payload.id),
            action.payload
          ];
        } else {
          state.capacitySelectedFloors = [...state.capacitySelectedFloors, action.payload];
        }
      } else state.capacitySelectedFloors = [action.payload];
    },
    setSelectableEntities(state, action: PayloadAction<ReportEntities>) {
      if (state.selectableEntities) {
        state.selectableEntities = [...state.selectableEntities, action.payload];
      } else {
        state.selectableEntities = [action.payload];
      }
    },
    setEquipment(state, action: PayloadAction<EquipmentFilter>) {
      const { equipmentIds, equipmentCategoryIds, workplaceCategoryIds } = action.payload;

      if (equipmentIds) state.equipment.equipmentIds = equipmentIds;
      if (equipmentCategoryIds) state.equipment.equipmentCategoryIds = equipmentCategoryIds;
      if (workplaceCategoryIds) state.equipment.workplaceCategoryIds = workplaceCategoryIds;
    },
    setFilterOptions(state, action: PayloadAction<ReportState["filterOptions"]>) {
      const { equipments, equipmentCategories, placeCategories } = action.payload;
      if (equipments) state.filterOptions.equipments = equipments;
      if (equipmentCategories) state.filterOptions.equipmentCategories = equipmentCategories;
      if (placeCategories) state.filterOptions.placeCategories = placeCategories;

      // Reset selected when fetching new options
      state.filterSelected = {};
    },
    setFilterSelected(state, action: PayloadAction<ReportState["filterSelected"]>) {
      const { equipments, equipmentCategories, placeCategories } = action.payload;
      if (equipments) state.filterSelected.equipments = equipments;
      if (equipmentCategories) state.filterSelected.equipmentCategories = equipmentCategories;
      if (placeCategories) state.filterSelected.placeCategories = placeCategories;
    },
    setSelectedBookingType(state, action: PayloadAction<ReportState["selectedBookingType"]>) {
      state.selectedBookingType = action.payload;
    },
    setSelectedWeekdays(state, action: PayloadAction<ReportState["selectedWeekdays"]>) {
      state.selectedWeekdays = action.payload;
    },
    setSelectedDepartmentIds(state, action: PayloadAction<ReportState["selectedDepartmentIds"]>) {
      state.selectedDepartmentIds = action.payload;
    }
  }
});

export default reportSlice.reducer;
export const {
  setMarkers,
  setSelectableEntities,
  setInitialTimeframe,
  setSelectedTimeframe,
  setCapacitySelectedLocations,
  setCapacitySelectedFloors,
  setSelectedLocation,
  setCompareTo,
  setLoading,
  setCurrentFloors,
  setSelectedZoneOrWorkplace,
  setCapacitySelectedWorkplaceOrZone,
  setCalculateWithoutCosts,
  setZoneRestrictions,
  setEquipment,
  setFilterOptions,
  setFilterSelected,
  setSelectedBookingType,
  setSelectedWeekdays,
  setSelectedDepartmentIds,
  setIsSelected
} = reportSlice.actions;
