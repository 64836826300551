import i18n from "i18next";
import { DateTime } from "luxon";
import { UserInformation } from "../../Login/typings/login.types";
import { FloorInventory } from "../typings/floor-inventory.entity";
import { LocationInventory } from "../typings/location-inventory";
import { InventoryStatus } from "../typings/shared/inventory.entity";

export const defaultLocationInventoryInputs = (args: {
  id: LocationInventory["id"];
  uid: string;
  scheduleId: number;
  userInformation: UserInformation;
}): LocationInventory => ({
  id: args.id,
  uid: args.uid,
  name: "",
  approval: false,
  status: InventoryStatus.Active,
  address1: "",
  address2: "",
  address3: "",
  city: "",
  postalCode: "",
  state: "",
  country: "",
  timezone: "_",
  picturePath: "",
  allowChangeDeskCost: false,
  allowForBooking: false,
  bookingAllowedAt: new Date().toISOString(),
  color: "#f44336",
  isMobileWorking: false,
  maxLevels: "" as any,
  companyId: 1,
  checkinPeriod: 30,
  checkinRadiusInMeters: 1500,
  schedule: [
    {
      id: args.scheduleId,
      start: DateTime.now().set({ hour: 0, minute: 1 }).toISO(),
      end: null,
      inventoryId: -1,
      description: "",
      companyId: args.userInformation.companyId,
      authorId: "",
      workingHoursPerMonth: 80,
      budget: 1,
      defaultDeskCost: 1,
      incentiveFactor: 1,
      vacancyFactor: 1,
      periodLength: 1,
      createdAt: new Date().toISOString(),
      unitId: null
    }
  ],
  disabledSchedule: [],
  exivoSiteId: null,
  accessWithGeoLocation: false
});

export const defaultFloorInventoryInputs = (args: {
  id: FloorInventory["id"];
  uid: string;
  locationInventoryId: number;
}): FloorInventory => ({
  id: args.id,
  uid: args.uid,
  name: "",
  status: InventoryStatus.Active,
  level: "",
  viewport: { width: 0, height: 0 },
  locationInventoryId: args.locationInventoryId,
  schedule: [
    {
      start: DateTime.now().toFormat("yyyy-MM-dd"),
      end: null,
      outlinePath: "",
      description: i18n.t("new Schedule"),
      id: 99999, // not known @todo
      inventoryId: 9999 // not known @todo
    }
  ],
  disabledSchedule: []
});
