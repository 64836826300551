import { useEffect, useMemo, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { CheckBoxOutlineBlank, DoNotDisturb } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MRT_ColumnDef, MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useRemoteFetchActiveBookingRequests } from "../../hooks/Remote/BookingRequests/useRemoteFetchActiveBookingRequests";
import { BookingRequestsActiveRow, BookingRequestsStatus } from "./typings/booking-requests.types";
import { commonLocalization } from "../../functions/tableLocalization";
import useTimeFormat from "../../hooks/useTimeFormat/useTimeFormat";
import {
  bookingTypeArr,
  renderBookingObject,
  renderBookingType,
  renderExpandButton,
  renderLocation,
  renderRequestedDate,
  renderStatusData,
  renderUserInfo,
  statusArr
} from "./booking-requests-management.partial";
import { useRemotePatchActiveBookingRequests } from "../../hooks/Remote/BookingRequests/useRemotePatchActiveBookingRequests";
import TableDeleteConfirmDialog from "../Common/TableDeletionConfirmDialog/TableDeletionConfirmDialog.component";
import { ScheduleSelectionDialog } from "../Schedule/ScheduleCalendar/ScheduleSelectionDialog/ScheduleSelectionDialog.component";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";
import { filterMRTEndDateProps } from "../../features/Approval/booking-approval-hook.functions";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";
import { useRemoteFetchUserInfoForAllUsers } from "../../hooks/Remote/User/UserInfo/useRemoteFetchUserInfoForAllUsers";
import BookingSvgDialog from "../Svg/svg-dialog.component";

/**
 * @version 0.1.0
 */
const BookingRequestsManagement = ({ mode }: { mode: "active" | "archive" }) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    userInformation,
    userRoles: { isRequestAdmin }
  } = useSelector((state: RootState) => state.login);
  const { dateFormat, timeFormat } = useTimeFormat();
  const localeFullFormat = dateFormat + " " + timeFormat;

  const theme = useTheme();

  const [data, setData] = useState<BookingRequestsActiveRow[]>();

  const [selectedSchedule, setSelectedSchedule] = useState<BookingScheduleInterface>();
  const [isShowFloorDialogOpen, setIsShowFloorDialogOpen] = useState<boolean>(false);

  const [showBookingDetail, setShowBookingDetail] = useState<{
    schedule: Partial<BookingScheduleInterface> | null;
    open: boolean;
  }>({
    schedule: null,
    open: false
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletionSelected, setDeletionSelected] = useState<number[] | null>(null);
  const [selectRequestStatus, setSelectRequestStatus] = useState<BookingRequestsStatus | null>(
    null
  );

  const {
    data: remoteActiveBookingRequests,
    refetch: refetchActiveBookingRequests,
    isLoading: isActiveBookingRequestsLoading,
    isFetching: isActiveBookingRequestsFetching
  } = useRemoteFetchActiveBookingRequests(mode, isRequestAdmin);

  const { data: remoteUsers } = useRemoteFetchUserInfoForAllUsers();

  const {
    mutateAsync: mutatePatchBookingRequests,
    status: patchBookingRequestsStatus,
    error: patchBookingRequestsError
  } = useRemotePatchActiveBookingRequests();

  const handleShowFloor = (schedule: BookingScheduleInterface) => {
    setSelectedSchedule(schedule);
    setIsShowFloorDialogOpen(true);
  };

  const handleShowFloorCloseFn = () => {
    setSelectedSchedule(undefined);
    setIsShowFloorDialogOpen(false);
  };

  // handle reject booking on the slide out panel on detail
  /* istanbul ignore next */
  const handleRejectBookingsOnPanel = () => {
    if (!showBookingDetail.open) return;

    setSelectRequestStatus(BookingRequestsStatus.REJECTED);
    mutatePatchBookingRequests({
      status: BookingRequestsStatus.REJECTED,
      bookingData: [
        {
          bookingId: showBookingDetail.schedule?.id ?? 0,
          bookingType: showBookingDetail.schedule?.bookingType as BookingType
        }
      ]
    });
  };

  // handle approve booking on the slide out panel on detail
  /* istanbul ignore next */
  const handleApproveBookingsOnPanel = () => {
    if (!showBookingDetail.open) return;
    //
    setSelectRequestStatus(BookingRequestsStatus.APPROVED);
    mutatePatchBookingRequests({
      status: BookingRequestsStatus.APPROVED,
      bookingData: [
        {
          bookingId: showBookingDetail.schedule?.id ?? 0,
          bookingType: showBookingDetail.schedule?.bookingType as BookingType
        }
      ]
    });
  };

  const handleRejectBookings = () => {
    const selected = table.getSelectedRowModel().rows.map(row => row.original.bookingObject); // id of booking
    setDeletionSelected(selected);
    setIsDeleteModalOpen(true);
    setSelectRequestStatus(BookingRequestsStatus.REJECTED);
  };

  const handleConfirmRejectBookings = () => {
    mutatePatchBookingRequests({
      status: BookingRequestsStatus.REJECTED,
      bookingData: table.getSelectedRowModel().rows.map(({ original: book }) => ({
        bookingId: book.bookingObject,
        bookingType: book.bookingType
      }))
    });
  };

  const handleApproveBookings = () => {
    setSelectRequestStatus(BookingRequestsStatus.APPROVED);
    mutatePatchBookingRequests({
      status: BookingRequestsStatus.APPROVED,
      bookingData: table.getSelectedRowModel().rows.map(({ original: book }) => ({
        bookingId: book.bookingObject,
        bookingType: book.bookingType
      }))
    });
  };

  useEffect(() => {
    if (!remoteActiveBookingRequests) return;

    const approv = remoteActiveBookingRequests.map(book => ({
      statusData: book.statusData,
      inventoryId: book.inventoryId,
      bookingType: book.bookingType,
      requestedDate: book.bookingStart,
      requestedTime: {
        bookingStart: book.bookingStart,
        bookingEnd: book.bookingEnd
      },
      bookingObject: book.bookingId,
      userInfo: {
        bookingUserName: book.bookingUserName,
        bookingUserEmail: book.bookingUserEmail
      },
      locationData: book.locationData
    }));

    setData(approv);
  }, [remoteActiveBookingRequests]);

  // after deleting a row, refresh the list
  useEffect(() => {
    if (patchBookingRequestsStatus === "success") {
      enqueueSnackbar(
        t(`Successfully ${selectRequestStatus?.toLowerCase()} the selected request(s)`),
        {
          variant: "success"
        }
      );
      setIsDeleteModalOpen(false);
      table.resetRowSelection();
      refetchActiveBookingRequests();
      setDeletionSelected(null);
      setSelectRequestStatus(null);
      setShowBookingDetail({ schedule: null, open: false });
    } else if (patchBookingRequestsStatus === "error") {
      // if request-admin from another location is calling error should be thrown
      // if user without request-admin role is calling, error should be thrown
      const err =
        patchBookingRequestsError.response?.data?.message ||
        patchBookingRequestsError.message ||
        "There was an error";

      enqueueSnackbar(t(err), { variant: "error" });
    }
  }, [patchBookingRequestsStatus, patchBookingRequestsError]);

  const column = useMemo<MRT_ColumnDef<BookingRequestsActiveRow>[]>(
    () => [
      {
        header: t("Status"),
        accessorKey: "statusData.status",
        filterVariant: "multi-select",
        filterSelectOptions: statusArr(data),
        size: 200,
        Cell: ({ cell }) => renderStatusData(cell.row.original, dateFormat, timeFormat)
      },
      {
        accessorKey: "bookingType",
        header: t("_bookingRequests_header_bookingType"),
        size: 100,
        filterVariant: "multi-select",
        filterSelectOptions: bookingTypeArr(data),
        Cell: ({ cell }) => renderBookingType(cell.row.original)
      },
      {
        accessorKey: "requestedTime.bookingStart",
        header: t("_bookingRequests_header_requestedStart"),
        size: 150,
        sortingFn: "datetime",
        filterVariant: "date",
        filterFn: "greaterThan",
        accessorFn: row => new Date(row.requestedTime.bookingStart),
        Cell: ({ cell }) => renderRequestedDate(cell, localeFullFormat),
        muiFilterDatePickerProps: {
          format: dateFormat.toUpperCase()
        }
      },
      {
        accessorKey: "requestedTime.bookingEnd",
        header: t("_bookingRequests_header_requestedEnd"),
        size: 150,
        sortingFn: "datetime",
        filterVariant: "date",
        filterFn: "lessThan",
        accessorFn: row => new Date(row.requestedTime.bookingEnd),
        Cell: ({ cell }) => renderRequestedDate(cell, localeFullFormat),
        muiFilterDatePickerProps: ({ column }) =>
          /* istanbul ignore next */ filterMRTEndDateProps(column as any, dateFormat)
      },
      {
        header: t("_bookingRequests_header_bookingObject"),
        accessorKey: "bookingObject",
        size: 80,
        Cell: ({ cell }) => renderBookingObject(cell.row.original)
      },
      {
        header: t("_bookingRequests_header_userInfo"),
        accessorKey: "userInfo.bookingUserEmail",
        size: 80,
        Cell: ({ cell }) => renderUserInfo(cell.row.original)
      },
      {
        header: t("Location"),
        accessorKey: "locationData.name",
        size: 200,
        Cell: ({ cell }) => renderLocation(cell.row.original)
      },
      {
        header: t("Show Details"),
        accessorKey: "requestedTime.bookingEnd",
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        size: 50,
        Cell: ({ cell }) =>
          renderExpandButton({
            params: cell.row.original,
            users: remoteUsers,
            userInformation: userInformation,
            theme,
            enqueueSnackbar,
            setShowBookingDetail
          })
      }
    ],
    [remoteActiveBookingRequests, data, i18n.language]
  );

  const table = useMaterialReactTable({
    columns: column ?? [],
    data: data ?? [],
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: true,
    enableRowSelection: row => row.original?.statusData?.status !== BookingRequestsStatus.REJECTED,
    getRowId: row => row.bookingObject?.toString(),
    state: {
      isLoading: isActiveBookingRequestsLoading || isActiveBookingRequestsFetching,
      isSaving: patchBookingRequestsStatus === "loading"
    },
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      pagination: { pageSize: 10, pageIndex: 0 }
    },
    positionToolbarAlertBanner: "none",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined"
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15],
      variant: "outlined"
    },
    paginationDisplayMode: "pages",
    localization: {
      ...commonLocalization(t),
      noRecordsToDisplay: t("There are no bookings")
    },
    muiSelectCheckboxProps: ({ row }) => ({
      icon:
        row.original?.statusData?.status === BookingRequestsStatus.REJECTED ? (
          <DoNotDisturb />
        ) : (
          <CheckBoxOutlineBlank />
        )
    }),
    renderBottomToolbarCustomActions: () => (
      <Box>
        <Button
          data-testid="booking-requests-Reject-btn"
          onClick={handleRejectBookings}
          color={"error"}
          sx={{ ml: 1, mr: 1 }}
        >
          {t("Reject")}
        </Button>
        <Button
          data-testid="booking-requests-Approve-btn"
          onClick={handleApproveBookings}
          sx={{ ml: 1, mr: 1 }}
        >
          {t("Approve")}
        </Button>
      </Box>
    )
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MaterialReactTable table={table} />
      </LocalizationProvider>

      <ScheduleSelectionDialog
        isEditable={false}
        /* istanbul ignore next */
        isOpen={!!showBookingDetail.schedule && showBookingDetail.open}
        onClose={() => setShowBookingDetail({ schedule: null, open: false })}
        schedule={{ ...showBookingDetail.schedule } as BookingScheduleInterface}
        calendarData={[]}
        remoteUsers={remoteUsers}
        handleRejectBookings={handleRejectBookingsOnPanel}
        handleApproveBookings={handleApproveBookingsOnPanel}
        onScheduleChange={handleShowFloor}
      />

      {/* Confirm Deletion Modal */}
      <TableDeleteConfirmDialog
        dialogTitle={
          deletionSelected?.length === 1
            ? t("_bookingRequestsSingleRejectTitle")
            : t("_bookingRequestsMultiRejectTitle")
        }
        dialogDesc={
          deletionSelected?.length === 1
            ? t("_bookingRequestsSingleRejectDesc")
            : t("_bookingRequestsMultiRejectDesc")
        }
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        /* istanbul ignore next */
        onConfirm={handleConfirmRejectBookings}
        deleteStatus={patchBookingRequestsStatus}
      />

      {/* dialog to display where the selected schedule on the floor plan */}
      {selectedSchedule && (
        <BookingSvgDialog
          isShowDialogOpen={isShowFloorDialogOpen}
          bookingId={selectedSchedule.id}
          bookingType={selectedSchedule.bookingType as BookingType}
          highlight={selectedSchedule.bookingInventoryId}
          closeFn={handleShowFloorCloseFn}
          labelButton={t("Close")}
        />
      )}
    </>
  );
};

export default BookingRequestsManagement;
