import { Weekdays } from "../../Pickers/WeekdayPicker/weekday-picker.component";

export function weekdaysToToggle(newToggles: string[]): Weekdays {
  return {
    monday: newToggles.includes("monday"),
    tuesday: newToggles.includes("tuesday"),
    wednesday: newToggles.includes("wednesday"),
    thursday: newToggles.includes("thursday"),
    friday: newToggles.includes("friday"),
    saturday: newToggles.includes("saturday"),
    sunday: newToggles.includes("sunday")
  };
}
