import { useEffect, useState } from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ArrowForwardIos } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { UserOrEmailMapping } from "../../../features/Admin/typings/admin.types";
import { TeamMember } from "../../../features/Booking-Form/typings/team-member";
import { getOptionLabelOfUserOrEmailMapping } from "../../../utils/type/type.utils";
import { BookingScheduleInterface } from "../../../features/Booking-Form/typings/booking.types";
import { useRemoteUpdateBookingGuests } from "../../../hooks/Remote/Booking/useRemoteUpdateBookingGuest";
import { useRemoteFetchUserInfoForAllUsers } from "../../../hooks/Remote/User/UserInfo/useRemoteFetchUserInfoForAllUsers";
import {
  filterParticipantsOpt,
  inputDialogEditParticipants,
  isOptionEqualToVal,
  renderParticiapantTags
} from "../../BookingForm/functions/form-zone-restriction.functions";
import { ScheduleContentList } from "../../Schedule/ScheduleCalendar/ScheduleSelectionDialog/ScheduleContentList.component";
import { genZoneAccessWithExternal } from "./input-dialog-schedule.functions";

/**
 * @description Modal that displays current zone access users and lets the user edit them if allowed to.
 */

export interface SimpleDialogProps {
  appointmentDataId: { id: number };
  selectedUserBookedFor: string;
  /** whether booking type is conferencezone */
  mode: boolean;
  disabled?: boolean;
  users?: TeamMember[];
  schedule: BookingScheduleInterface;
  refetchAllSchedule?: () => void;
}

export default function InputDialogSchedule(props: SimpleDialogProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { appointmentDataId, schedule, mode, refetchAllSchedule } = props;

  const [userOptions, setUserOptions] = useState<UserOrEmailMapping[]>([]);
  const [zoneAccess, setZoneAccess] = useState<UserOrEmailMapping[]>([]);

  const dialogTitleForGuests = schedule.isGuest ? t("Show Guests") : t("Edit Guests");
  const dialogTitle = mode ? t("Edit Zone Access") : dialogTitleForGuests;
  const placeHolderText = schedule.isGuest ? "" : t("Choose your colleagues");

  const { data: remoteUsers, isFetching: isRemoteUsersFetching } =
    useRemoteFetchUserInfoForAllUsers();
  const { mutateAsync: updateBookingGuests, isLoading: isUpdateLoading } =
    useRemoteUpdateBookingGuests();

  // Function that pushes the id's of new zone access users to an array and sends that to the backend
  const handleSave = () => {
    if (mode) {
      // setZoneBookingUserByZoneBookings is used in the past
      // then => enqueueSnackbar(t("Successfully edited access rights"), { variant: "success" }))
      // catch => enqueueSnackbar(t(`Error while editing access rights`), { variant: "error" })
    } else {
      // if external sending email, if not remove email property
      const newVal = genZoneAccessWithExternal(zoneAccess);

      updateBookingGuests({
        noPlaceZoneBookingId: appointmentDataId.id,
        conferenceZoneBookingGuests: newVal
      }).then(() => refetchAllSchedule?.());
    }
  };

  useEffect(() => {
    /** initially fetch user options to select and find guests info */
    if (remoteUsers) setUserOptions(remoteUsers);

    /** get zone access(guests list) */
    if (mode) {
      // when booking type is not conferencezone
      // getZoneBookingUserByZoneBookings is used in the past
    } else {
      /** find guests from user list */
      const guests = userOptions.filter(user =>
        schedule.guests.map(g => g.userId).includes(user.userId || "")
      );

      /** add the external guests if existed */
      const externalGuests = schedule.guests
        .filter(guest => !!guest.external)
        .map(g => ({ ...g, firstName: "external", surname: "external", companyId: 0 }));

      setZoneAccess([...guests, ...externalGuests]);
    }
  }, [schedule, userOptions, isRemoteUsersFetching]);

  return (
    <ScheduleContentList
      contentIcon={<ArrowForwardIos fontSize="small" />}
      contentTitle={dialogTitle}
      contents={[]}
      contentChildren={
        <Grid container data-testid="input-dialog-schedule-invite-guest-container">
          <Grid item xs={12} data-testid="input-dialog-schedule-invite-guest-item">
            <Autocomplete
              multiple
              disableCloseOnSelect
              options={userOptions}
              disabled={props.disabled}
              data-testid={"input-dialog-conference-edit-guests"}
              freeSolo
              filterOptions={filterParticipantsOpt}
              handleHomeEndKeys
              filterSelectedOptions
              isOptionEqualToValue={isOptionEqualToVal}
              getOptionLabel={getOptionLabelOfUserOrEmailMapping}
              onChange={(event, values, reason, details) => {
                switch (reason) {
                  case "selectOption":
                  case "removeOption": {
                    inputDialogEditParticipants(
                      reason,
                      values,
                      details,
                      setZoneAccess,
                      enqueueSnackbar
                    );
                    return;
                  }
                  case "clear": {
                    setZoneAccess(values as UserOrEmailMapping[]);
                    return;
                  }
                }
              }}
              value={zoneAccess || []}
              renderInput={params => (
                <TextField
                  {...params}
                  variant={"standard"}
                  fullWidth
                  placeholder={placeHolderText}
                  className="autocompletefield"
                />
              )}
              renderTags={renderParticiapantTags}
            />
          </Grid>

          {/* confirm button */}
          {!schedule.isGuest && (
            <Grid item xs={12} alignContent={"center"} textAlign={"end"} sx={{ mt: 1, pl: 1.5 }}>
              <LoadingButton
                loading={isUpdateLoading}
                data-testid={"confirmBTN"}
                disabled={props.disabled}
                variant="contained"
                onClick={handleSave}
              >
                {t("Confirm")}
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      }
    />
  );
}
