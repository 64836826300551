import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderGetTagProps,
  Box,
  Chip,
  FilterOptionsState
} from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { GroupAdd } from "@mui/icons-material";
import { ProviderContext } from "notistack";
import { UserOrEmailMapping } from "../../../features/Admin/typings/admin.types";
import { email as emailReg } from "../../../features/Booking-Form/typings/schema";
import i18n from "../../../i18n";
import { AppDispatch } from "../../../app/store";

export function genParticipantTagLabel(option: string | UserOrEmailMapping): string {
  if (typeof option === "string") return "";
  if (option.firstName === "external" && option.surname === "external") return option.email || "";
  return option.userId && option.firstName
    ? option.firstName + " " + option.surname
    : option.email ?? "";
}

export function isExternalEmail(option: string | UserOrEmailMapping): boolean {
  if (typeof option === "string") return true;
  if (option.firstName === "external" && option.surname === "external") return true;
  return false;
}

export function isOptionEqualToVal(
  option: string | UserOrEmailMapping,
  value: string | UserOrEmailMapping
) {
  if (typeof option === "string" || typeof value === "string") return true;
  return option.email === value.email;
}

export function mergeRequiredAndOptionalUsers(
  required: UserOrEmailMapping[],
  optional: UserOrEmailMapping[]
) {
  const updatedZoneAccess = [
    ...required.map(user => ({ ...user, isRequired: true })),
    ...optional.map(user => ({ ...user, isRequired: false }))
  ];
  return updatedZoneAccess;
}

export function selectOrRemoveParticipantsOpt(
  values: UserOrEmailMapping[],
  details: AutocompleteChangeDetails<string | UserOrEmailMapping> | undefined,
  optionalUsers: UserOrEmailMapping[],
  reason: AutocompleteChangeReason
) {
  const result = values;

  updateExternalEmail(details, reason);

  const unselectedOptionalUsers2 = optionalUsers.filter(
    user => !values.some(valueUser => valueUser.userId === user.userId)
  );
  return { unselectedOptionalUsers2, result };
}

export function filterParticipantsOpt(
  options: (string | UserOrEmailMapping)[],
  params: FilterOptionsState<string | UserOrEmailMapping>
) {
  const filter = createFilterOptions<UserOrEmailMapping>();

  const opts = options as UserOrEmailMapping[];
  const filtered = filter(opts, params);
  const isExisting = opts.some(opt => params.inputValue === opt.email);

  /* istanbul ignore next */
  if (params.inputValue !== "" && !isExisting) {
    filtered.push({
      firstName: "external",
      surname: "external",
      companyId: 0,
      email: `Add "${params.inputValue}"`,
      userId: ""
    });
  }

  return filtered;
}

export function renderParticiapantTags(
  tagValue: (string | UserOrEmailMapping)[],
  getTagProps: AutocompleteRenderGetTagProps
) {
  const tag = tagValue.map((option, index) => {
    const { key, ...rest } = getTagProps({ index });
    return (
      <Chip
        key={key}
        {...rest}
        label={genParticipantTagLabel(option)}
        icon={
          <>
            {isExternalEmail(option) && (
              <Box data-testid="autocomplete-participants-tags-box" sx={{ pl: 1, pt: 1 }}>
                <GroupAdd />
              </Box>
            )}
          </>
        }
      />
    );
  });

  return tag;
}

export function validateInputEmailValue(
  reason: AutocompleteChangeReason,
  details: AutocompleteChangeDetails<string | UserOrEmailMapping> | undefined
) {
  if (reason === "selectOption") {
    let checkInputVal = "";

    if (typeof details?.option === "string") {
      checkInputVal = details.option;
    } else if (details?.option.firstName !== "external" && details?.option.surname !== "external") {
      return true;
    } else {
      const ema = details?.option.email || "";
      const checkEma = ema.split(" ")[1];
      const newCheckEma = checkEma.slice(1, checkEma.length - 1);
      checkInputVal = newCheckEma;
    }
    return emailReg.test(checkInputVal);
  }
  return true;
}

export function handleSelectOptionalOpt({
  state: { reason, details, optionalVal, requiredUsers },
  action: { setOptionalUsers, dispatch, setInputs, enqueueSnackbar }
}: {
  state: {
    reason: AutocompleteChangeReason;
    details: AutocompleteChangeDetails<string | UserOrEmailMapping> | undefined;
    optionalVal: UserOrEmailMapping[];
    requiredUsers: UserOrEmailMapping[];
  };
  action: {
    setOptionalUsers: (u: UserOrEmailMapping[]) => void;
    dispatch: AppDispatch;
    setInputs: any;
    enqueueSnackbar: ProviderContext["enqueueSnackbar"];
  };
}) {
  // sanity check if inputValue is proper email address
  const valid = validateInputEmailValue(reason, details);
  if (!valid) return enqueueSnackbar(i18n.t("_validEmail"), { variant: "error" });

  updateExternalEmail(details, reason);
  setOptionalUsers(optionalVal);
  const updatedZoneAccess = mergeRequiredAndOptionalUsers(requiredUsers, optionalVal);
  dispatch(setInputs({ zoneAccess: updatedZoneAccess }));
}

export function handleSelectOrRemoveRequiredOpt({
  state: { valOpt, reason, details, optionalUsers, selectedOpt },
  action: {
    setRequiredUsers,
    setOptionalUsers,
    dispatch,
    setInputs,
    setOptionalUsersOptions,
    enqueueSnackbar
  }
}: {
  state: {
    valOpt: UserOrEmailMapping[];
    reason: AutocompleteChangeReason;
    details: AutocompleteChangeDetails<string | UserOrEmailMapping> | undefined;
    optionalUsers: UserOrEmailMapping[];
    selectedOpt: UserOrEmailMapping;
  };
  action: {
    setRequiredUsers: (u: UserOrEmailMapping[]) => void;
    setOptionalUsers: (u: UserOrEmailMapping[]) => void;
    dispatch: AppDispatch;
    setInputs: any;
    setOptionalUsersOptions: (value: React.SetStateAction<UserOrEmailMapping[]>) => void;
    enqueueSnackbar: ProviderContext["enqueueSnackbar"];
  };
}) {
  // sanity check if inputValue is proper email address
  const valid = validateInputEmailValue(reason, details);
  if (!valid) return enqueueSnackbar(i18n.t("_validEmail"), { variant: "error" });

  const { unselectedOptionalUsers2, result } = selectOrRemoveParticipantsOpt(
    valOpt,
    details,
    optionalUsers,
    reason
  );

  setOptionalUsers(unselectedOptionalUsers2);
  setRequiredUsers(result);
  dispatch(
    setInputs({
      zoneAccess: mergeRequiredAndOptionalUsers(result, unselectedOptionalUsers2)
    })
  );

  // add removed option to OptionalUsersOptions
  if (
    reason === "removeOption" &&
    selectedOpt.firstName !== "external" &&
    selectedOpt.surname !== "external"
  ) {
    setOptionalUsersOptions(prev => [...prev, details?.option as UserOrEmailMapping]);
  }
}

export function handleClearRequiredOpt({
  state: { valOpt, optionalUsers, requiredUsers },
  action: { setRequiredUsers, dispatch, setInputs, setOptionalUsersOptions }
}: {
  state: {
    valOpt: UserOrEmailMapping[];
    optionalUsers: UserOrEmailMapping[];
    requiredUsers: UserOrEmailMapping[];
  };
  action: {
    setRequiredUsers: (u: UserOrEmailMapping[]) => void;
    dispatch: AppDispatch;
    setInputs: any;
    setOptionalUsersOptions: (value: React.SetStateAction<UserOrEmailMapping[]>) => void;
  };
}) {
  const optional = optionalUsers.filter(user => !valOpt.some(val => val.email === user.email));
  const removedRequiredUsers = requiredUsers.filter(
    user => user.firstName !== "external" && user.surname !== "external"
  );
  setRequiredUsers(valOpt);
  dispatch(
    setInputs({
      zoneAccess: mergeRequiredAndOptionalUsers(valOpt, optional)
    })
  );
  setOptionalUsersOptions(prev => [...prev, ...removedRequiredUsers]);
}

export function inputDialogEditParticipants(
  reason: AutocompleteChangeReason,
  values: (string | UserOrEmailMapping)[],
  details: AutocompleteChangeDetails<string | UserOrEmailMapping> | undefined,
  setZoneAccess: (a: UserOrEmailMapping[]) => void,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"]
) {
  // sanity check if inputValue is proper email address
  const valid = validateInputEmailValue(reason, details);
  if (!valid) return enqueueSnackbar(i18n.t("_validEmail"), { variant: "error" });

  updateExternalEmail(details, reason);
  setZoneAccess(values as UserOrEmailMapping[]);
}

export function updateExternalEmail(
  details: AutocompleteChangeDetails<string | UserOrEmailMapping> | undefined,
  reason: AutocompleteChangeReason
) {
  const newSel = details?.option as UserOrEmailMapping;
  if (
    newSel.firstName === "external" &&
    newSel.surname === "external" &&
    reason === "selectOption"
  ) {
    const ema = newSel.email?.split(" ")[1];
    if (!ema) return;
    const newEmail = ema.slice(1, ema.length - 1);
    newSel.email = newEmail;
    newSel.external = true;
  }
}
