import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogTitle,
  Grid,
  LinearProgress,
  Tooltip,
  Typography
} from "@mui/material";
import { AddLocation, FlashOn } from "@mui/icons-material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "../../app/helpers";
import { ChosenTimesWarnings } from "../../features/Checkin/components/chosen-times-warnings.component";
import { LocationUsage } from "../../features/Reports/typings/reports.types";
import { quickAutomatedPlaceBooking } from "../../utils/axios-requests";
import { checkEndBeforeStart, checkStartInPast } from "../../utils/utilities";
import { TimePickers } from "../Pickers/time-pickers.component";
import { buttonColor } from "./top-list.functions";
import { RootState } from "../../app/rootReducer";
import { LocationDetails } from "./LocationDetails";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";
import { initBookingTime } from "../BookingForm/functions/date-time.functions";

type P = {
  value: LocationUsage;
  setIsQuickAutomatedPlaceBooking: (q: boolean) => void;
  displayBookNowButton?: boolean;
};

/**
 * Shows A list of top locations, with location picture, and usage graph.
 * @param props.value Information like usage duration, location picture and name.;
 */
export const TopList: React.FC<P> = ({
  value: { locationName, picturePath, relativeDuration, locationInventoryId, isMobileWorking },
  setIsQuickAutomatedPlaceBooking,
  displayBookNowButton = true
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    settings: { standardStartTime, standardEndTime, colorMode },
    userInformation: {
      sub,
      company: { meta }
    }
  } = useSelector((state: RootState) => state.login);
  const { enqueueSnackbar } = useSnackbar();

  const [chosenTimes, setChosenTimes] = useState({
    startTime: standardStartTime,
    endTime: standardEndTime
  });

  const isTodayAWeekday = DateTime.now().weekday !== 6 && DateTime.now().weekday !== 7;
  const isBookingAllowedForSaturday =
    meta.bookingsOnSaturdaysAllowed && DateTime.now().weekday === 6;
  const isBookingAllowedForSunday = meta.bookingsOnSundaysAllowed && DateTime.now().weekday === 7;
  const checkBookingAllowedDay =
    isTodayAWeekday || isBookingAllowedForSaturday || isBookingAllowedForSunday;

  const handleBooking = () => {
    setLoading(true);
    quickAutomatedPlaceBooking({
      users: [
        {
          userId: sub,
          isExternal: false,
          isMobileWorking: isMobileWorking
        }
      ],
      bookingType: BookingType.WORKPLACE,
      endDate: DateTime.now().toFormat("yyyy-MM-dd"),
      startDate: DateTime.now().toFormat("yyyy-MM-dd"),
      endTime: chosenTimes.endTime,
      startTime: chosenTimes.startTime,
      locationInventoryId
    })
      .then(() => {
        enqueueSnackbar(t("Successfully booked the workplace."), { variant: "success" });
        setIsQuickAutomatedPlaceBooking(true);
        setOpen(false);
        setLoading(false);
      })
      .catch(() => {
        enqueueSnackbar(t("There was a booking error"), { variant: "error" });
        setOpen(false);
        setLoading(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // initialize booking time
    const [initStartTime, initEndTime] = initBookingTime(standardStartTime, standardEndTime);
    setChosenTimes({ startTime: initStartTime, endTime: initEndTime });
  }, [standardEndTime, standardStartTime]);

  return (
    <>
      <Grid
        data-testid="top-list-container"
        container
        direction={"column"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        sx={{ width: "100%" }}
      >
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <LocationDetails locationName={locationName} picturePath={picturePath} />
          {checkBookingAllowedDay && displayBookNowButton && (
            <>
              {locationName !== "No Booking Time" ? (
                <Button
                  onClick={() => setOpen(true)}
                  sx={{ color: buttonColor(colorMode) }}
                  size={"small"}
                  variant="outlined"
                  endIcon={<AddLocation />}
                  data-testid="location-book-btn"
                >
                  {t("book now")}
                </Button>
              ) : (
                <Link style={{ textDecoration: "none" }} to={"/booking/0"}>
                  <Button
                    style={{ textDecoration: "none" }}
                    size={"small"}
                    variant="outlined"
                    sx={{ color: buttonColor(colorMode) }}
                    endIcon={<AddLocation />}
                    data-testid="no-location-book-btn"
                  >
                    {t("book now")}
                  </Button>
                </Link>
              )}
            </>
          )}
        </Grid>
        <Grid container direction={"column"} justifyContent={"center"} sx={{ width: "100%" }}>
          <Typography style={{ margin: "10px 0 10px 0" }} variant={"body1"}>
            {t(locationName)}
          </Typography>

          {meta.showBookingStatistics && (
            <>
              <Grid
                style={{ marginBottom: 10 }}
                container
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant={"h5"}>{(relativeDuration * 100).toFixed(0)}%</Typography>
                <Typography variant={"caption"}>{t("last 30 days")}</Typography>
              </Grid>
              <LinearProgress
                style={{ borderRadius: 10, height: 5 }}
                variant="determinate"
                value={relativeDuration * 100}
                color="primary"
              />
            </>
          )}
        </Grid>
      </Grid>

      {/* dialog to book now on the selected location */}
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{ padding: 20 }}
        >
          <DialogTitle style={{ padding: "16px 0 0 0" }} id="simple-dialog-title">
            {t(locationName)}
          </DialogTitle>
          <Tooltip enterTouchDelay={0} title={t("_freeWorkplaceChosen")} aria-label="add">
            <Chip
              style={{ width: 200 }}
              variant="outlined"
              size="small"
              avatar={<FlashOn />}
              label={t("_automaticSeating")}
            />
          </Tooltip>
          <Typography style={{ marginBottom: 20 }} variant={"caption"}>
            {t("today")}
          </Typography>
          <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"}>
            <TimePickers
              chosenTimes={chosenTimes}
              setChosenTimes={setChosenTimes}
              editable={{ start: true, end: true }}
            />
          </Grid>
          <ChosenTimesWarnings chosenTimes={chosenTimes} />

          <Button
            data-testid={"location-book-now-btn"}
            disabled={
              checkEndBeforeStart(
                DateTime.now().toFormat("yyyy-MM-dd").valueOf(),
                chosenTimes.startTime,
                DateTime.now().toFormat("yyyy-MM-dd").valueOf(),
                chosenTimes.endTime
              ) ||
              checkStartInPast(
                DateTime.now().toFormat("yyyy-MM-dd").valueOf(),
                chosenTimes.startTime
              ) ||
              loading
            }
            onClick={handleBooking}
            style={{ marginTop: 10 }}
            color={"primary"}
          >
            {t("Book now")}
          </Button>
        </Grid>
      </Dialog>
    </>
  );
};

export default TopList;
