import { AppDispatch } from "../../../app/store";
import { IFloorPayload } from "../../../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { getFilteredEquipmentInventories } from "../../../utils/axios-requests";
import { FloorAvailability } from "../../FloorManager/typings/floor-inventory.entity";
import { PricingModel } from "../../Login/typings/login.types";
import { MultiselectOption } from "../../Reports/typings/reports.types";
import { setBookingFilters, setFloors } from "../slices/booking.slice";
import { NotSerializedBookingInputs } from "../typings/booking-inputs";
import { FilterDto } from "../typings/inputs-filter";

export const enterpriseOrRLE = (pricingModels: PricingModel[], companyId: number) =>
  pricingModels.includes(PricingModel.ENTERPRISE) || companyId === 4;

export const stepToGoNextTo = (
  isMobileWorking: NotSerializedBookingInputs["isMobileWorking"],
  automatedSeating: NotSerializedBookingInputs["automatedSeating"],
  state: any,
  current: string | undefined,
  nextStep: () => void,
  prevStep: () => void
) => {
  if (isMobileWorking || automatedSeating) {
    const { prev } = state;
    if (current && current > prev) nextStep();
    else prevStep();
  }
};

/**
 * get current floor by floor index
 * initially select the most bookable floor for the first floor
 */
export const getCurrentFloor = (
  dispatch: AppDispatch,
  inventory: FloorAvailability[],
  currentFloorIndex?: number
): FloorAvailability => {
  if (inventory.length > 0 && currentFloorIndex === undefined) {
    const { mostBookableFloorIndex } = findMostBookableFloor(inventory);
    dispatch(setFloors({ currentFloorIndex: mostBookableFloorIndex }));
    return inventory[mostBookableFloorIndex];
  }

  if (currentFloorIndex) return inventory[currentFloorIndex];
  return inventory[0];
};

export const handlePlanResponse = (
  res: IFloorPayload,
  setFloorPlan: React.Dispatch<React.SetStateAction<IFloorPayload>>
) => {
  setFloorPlan(res);
};

export const setBookingFiltersState = (
  mode: NotSerializedBookingInputs["mode"],
  usersBookedFor: NotSerializedBookingInputs["usersBookedFor"],
  currentUser: number,
  dispatch: AppDispatch
) => {
  if (mode === "team" && usersBookedFor && usersBookedFor[currentUser]) {
    const userBookingFilters = usersBookedFor[currentUser].bookingFilters;
    dispatch(setBookingFilters(userBookingFilters));
  }
};

export const setFilters = (
  filter: FilterDto,
  setDeviceOptions: React.Dispatch<React.SetStateAction<MultiselectOption[]>>,
  setDeviceCategoryOptions: React.Dispatch<React.SetStateAction<MultiselectOption[]>>
) => {
  getFilteredEquipmentInventories(filter).then(res => {
    const optionsResponse = res.data.map((device: any) => {
      return {
        id: device.id,
        name: device.name
      };
    }) as MultiselectOption[];

    setDeviceOptions(optionsResponse);
  });

  // getFilteredDeviceCategories is removed for now
  setDeviceCategoryOptions([]);
};

export const selectWorkplaceFloor = (
  inventory: FloorAvailability[],
  dispatch: AppDispatch,
  currentFloorIndex?: number
) => {
  // If current floor has no empty workplaces select the next workplace.
  if (currentFloorIndex !== undefined && currentFloorIndex + 1 !== inventory.length) {
    if (inventory[currentFloorIndex]?.numberOfBookableObjects === 0) {
      const newIndex = currentFloorIndex + 1;
      dispatch(setFloors({ currentFloorIndex: newIndex }));
    }
  }
};

// among floors, choose the most numberOfBookableObjects has
export function findMostBookableFloor(floorInventory: FloorAvailability[]) {
  const mostBookableFloor = floorInventory.reduce((maxObj, currentObj) => {
    return currentObj.numberOfBookableObjects > maxObj.numberOfBookableObjects
      ? currentObj
      : maxObj;
  });

  const mostBookableFloorIndex = floorInventory.findIndex(inv => inv.id === mostBookableFloor.id);
  return { mostBookableFloor, mostBookableFloorIndex };
}
