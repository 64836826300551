import { useEffect } from "react";
import { Point } from "pixi.js";
import * as _ from "lodash";
import { IFloorPayload } from "../../../../../Domain/Types/FloorPlan/FloorPayload.type";
import { IZoneSchedule, IZoneUserNumber } from "../../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { IZoneInventory } from "../../../../../Domain/Types/FloorPlan/ZoneInventory.type";
import { IPlaceSchedule } from "../../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IPlaceInventory } from "../../../../../Domain/Types/FloorPlan/PlaceInventory.type";
import { IPermission, PermissionType } from "../../../../../Domain/Types/FloorPlan/Permission.type";
import { ICoordinate } from "../../../../../Domain/Types/FloorPlan/Coordinate.type";
import { ITimeframe } from "../../../../../Domain/Types/FloorPlan/Timeframe.type";
import { SingleTableArrangement } from "../../../../../Domain/Types/FloorPlan/TableArrangement.type";
import { WeekdayNames } from "../../../../../Domain/Types/FloorPlan/WeekdayNames.type";
import { useRemoteFetchFloorPlan } from "../../../../../../../hooks/Remote/FloorPlan/useRemoteFetchFloorPlan";
import { useInventoryManagement } from "../../../../../Hooks/useInventoryManagement";
import { useCategoryManagement } from "../../../../../Hooks/useCategoryManagement";
import { useEquipmentManagement } from "../../../../../Hooks/useEquipmentManagement";
import { useGeometry } from "../../../../../Hooks/useGeometry";
import {
  useHistoryState,
  HistoryStateActionType
} from "../../../../../Hooks/useHistoryState/useHistoryState";
import { IViewport } from "../../../../../Domain/Types/FloorPlan/Viewport.type";
import { SingleBookableEquipmentDto } from "../../../../Toolbars/InfoSidebar/ZoneInfoSidebar/BookableEquipmentDialogContent/typings/ServiceBookableEquipment.types";
import { EquipmentCategoryRow } from "../../../../Toolbars/InfoSidebar/EquipmentCategoryDialogContent/typings/EquipmentCategoryTableRow.type";
import { BookingPropertyRow } from "../../../../Toolbars/InfoSidebar/BookingPropertyDialogContent/typings/BookingPropertyTableRow.type";
import { BookingType } from "../../../../../../../features/Booking-Form/typings/booking-inputs";
import { IZone } from "../../../../../Domain/Types/FloorPlan/Zone.type";

export type FloorPlanUpdateStatus = "error" | "loading" | "success" | "idle";
export type NewPerms = {
  companies: {
    id: string;
    enforceUsage: boolean;
    activeWeekdays: WeekdayNames[];
    hoursInAdvance: number;
  }[];
  costCenterUnits: {
    id: string;
    enforceUsage: boolean;
    activeWeekdays: WeekdayNames[];
    hoursInAdvance: number;
  }[];
  users: {
    id: string;
    enforceUsage: boolean;
    activeWeekdays: WeekdayNames[];
    hoursInAdvance: number;
  }[];
};

const basicPerms: IPermission[] = [
  {
    id: 0,
    affectedUsers: [],
    affectedCompanies: [],
    affectedDepartments: [], // costCenter
    affectedUserId: null,
    affectedCompanyId: null,
    affectedDepartmentId: null, // costCenter
    activeWeekdays: [],
    enforceUsage: false, // might not necessary
    hoursInAdvance: 0, // might not necessary
    //
    type: PermissionType.WHITELIST,
    affectedTenants: [],
    affectedProjectUnits: [],
    affectedWorkOrderUnits: []
  }
];

export function useFloorplan(
  initialFloorPlan: IFloorPayload,
  selectedTimeframe: ITimeframe,
  floorPlanUpdateStatus: FloorPlanUpdateStatus
) {
  const {
    state: floorPlan,
    dispatch: dispatchFloorPlan,
    canUndo,
    canRedo
  } = useHistoryState<IFloorPayload>(initialFloorPlan, 150);
  const inventoryManagement = useInventoryManagement({ floorPlan, dispatchFloorPlan });
  const categoryManagement = useCategoryManagement({ floorPlan, dispatchFloorPlan });
  const equipmentManagement = useEquipmentManagement({ floorPlan, dispatchFloorPlan });
  const { convertPixiPointsToPosition } = useGeometry();

  const { refetch: refetchFloorPlan } = useRemoteFetchFloorPlan(
    initialFloorPlan.floorInventoryId,
    selectedTimeframe.start,
    selectedTimeframe.end
  );

  // refetch the floor plan once after the floor plan is published and status is success
  useEffect(() => {
    if (floorPlanUpdateStatus === "success") {
      refetchFloorPlan().then(res => {
        dispatchFloorPlan({
          type: HistoryStateActionType.UPDATE,
          payload: res.data as IFloorPayload
        });
      });
    }
  }, [floorPlanUpdateStatus]);

  function addZone(
    points: (Point | undefined)[],
    selectedTimeframe: ITimeframe,
    typeOfZone: number
  ) {
    const newFloorPlan = _.cloneDeep(floorPlan);
    const schedule: IZoneSchedule = {
      categoryId: 0,
      coordinates: convertPixiPointsToPosition(points),
      description: "newly created zone",
      disabled: false,
      end: selectedTimeframe.end ?? null,
      id: generateNegativeIds(newFloorPlan.zones),
      inventoryId: 0,
      permissions: basicPerms,
      start: selectedTimeframe.start,
      zoneTypeId: typeOfZone,
      equipmentInventoryIds: [],
      equipments: [],
      zoneEquipments: [],
      equipmentCategories: [],
      bookingProperties: [],
      // tableArrangements: [{ tableArrangementId: 0, preparationTime: null }]
      tableArrangements: [],
      approvalRequired: false,
      automatedApproval: false
    };
    if (typeOfZone === 3) {
      schedule.minUsers = 0;
      schedule.maxUsers = null;
    }
    newFloorPlan.zones.push(schedule);
    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  function updateZoneInventory(zoneIds: number[], inventory: IZoneInventory) {
    const newFloorPlan = _.cloneDeep(floorPlan);

    for (const id of zoneIds) {
      const targetZone = newFloorPlan.zones.find(zone => zone.id === id);

      if (targetZone) {
        targetZone.inventoryId = inventory.id;
        targetZone.inventory = inventory;

        const findType = adjustZoneType(newFloorPlan.zoneTypes).find(
          type => type.id === targetZone.zoneTypeId
        );

        targetZone.inventory.zoneTypeId = targetZone.zoneTypeId || 1;
        targetZone.inventory.zoneType = findType;
      }
    }
    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  function updatePlaceInventory(placeIds: number[], inventory: IPlaceInventory) {
    const newFloorPlan = _.cloneDeep(floorPlan);

    for (const id of placeIds) {
      const targetPlace = newFloorPlan.places.find(place => place.id === id);

      if (targetPlace) {
        targetPlace.inventoryId = inventory.id;
        targetPlace.inventory = inventory;

        const findType = newFloorPlan.placeTypes.find(type => type.id === targetPlace.placeTypeId);

        targetPlace.inventory.placeTypeId = targetPlace.placeTypeId || 1;
        targetPlace.inventory.placeType = findType;
        targetPlace.inventory.boundingBox = findType?.defaultBoundingBox ||
          findType?.appearance.boundingBox || { width: 160, height: 80 };
      }
    }

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  function addDesks(
    opts: { start: string; end: string | null; selectedZoneId: number; placeTypeId: number },
    ...desks: Array<{ position: ICoordinate; rotate: number }>
  ) {
    const newFloorPlan = _.cloneDeep(floorPlan);

    for (const newDesk of desks) {
      newFloorPlan.places.push({
        id: generateNegativeIds(newFloorPlan.places),
        inventoryId: NaN,
        categoryId: 0,
        description: "A new place",
        disabled: false,
        end: opts.end,
        permissions: basicPerms,
        position: newDesk.position,
        zoneScheduleId: opts.selectedZoneId,
        rotate: newDesk.rotate,
        start: opts.start,
        equipmentInventoryIds: [],
        equipments: [],
        placeEquipments: [],
        equipmentCategories: [],
        bookingProperties: [],
        placeTypeId: opts.placeTypeId,
        approvalRequired: false,
        automatedApproval: false
      });
    }

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  function transFormDesks(...desksTransforms: ObjectTransform) {
    const newFloorPlan = _.cloneDeep(floorPlan);

    for (const desksTransform of desksTransforms) {
      // find the desk
      for (const id of desksTransform.ids) {
        const desk = newFloorPlan.places.find(workplace => workplace.id === id);
        if (!desk) continue;

        // change desk position and rotation
        if (desksTransform.transform.x !== undefined) desk.position.x = desksTransform.transform.x;
        if (desksTransform.transform.y !== undefined) desk.position.y = desksTransform.transform.y;
        if (desksTransform.transform.r !== undefined) desk.rotate = desksTransform.transform.r;
      }
    }

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  function stretchDesks(...desksStretch: ObjectStretch) {
    const newFloorPlan = _.cloneDeep(floorPlan);

    for (const deskStretch of desksStretch) {
      // find the desk
      for (const id of deskStretch.ids) {
        const filteredPlace = newFloorPlan.places.find(workplace => workplace.id === id);
        if (!filteredPlace || !filteredPlace.inventory) continue;

        // find the desk inventory
        const filteredInven = newFloorPlan.places.find(
          wp => wp.inventoryId === filteredPlace.inventoryId
        )?.inventory;
        if (!filteredInven) continue;

        // change the width/height of place and its inventory
        changeStretchInventory(deskStretch, filteredPlace);
      }
    }

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  /**
   * moves the zone to a new location
   * @param zoneTransforms
   */

  function transformZone(...zoneTransforms: Array<ZoneTransforms>) {
    const newFloorPlan = _.cloneDeep(floorPlan);

    for (const zoneTransform of zoneTransforms) {
      const zone = newFloorPlan.zones.find(zn => zn.id === zoneTransform.id);

      // update coordinates
      if (zone) zone.coordinates = convertPixiPointsToPosition(zoneTransform.newCoordinates);
    }
    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  function deleteDesk(deskIds: number[]) {
    const newFloorPlan = _.cloneDeep(floorPlan);
    for (const id of deskIds) {
      newFloorPlan.places = newFloorPlan.places.filter(wp => wp.id !== id);
    }

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  function deleteZone(zoneIds: number[]) {
    const newFloorPlan = _.cloneDeep(floorPlan);
    for (const id of zoneIds) {
      newFloorPlan.zones = newFloorPlan.zones.filter(zn => zn.id !== id);
    }

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  function changeZone(
    zoneIds: number[],
    opts: {
      description?: string;
      name?: string;
      enabled?: boolean;
      perms?: NewPerms;
      numberOfUser?: IZoneUserNumber;
      approval?: BookingApproval;
      equipmentCategories?: EquipmentCategoryRow[];
      bookingProperties?: BookingPropertyRow[];
    }
  ) {
    const newFloorPlan = _.cloneDeep(floorPlan);
    for (const id of zoneIds) {
      const zone = newFloorPlan.zones.find(zn => zn.id === id);
      if (!zone) return;

      changeScheduleProperties(zone, opts);

      // update number of users for conference zone
      if (opts.numberOfUser) {
        zone.minUsers = opts.numberOfUser.minUsers;
        zone.maxUsers = opts.numberOfUser.maxUsers;
      }
    }

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  function changeDesk(
    deskIds: number[],
    opts: {
      description?: string;
      enabled?: boolean;
      name?: string;
      perms?: NewPerms;
      approval?: BookingApproval;
      equipmentCategories?: EquipmentCategoryRow[];
      bookingProperties?: BookingPropertyRow[];
    }
  ) {
    const newFloorPlan = _.cloneDeep(floorPlan);
    for (const id of deskIds) {
      const desk = newFloorPlan.places.find(wp => wp.id === id);
      if (!desk) return;

      changeScheduleProperties(desk, opts);
    }

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  function editConferenceService(
    zoneScheduleId: number,
    opts: {
      tableAssignment?: SingleTableArrangement[];
      bookableEquipments?: SingleBookableEquipmentDto[];
    }
  ) {
    const newFloorPlan = _.cloneDeep(floorPlan);

    const zone = newFloorPlan.tableArrangementAssignments?.find(
      zn => zn.zoneScheduleId === zoneScheduleId
    );
    if (!zone) return;
    if (opts.tableAssignment && opts.tableAssignment.length > 0) {
      if (!zone.tableAssignmentIds) zone.tableAssignmentIds = [];
      zone.tableAssignmentIds = opts.tableAssignment;
    }

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  function editFloorFrame(newOutlineUrl: string, newViewport: IViewport) {
    const newFloorPlan = _.cloneDeep(floorPlan);

    newFloorPlan.viewport = newViewport;
    newFloorPlan.outlineUrl = newOutlineUrl;

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  return {
    floorPlan,
    addZone,
    transFormDesks,
    stretchDesks,
    deleteDesk,
    addDesks,
    deleteZone,
    transformZone,
    changeZone,
    changeDesk,
    editConferenceService,
    updateZoneInventory,
    updatePlaceInventory,
    inventoryManagement,
    categoryManagement,
    dispatchFloorPlan,
    canUndo,
    canRedo,
    equipmentManagement,
    editFloorFrame
  };
}

export type Transform = { x?: number; y?: number; r?: number };
export type SingleObjectTransform = { id: number; inventoryId?: number; transform: Transform };
export type MultiObjectTransform = { ids: number[]; transform: Transform };
export type ObjectTransform = Array<MultiObjectTransform>;

export type Stretch = { width?: number; height?: number };
export type MultiObjectStretch = { ids: number[]; stretch: Stretch };
export type ObjectStretch = Array<MultiObjectStretch>;

export type BookingApproval = { approvalRequired?: boolean; automatedApproval?: boolean };

export type ZoneTransforms = { id: number; newCoordinates: Point[] };

export function changeStretchInventory(
  deskStretch: MultiObjectStretch,
  filteredPlace: IPlaceSchedule
) {
  if (!filteredPlace.inventory) return;

  // change the width of place and its inventory
  if (deskStretch.stretch && deskStretch.stretch.width !== undefined) {
    filteredPlace.inventory.boundingBox.width = deskStretch.stretch.width;
  }
  // change the height of place and its inventory
  if (deskStretch.stretch.height !== undefined) {
    filteredPlace.inventory.boundingBox.height = deskStretch.stretch.height;
  }
}

/**
 * generate negative ids for new places/zones sequentially.
 * it will handle deleting ids of new items easily when publishing the floor plan
 */
export function generateNegativeIds(newItems: any[]) {
  const negativeIds = newItems.filter(wp => wp.id < 0);
  if (negativeIds.length === 0) return -1;

  const ids = negativeIds.map(i => i.id);
  const latest = ids[ids.length - 1];
  return latest - 1;
}

export function updatePerms(newPerms: NewPerms, selected: IPlaceSchedule | IZoneSchedule) {
  // check whether selected item has perms
  if (!selected.permissions || !selected.permissions.length) selected.permissions = basicPerms;

  let newPermComp: IPermission[] = [];
  let newPermDepa: IPermission[] = [];
  let newPermUsers: IPermission[] = [];

  if (newPerms.companies && newPerms.companies.length) {
    const upd = newPerms.companies.map(cp => {
      const old = { ...basicPerms[0] };
      old.affectedCompanies = [
        { id: cp.id, enforceUsage: cp.enforceUsage, hoursInAdvance: cp.hoursInAdvance }
      ];
      old.activeWeekdays = cp.activeWeekdays;
      old.hoursInAdvance = cp.hoursInAdvance;
      return old;
    });
    newPermComp = upd;
  }

  if (newPerms.costCenterUnits && newPerms.costCenterUnits.length) {
    const upd = newPerms.costCenterUnits.map(cp => {
      const old = { ...basicPerms[0] };
      old.affectedDepartments = [
        { id: cp.id, enforceUsage: cp.enforceUsage, hoursInAdvance: cp.hoursInAdvance }
      ];
      old.activeWeekdays = cp.activeWeekdays;
      old.hoursInAdvance = cp.hoursInAdvance;
      return old;
    });
    newPermDepa = upd;
  }
  if (newPerms.users && newPerms.users.length) {
    const upd = newPerms.users.map(cp => {
      const old = { ...basicPerms[0] };
      old.affectedUsers = [
        { id: cp.id, enforceUsage: cp.enforceUsage, hoursInAdvance: cp.hoursInAdvance }
      ];
      old.activeWeekdays = cp.activeWeekdays;
      old.hoursInAdvance = cp.hoursInAdvance;
      return old;
    });
    newPermUsers = upd;
  }

  selected.permissions = [...newPermComp, ...newPermDepa, ...newPermUsers];
}

export function updateBookingApproval(
  newApproval: BookingApproval,
  selected: IPlaceSchedule | IZoneSchedule
) {
  if (newApproval.approvalRequired !== undefined) {
    selected.approvalRequired = newApproval.approvalRequired;
    if (!newApproval.approvalRequired) newApproval.automatedApproval = false;
  }

  if (newApproval.automatedApproval !== undefined)
    selected.automatedApproval = newApproval.automatedApproval;
}

export function changeScheduleProperties(
  item: IPlaceSchedule | IZoneSchedule,
  opts: {
    description?: string;
    enabled?: boolean;
    name?: string;
    perms?: NewPerms;
    approval?: BookingApproval;
    equipmentCategories?: EquipmentCategoryRow[];
    bookingProperties?: BookingPropertyRow[];
  }
) {
  if (opts.description) item.description = opts.description;
  if (opts.enabled !== undefined) item.disabled = !opts.enabled;
  if (opts.name !== undefined && item.inventory) item.inventory.name = opts.name;
  if (opts.perms) updatePerms(opts.perms, item);
  if (opts.approval) updateBookingApproval(opts.approval, item);
  if (opts.equipmentCategories) item.equipmentCategories = opts.equipmentCategories;
  if (opts.bookingProperties) item.bookingProperties = opts.bookingProperties;
}

/**
 * conference zone is able to draw regardless of booking availability
 * @see FE issue #1376
 * but in the process of create and update zone, adjust zoneTypes with conferencezone
 */
export function adjustZoneType(zoneTypes: IZone[]) {
  const isConferenceZoneExisted = zoneTypes.some(type => type.id === 3);
  if (isConferenceZoneExisted) return zoneTypes;
  else {
    const conf = { id: 3, name: BookingType.CONFERENCEZONE } as IZone;
    zoneTypes = [...zoneTypes, conf];
    return zoneTypes;
  }
}
