import { DateTime } from "luxon";
import { Dispatch, SetStateAction } from "react";
import { checkDatumValue } from "../../../components/FacilityManager/Functions/handleDatumValue";
import { Timeframe } from "../typings/shared/timeframe";

export const handleStartDateChange = (
  date: Date | null | string,
  setStartDate: Dispatch<SetStateAction<DateTime | null>>
): void => {
  if (!date) return setStartDate(null);

  const d = checkDatumValue(date);

  setStartDate(d.set({ hour: 0, minute: 1, second: 0 }));
};

export const sortTimeframes = (timeframes: Timeframe[]) => {
  return timeframes.sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime());
};
