import { UpdateConferenceAttributes } from "../../../features/Schedule/schedule.types";
import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";

export function useRemoteUpdateNoPlaceZoneBookingScheduleAttributes() {
  return useRemoteMutation<void, RemoteApiError, UpdateConferenceAttributes>({
    key: "useRemoteUpdateNoPlaceZoneBookingScheduleAttributes",
    method: "put",
    url: `/booking-schedule/no-place-zone-booking-attributes`
  });
}
