import { Dispatch, SetStateAction } from "react";
import { MbscCalendarEvent, MbscPageLoadedEvent, MbscPageLoadingEvent } from "@mobiscroll/react";
import { AxiosResponse } from "axios";
import { ProviderContext } from "notistack";
import { DateTime } from "luxon";
import { TFunction } from "i18next";
import {
  BookingScheduleInterface,
  RestrictionZone
} from "../../features/Booking-Form/typings/booking.types";
import { TeamMember } from "../../features/Booking-Form/typings/team-member";
import { PricingModel } from "../../features/Login/typings/login.types";
import { UpdateBookingTimes } from "../../features/Schedule/schedule.types";
import i18n from "../../i18n";
import {
  getNoPlaceZoneBookingSchedule,
  getPlaceSchedule,
  getPlaceZoneSchedule,
  updateBookingTimes,
  updateConferenceZoneBookingTimes
} from "../../utils/axios-requests";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";

export const getWorkplacesOrZones = (
  startDate: string,
  endDate: string,
  mode: string,
  users: TeamMember[] | undefined,
  userInfo: { sub: string; pricingModels: PricingModel[] },
  selectedRestriction: RestrictionZone | undefined,
  selectedTypes: string[]
): Promise<AxiosResponse<BookingScheduleInterface[]>> => {
  /** currently don't use placeZone call */
  const useRequest = false;

  if (mode === "place") {
    return getPlaceSchedule({
      userIds: users ? users.map((user: { userId: string }) => user.userId) : [userInfo.sub],
      startDate,
      bookingType: selectedTypes,
      endDate
    });
    /* istanbul ignore next */
  } else if (
    useRequest &&
    mode === "zone" &&
    !userInfo.pricingModels.includes(PricingModel.ENTERPRISE)
  ) {
    return getPlaceZoneSchedule({
      userIds: [userInfo.sub],
      startDate,
      endDate
    });
    /* istanbul ignore next */
  } else if (useRequest && mode === "zone") {
    return getPlaceZoneSchedule({
      userIds: [userInfo.sub],
      startDate,
      endDate,
      zoneBookingObject: selectedRestriction?.object.zoneBookingObject,
      client: selectedRestriction?.company.id || undefined
    });
  } else {
    return getNoPlaceZoneBookingSchedule({
      userIds: users
        ? users.map((colleague: { userId: string }) => colleague.userId)
        : [userInfo.sub],
      startDate,
      endDate
    });
  }
};

export const checkIndex = (
  calendarData: MbscCalendarEvent[],
  dto: UpdateBookingTimes,
  setCalendarData?: Dispatch<SetStateAction<MbscCalendarEvent[]>>
) => {
  const resizedIndex = calendarData.findIndex(c => c.id === dto?.event?.event.id);
  if (resizedIndex >= 0) {
    const resetCalendarData = calendarData.slice();
    resetCalendarData[resizedIndex] = dto?.event.oldEvent;
    setCalendarData?.(resetCalendarData);
  }
};

export const updateTimes = async (
  dto: UpdateBookingTimes,
  sub: string,
  schedule: BookingScheduleInterface | undefined,
  calendarData: MbscCalendarEvent[],
  {
    enqueueSnackbar,
    setCalendarData,
    refetchAllSchedule,
    cancelChanges
  }: {
    enqueueSnackbar: ProviderContext["enqueueSnackbar"];
    setCalendarData?: Dispatch<SetStateAction<MbscCalendarEvent[]>>;
    refetchAllSchedule?: () => void;
    cancelChanges?: () => void;
  }
) => {
  if (!schedule) return;
  if (schedule.bookingType === BookingType.CONFERENCEZONE) {
    try {
      await updateConferenceZoneBookingTimes({
        ...dto,
        bookingType: schedule.bookingType as BookingType
      });
      enqueueSnackbar(i18n.t("Successfully edited booking"), {
        variant: "success"
      });
      refetchAllSchedule?.();
      return;
    } catch (error: unknown) {
      cancelChanges?.();
      return enqueueSnackbar(i18n.t(`changeBookingTimeError`), { variant: "error" });
    }
  }
  try {
    await updateBookingTimes({ ...dto, bookingType: schedule.bookingType as BookingType });
    enqueueSnackbar(i18n.t("Successfully edited booking"), {
      variant: "success"
    });
    refetchAllSchedule?.();
    return;
  } catch (error: unknown) {
    checkIndex(calendarData, dto, setCalendarData);
    cancelChanges?.();
    // Currently, there are no translations for errors in the backend
    // We show only a generic error message due to this reason
    // in the future, we show the backends error message instead!
    return enqueueSnackbar(i18n.t(`changeBookingTimeError`), { variant: "error" });
  }
};

export const checkInvalidCreateOnSchedule = (
  bookingStartDate: DateTime,
  bookingsOnSaturdaysAllowed: boolean | undefined,
  bookingsOnSundaysAllowed: boolean | undefined,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  t: TFunction
) => {
  // compare selected date with now time
  const todayDate = bookingStartDate.diffNow().as("day");

  if (todayDate < -1) return false;

  // consider saturday, sunday booking allowed settings
  if (
    (!bookingsOnSaturdaysAllowed && bookingStartDate.weekday === 6) ||
    (!bookingsOnSundaysAllowed && bookingStartDate.weekday === 7)
  ) {
    const weekdayInfo = bookingStartDate.weekday === 6 ? t("Saturday") : t("Sunday");
    enqueueSnackbar(t("_scheduleCreateOnCalendarWeekendCheck", { day: weekdayInfo }), {
      variant: "info"
    });
    return false;
  }

  return true;
};

export function updateCalendarDate(
  currentLoadingEvent: MbscPageLoadingEvent | MbscPageLoadedEvent | undefined,
  setCalendarDate: (d: { firstDay: string; lastDay: string }) => void,
  refetchAllSchedule?: () => void
) {
  if (!currentLoadingEvent) return;
  const { firstDay, lastDay } = currentLoadingEvent;

  const firstDayISOString = firstDay.toISOString();
  const lastDayISOString = lastDay.toISOString();

  const startDate = DateTime.fromISO(firstDayISOString).toFormat("yyyy-MM-dd HH:mm");
  const endDate = DateTime.fromISO(lastDayISOString).toFormat("yyyy-MM-dd HH:mm");

  setCalendarDate({
    firstDay: startDate,
    lastDay: endDate
  });

  refetchAllSchedule && refetchAllSchedule();
}
