import { Divider, Typography } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useDispatch } from "../../app/helpers";
import LocationInputs from "../../components/Forms/location-form.component";
import { fileSchema } from "../../utils/form.utils";
import { AccessPermissions } from "./partials/access.partials";
import { destroyModal } from "./slices/modals.slice";
import fetchAdminLocations from "./thunks/fetchAdminLocations";
import patchLocationById from "./thunks/patchLocationById";
import postLocation, { LocationWithImage } from "./thunks/postLocation";
import { FloorInventory } from "./typings/floor-inventory.entity";
import { LocationInventory, locationInventorySchema } from "./typings/location-inventory";
import { LocationDisabledScheduleInputs } from "./partials/DisabledScheduleInputs/LocationDisabledScheduleInputs/LocationDisabledScheduleInputs.component";
import { FormDialog2 as FormDialog } from "../../components/FacilityManager/Legacy/formDialog2.component";
import { deleteEmptyPerms } from "./functions/deleteEmptyPerms";

export const LocationDialog: React.FC<{ modal: any }> = ({ modal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isEditMode = modal.editTarget === 0 || !!modal.editTarget;

  return (
    <FormDialog
      key={modal.id}
      maxWidth="md"
      title={
        isEditMode
          ? t("Edit {{thing}}", { thing: t("location") })
          : t("Create {{thing}}", { thing: t("location") })
      }
      isOpen={modal.isOpen}
      tabLabels={[t("Edit"), t("Access")]}
      initialValues={modal.initialValues}
      schema={!isEditMode ? locationInventorySchema.and(fileSchema) : locationInventorySchema}
      handleCancel={() => dispatch(destroyModal(modal.id))}
      handleOk={values => /* istanbul ignore next */ {
        // check if company is not assigned, prohibit empty company assignment
        if (values.checkinRadiusInMeters <= 0) return;
        if (checkEmptyCompanyAssign(values)) return;

        const newVal = handleAssignCompany(values, isEditMode);
        const payload = makePayload(newVal);
        let action;
        if (isEditMode) action = patchLocationById(payload);
        else action = postLocation(payload);

        dispatch(action)
          .then(unwrapResult)
          .then(() => {
            dispatch(fetchAdminLocations());
            dispatch(destroyModal(modal.id));
          });
      }}
      primaryButtonLabel={t("Confirm")}
      secondaryButtonLabel={t("Cancel")}
    >
      <LocationInputs />
      {isEditMode ? (
        <div style={{ width: "100%" }}>
          <Typography variant={"h5"}>{t("Blocking")}</Typography>
          <LocationDisabledScheduleInputs editTarget={modal.editTarget as number} />
          <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
          <Typography variant={"h5"} sx={{ mb: 1 }}>
            {t("Permissions")}
          </Typography>
          <AccessPermissions />
        </div>
      ) : null}
    </FormDialog>
  );
};

/** export for testing */
export function makePayload(
  values: (FloorInventory | LocationInventory) & { file?: File }
): LocationWithImage {
  const payload = { payload: { ...values }, picture: values.file };

  if (!payload.payload.permissions?.length) return payload as any;

  // attach date time and restructure
  payload.payload.permissions = payload.payload.permissions.map((permi: any) => ({
    ...permi,
    start: DateTime.fromISO(permi.start).toISODate(),
    end: DateTime.fromISO(permi.end).toISODate(),
    workOrderUnits: permi.workOrderUnits?.map((unit: { id: any }) => unit.id),
    costCenterUnits: permi.costCenterUnits?.map((unit: { id: any }) => unit.id),
    projectUnits: permi.projectUnits?.map((unit: { id: any }) => unit.id),
    companies: permi.companies?.map((company: { id: any }) => company.id),
    users: permi.users?.map((user: { id: any }) => user.id)
  }));

  // delete items without any perm value
  payload.payload.permissions = deleteEmptyPerms(payload.payload.permissions);

  // completed remove permission array if no perm val has
  if (!payload.payload.permissions.length) delete payload.payload.permissions;

  return payload as any;
}

export function handleAssignCompany(
  values: LocationInventory,
  isEditMode: boolean
): LocationInventory {
  if (!values.schedule || !values.schedule.length) return values;

  if (isEditMode) {
    // update - companyId in the schedule
    return values;
  } else {
    // create - send companyId in plain
    values.companyId = values.schedule[0].companyId;
    return values;
  }
}

export function checkEmptyCompanyAssign(values: LocationInventory): boolean {
  if (!values.schedule || !values.schedule.length) return true;
  const latestIndex = values.schedule.findIndex(schedule => schedule.end === null);
  const scheduleCompanyId = values.schedule[latestIndex].companyId;

  if (!scheduleCompanyId) return true;
  return false;
}
