import { Dispatch, SetStateAction, useState } from "react";
import { MbscCalendarEvent } from "@mobiscroll/react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ScheduleEventBookingTypeContents } from "../../schedule-event-popover-booking-type-contents";
import { Colleague } from "../../../../features/Connections/types/Colleague.type";
import {
  BookingType,
  NotSerializedBookingMode
} from "../../../../features/Booking-Form/typings/booking-inputs";
import { MsTeamsLinkContents } from "../../schedule-event-popover-msTeams-link-Contents.component";
import { ScheduleEventExternalUser } from "../../schedule-event-external-user.component";
import { ScheduleEventLocation } from "../../schedule-event-location.component";
import { ScheduleEventTime } from "../../schedule-event-time.component";
import { ScheduleEventCheckinButton } from "../../schedule-event-checkin-button.component";
import { ScheduleEventUnlockButton } from "../schedule-event-unlock-button.component";
import { ScheduleSelectionConferenceList } from "./ScheduleSelectionConferenceList.partial";
import { UserInformation } from "../../../../features/Login/typings/login.types";
import { BookingScheduleInterface } from "../../../../features/Booking-Form/typings/booking.types";
import { ScheduleContentList } from "./ScheduleContentList.component";
import { ShowMoreContentIcon } from "../../../FacilityManager/Components/Views/SelectFloorPlanView/SelectionPrompt/partial/ShowMoreContainerIcon.component";
import { useRemoteFetchBookingInventoryCharacteristics } from "../../../../hooks/Remote/Booking/useRemoteFetchBookingInventoryCharacteristics";
import { typeIdPerBookingMode } from "../../../BookingForm/functions/form.functions";
import DefaultSpinner from "../../../LoadingSpinner/default-spinner.component";
import { useFeatures } from "../../../FacilityManager/Hooks/useFeatures";
import { showBookingInventoryCharacteristics } from "./hooks/showBookingInventoryCharacteristics.hook.functions";

type P = {
  isMobileTablet: boolean;
  isMobile: boolean;
  isEditable: boolean;
  schedule: BookingScheduleInterface;
  userInformation: UserInformation;
  bookingUser?: Colleague;
  allSchedules?: BookingScheduleInterface[];
  calendarData: MbscCalendarEvent[];
  handleEditSubject: (
    val: string,
    schedule: BookingScheduleInterface
  ) => Promise<string | number | void>;
  onScheduleChange?: (schedule: BookingScheduleInterface) => void;
  onClickUnlock?: () => void;
  refetchAllSchedule?: () => void;
  setCalendarData?: Dispatch<SetStateAction<MbscCalendarEvent[]>>;
};

export const ScheduleSelectionBookingTab: React.FC<P> = ({
  isMobileTablet,
  isMobile,
  isEditable,
  schedule,
  userInformation,
  bookingUser,
  allSchedules,
  calendarData,
  handleEditSubject,
  onScheduleChange,
  onClickUnlock,
  refetchAllSchedule,
  setCalendarData
}) => {
  const { t } = useTranslation();
  const { useShowEquipmentInSlideOutPanel } = useFeatures();

  const { data: bookingInventoryCharacteristics, isFetching: isBICharacteristicsFetching } =
    useRemoteFetchBookingInventoryCharacteristics(
      typeIdPerBookingMode(schedule.bookingType as NotSerializedBookingMode)[0],
      {
        inventoryIds: [schedule.bookingInventoryId],
        start: schedule.start ?? schedule.startDate,
        end: schedule.end ?? schedule.endDate
      },
      true
    );

  const [showMoreEquipment, setShowMoreEquipment] = useState(false);
  const [showMoreEquipCategory, setShowMoreEquipCategory] = useState(false);
  const [showMoreBookProp, setShowMoreBookProp] = useState(false);
  const [showMoreCategory, setShowMoreCategory] = useState(false);

  const contentChildItemStyle = (showMore: boolean) => {
    return { flexWrap: "nowrap", overflowY: "auto", maxHeight: showMore ? "180px" : "unset" };
  };

  const locDateTimeGridMbStyle = isMobileTablet ? 1 : 1.5;
  const rowGapStyle = isMobileTablet ? 2 : 5;

  const { isShowCategory, isShowEquipCategories, isShowEquipments, isShowBookProps } =
    showBookingInventoryCharacteristics(
      bookingInventoryCharacteristics,
      isBICharacteristicsFetching,
      useShowEquipmentInSlideOutPanel
    );

  return (
    <>
      <Grid
        container
        data-testid="booking-selection-tabPanel-0-booking"
        rowGap={rowGapStyle}
        sx={{ width: isMobileTablet ? "fit-content" : "auto", mt: isMobileTablet ? 0 : 2 }}
      >
        <Grid item container data-testid="booking-selection-tabPanel-0-booking-child-parent">
          <Grid item xs={12} sm={8} data-testid="booking-selection-tabPanel-0-booking-child1">
            <Grid item container>
              {/* display schedule booking type icon and type name */}
              <ScheduleEventBookingTypeContents
                schedule={schedule}
                userId={userInformation.sub}
                bookingUser={bookingUser}
              />

              <Grid item container flexDirection={"column"} alignItems={"flex-start"}>
                {/* display schedule show place/zone button */}
                {schedule.locationName !== "Mobile Working" && (
                  <Button
                    sx={{ mb: 1 }}
                    variant="text"
                    data-testid="schedule-item-show-btn"
                    onClick={() => onScheduleChange?.(schedule)}
                  >
                    {schedule.bookingType === BookingType.CONFERENCEZONE
                      ? t("Show Zone")
                      : t("Show place")}
                  </Button>
                )}

                {/* display ms teams meeting link button */}
                {schedule.bookingType === BookingType.CONFERENCEZONE && (
                  <MsTeamsLinkContents isMobile={isMobileTablet} selectedSchedule={schedule} />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4} data-testid="booking-selection-tabPanel-0-booking-child2">
            {/* if external user existed */}
            {(schedule.externalName || schedule.externalEmail) && (
              <ScheduleEventExternalUser schedule={schedule} isMobile={isMobileTablet} />
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          data-testid="booking-selection-tabPanel-0-booking-contents"
          rowGap={rowGapStyle}
        >
          <Grid container item display={isMobile ? "inline" : "flex"}>
            {/* location grid */}
            <Grid item sx={{ mb: 3 }} xs={12}>
              <Typography
                variant={"subtitle1"}
                sx={{ fontWeight: "bold", mb: locDateTimeGridMbStyle }}
              >
                {t("Location")}
              </Typography>

              <ScheduleEventLocation schedule={schedule} />
            </Grid>

            {/* date and time grid */}
            <Grid item sx={{ mb: 3 }} xs={12}>
              <Typography
                variant={"subtitle1"}
                sx={{ fontWeight: "bold", mb: locDateTimeGridMbStyle }}
              >
                {t("Date and Time")}
              </Typography>

              <ScheduleEventTime
                schedule={schedule}
                calendarData={calendarData}
                isEditable={isEditable}
                setCalendarData={setCalendarData}
                refetchAllSchedule={refetchAllSchedule}
              />
            </Grid>
          </Grid>

          {/* unlock and check-in button grid */}
          {isEditable && (
            <Grid
              data-testid="unlock-and-checkin-btn-grid"
              container
              item
              sx={{ mb: 2 }}
              xs={12}
              columnGap={2}
            >
              <ScheduleEventCheckinButton
                selectedSchedule={schedule}
                allSchedules={allSchedules}
                refetchAllSchedule={refetchAllSchedule}
              />

              <ScheduleEventUnlockButton
                doorLockData={schedule.doorLockData}
                onClickUnlock={onClickUnlock}
              />
            </Grid>
          )}

          {isBICharacteristicsFetching && (
            <DefaultSpinner style={{ padding: 0 }} size={20} text={t("Loading...")} />
          )}

          {/* when conferencezon booking, display subject, description, and zone category */}
          {schedule.bookingType === BookingType.CONFERENCEZONE && (
            <ScheduleSelectionConferenceList
              userInformation={userInformation}
              schedule={schedule}
              isEditable={isEditable}
              bookingInventoryCharacteristics={bookingInventoryCharacteristics}
              handleEditSubject={handleEditSubject}
              showMoreContent={showMoreCategory}
              setShowMoreContent={setShowMoreCategory}
              contentChildrenStyle={contentChildItemStyle(showMoreCategory)}
              isShowCategory={isShowCategory ?? false}
            />
          )}

          {/* place category */}
          {schedule.bookingType !== BookingType.CONFERENCEZONE && isShowCategory && (
            <ScheduleContentList
              contentIcon={
                <ShowMoreContentIcon
                  type={"Category"}
                  showMoreContent={showMoreCategory}
                  setShowMoreContent={setShowMoreCategory}
                />
              }
              contentTitle={t("Place Category")}
              contents={[]}
              contentChildren={
                <Grid
                  container
                  rowGap={1}
                  flexDirection={"column"}
                  sx={{ ...contentChildItemStyle(showMoreCategory) }}
                >
                  {bookingInventoryCharacteristics?.assignedCategories.map((cat, index) => {
                    const length = bookingInventoryCharacteristics?.assignedCategories.length;
                    return (
                      <div key={cat.id}>
                        <Grid item pb={1}>
                          {cat.name}
                        </Grid>
                        {showMoreCategory && (
                          <>
                            <Grid item pl={2} pb={1}>
                              {cat.description}
                            </Grid>
                            {index !== length - 1 && <Divider />}
                          </>
                        )}
                      </div>
                    );
                  })}
                </Grid>
              }
            />
          )}

          {/* equipment categories list */}
          {isShowEquipCategories && (
            <ScheduleContentList
              contentIcon={
                <ShowMoreContentIcon
                  type={"Equipment Categories"}
                  showMoreContent={showMoreEquipCategory}
                  setShowMoreContent={setShowMoreEquipCategory}
                />
              }
              contentTitle={t("Equipment Categories")}
              contents={[]}
              contentChildren={
                <Grid
                  container
                  rowGap={1}
                  flexDirection={"column"}
                  sx={{ ...contentChildItemStyle(showMoreEquipCategory) }}
                >
                  {bookingInventoryCharacteristics?.totalEquipmentCategories.map(
                    (equipCate, index) => {
                      const length =
                        bookingInventoryCharacteristics.totalEquipmentCategories?.length ?? 0;

                      return (
                        <div key={equipCate.category.id}>
                          <Grid item container pb={1}>
                            <Grid item xs={9}>
                              {equipCate.category.name}
                            </Grid>
                            {showMoreEquipCategory && (
                              <Grid item xs={2} display={"flex"} sx={{ placeContent: "end" }}>
                                {t("Qty")}
                                <Typography sx={{ pl: 1 }}>{equipCate.quantity}</Typography>
                              </Grid>
                            )}
                          </Grid>
                          {showMoreEquipCategory && (
                            <>
                              <Grid item pl={2} pb={1}>
                                {equipCate.category.description}
                              </Grid>
                              {index !== length - 1 && <Divider />}
                            </>
                          )}
                        </div>
                      );
                    }
                  )}
                </Grid>
              }
            />
          )}

          {/* equipments list */}
          {isShowEquipments && (
            <ScheduleContentList
              contentIcon={
                <ShowMoreContentIcon
                  type={"Equipment"}
                  showMoreContent={showMoreEquipment}
                  setShowMoreContent={setShowMoreEquipment}
                />
              }
              contentTitle={t("Equipment")}
              contents={[]}
              contentChildren={
                <Grid
                  container
                  rowGap={1}
                  flexDirection={"column"}
                  sx={{ ...contentChildItemStyle(showMoreEquipment) }}
                >
                  {bookingInventoryCharacteristics?.assignedEquipments.map((equip, index) => {
                    const length = bookingInventoryCharacteristics?.assignedEquipments.length;
                    return (
                      <div key={equip.id}>
                        <Grid item pb={1}>
                          {equip.equipment.name}
                        </Grid>
                        {showMoreEquipment && (
                          <>
                            <Grid item pl={2} pb={1}>
                              {equip.equipment.description}
                            </Grid>
                            {index !== length - 1 && <Divider />}
                          </>
                        )}
                      </div>
                    );
                  })}
                </Grid>
              }
            />
          )}

          {/* booking properties list */}
          {isShowBookProps && (
            <ScheduleContentList
              contentIcon={
                <ShowMoreContentIcon
                  type={"Properties"}
                  showMoreContent={showMoreBookProp}
                  setShowMoreContent={setShowMoreBookProp}
                />
              }
              contentTitle={t("Properties")}
              contents={[]}
              contentChildren={
                <Grid
                  container
                  rowGap={1}
                  flexDirection={"column"}
                  sx={{ ...contentChildItemStyle(showMoreBookProp) }}
                >
                  {bookingInventoryCharacteristics?.assignedProperties.map((prop, index) => {
                    const length = bookingInventoryCharacteristics?.assignedProperties?.length ?? 0;
                    return (
                      <div key={prop.id}>
                        <Grid item pb={1}>
                          {prop.name}
                        </Grid>
                        {showMoreBookProp && (
                          <>
                            <Grid item pl={2} pb={1}>
                              {prop.description}
                            </Grid>
                            {index !== length - 1 && <Divider />}
                          </>
                        )}
                      </div>
                    );
                  })}
                </Grid>
              }
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
