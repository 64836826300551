import React, { useEffect, useState } from "react";
import { Autocomplete, Chip, FormHelperText, LinearProgress, TextField } from "@mui/material";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

/** Any object that has a string or number id and a name */
type ObjectWithID<T = number | string> = { id: T; name: string; [key: string]: unknown };

type P = {
  values: (number | string)[];
  options: ObjectWithID[];
  changeAction: (value: ObjectWithID[]) => void;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  isLoading?: boolean;
};

/**
 * Combo that selects multiple values.
 * @param {P} props
 */
const EntityMultiCombo: React.FC<P> = ({
  values,
  options,
  changeAction,
  placeholder,
  label = i18n.t("Selection"),
  disabled = false,
  isLoading = false
}) => {
  const [tValues, setTValues] = useState<ObjectWithID[]>([]); // synced with values
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<{}>, value: ObjectWithID<React.ReactText>[]) =>
    changeAction(value);

  useEffect(() => {
    const result = options.filter(o =>
      isNaN(Number(o.id)) ? values.includes(o.id) : values.includes(Number(o.id))
    );
    setTValues(result);
  }, [values, options]);

  return (
    <>
      <Autocomplete
        multiple
        options={options}
        getOptionLabel={ten => ten.name}
        data-testid={"autocomplete"}
        value={tValues}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label={label}
            placeholder={placeholder}
            className="autocompletefield"
            error={values.length < 1}
          />
        )}
        disableClearable
        disableCloseOnSelect
        style={{ width: "100%" }}
        disabled={disabled}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const { key, ...rest } = getTagProps({ index });
            return <Chip {...getTagProps({ index })} {...rest} label={option.name} key={key} />;
          })
        }
      />
      {isLoading && <LinearProgress />}
      <FormHelperText error={values.length < 1}>{t("At least one is required")}</FormHelperText>
    </>
  );
};

export default EntityMultiCombo;
