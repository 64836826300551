import { Group, PinDrop } from "@mui/icons-material";
import { Box, Button, CardContent, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { LocationAvailability } from "../../../features/Booking-Form/typings/booking-location";
import { ConnectedBookingData } from "../../../features/Booking-Form/typings/connected-bookingData";

type P = {
  location: LocationAvailability;
  spotsAvailable: boolean | undefined;
  isAutomatedSeatingAvailable: boolean | undefined;
  uniqConnectedBookingData: ConnectedBookingData[];
  locationIcons: JSX.Element;
  handleAutomatedSeatingClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  setIsColleagueDataOpen: (o: boolean) => void;
};

export const LocationCardContent: React.FC<P> = forwardRef<HTMLDivElement, P>(
  (
    {
      location,
      spotsAvailable,
      isAutomatedSeatingAvailable,
      uniqConnectedBookingData,
      locationIcons,
      handleAutomatedSeatingClick,
      setIsColleagueDataOpen
    },
    ref
  ) => {
    const { t } = useTranslation();

    const { numberOfBookableObjects, name, address1, address2, postalCode, city } = location;

    return (
      <Box
        sx={{ display: "flex", flexDirection: "column", maxWidth: 345, width: "100%" }}
        ref={ref}
      >
        <CardContent sx={{ flex: "0 0 auto" }}>
          <Grid container>
            <Grid item xs={10}>
              <Typography component="h5" variant="h5" sx={{ overflowWrap: "anywhere" }}>
                {name === "Mobile Working" ? t("Mobile Working") : name}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              {/* select automatic seating */}
              {isAutomatedSeatingAvailable && (
                <Tooltip title={t("_freeWorkplaceChosen")}>
                  <IconButton
                    data-testid="automated-seating-loc-btn"
                    onClick={handleAutomatedSeatingClick}
                  >
                    <PinDrop />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>

          {name !== "Mobile Working" && (
            <div style={{ marginTop: 5 }}>
              {city}, {postalCode}
              <Typography variant="subtitle1" color="textSecondary">
                {address1} {address2}
              </Typography>
            </div>
          )}
        </CardContent>

        <Grid container sx={{ alignItems: "center", paddingLeft: 2, paddingBottom: 1 }}>
          <Grid item xs={8}>
            {spotsAvailable ? (
              <Typography sx={{ color: "#43a047" }} variant="h6" color="textSecondary">
                {t("_available")} {numberOfBookableObjects}
              </Typography>
            ) : (
              locationIcons
            )}
          </Grid>
          <Grid item xs={4}>
            {name !== "Mobile Working" && uniqConnectedBookingData && (
              <Button
                variant={"outlined"}
                sx={{ p: "4px 12px" }}
                disabled={!uniqConnectedBookingData.length}
                data-testid="show-connected-bookingData-btn"
                onClick={e => {
                  e.stopPropagation();
                  setIsColleagueDataOpen(true);
                }}
              >
                <Group />
                <Typography sx={{ pl: 0.5 }}>{uniqConnectedBookingData.length}</Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }
);
