import React, { useEffect } from "react";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { MbscCalendarEvent } from "@mobiscroll/react";
import { useTranslation } from "react-i18next";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";
import { useRemoteInactivateBookings } from "../../hooks/Remote/Booking/useRemoteInactivateBookings";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";
import { BookingRequestsStatus } from "../BookingRequest/typings/booking-requests.types";

type P = {
  schedule: BookingScheduleInterface;
  calendarData: MbscCalendarEvent[];
  isEditable: boolean;
  onClose: () => void;
  onCalendarDataChange?: (events: MbscCalendarEvent[]) => void;
  setDeletingBookings?: (d: boolean) => void;
  handleRejectBookings?: () => void;
  handleApproveBookings?: () => void;
};

export const ScheduleEventDeleteButton: React.FC<P> = ({
  schedule,
  calendarData,
  isEditable,
  onClose,
  onCalendarDataChange,
  setDeletingBookings,
  handleRejectBookings,
  handleApproveBookings
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { mutate: inactivateBookings, status: inactivateBookingsStatus } =
    useRemoteInactivateBookings();

  const handleInactiveBookings = () => {
    setDeletingBookings?.(true);
    inactivateBookings({
      bookings: [
        {
          deleted: false,
          bookingIds: [schedule.id],
          bookingType: schedule.bookingType as BookingType
        }
      ]
    });
  };

  const handleInactivateSuccess = () => {
    const index = calendarData.findIndex(c => c.id === schedule.id);
    if (index >= 0) {
      const newCalendarData = calendarData.slice();
      newCalendarData.splice(index, 1);
      onCalendarDataChange?.(newCalendarData);
      onClose();
    }
    setDeletingBookings?.(false);
  };

  useEffect(() => {
    if (inactivateBookingsStatus === "success") handleInactivateSuccess();
  }, [inactivateBookingsStatus]);

  return (
    <>
      {isEditable && (
        <Button
          color={"error"}
          sx={{ padding: "7px 10px 7px 10px" }}
          onClick={handleInactiveBookings}
          data-testid="schedule-item-delete-btn"
        >
          {isMobile ? t("Cancel Booking_short") : t("Cancel Booking")}
        </Button>
      )}
      {!isEditable && schedule.status !== BookingRequestsStatus.REJECTED && (
        <>
          <Button
            color={"error"}
            sx={{ padding: "7px 10px 7px 10px" }}
            onClick={handleRejectBookings}
            data-testid="schedule-item-reject-btn"
          >
            {t("Reject")}
          </Button>
          <Button
            color={"info"}
            sx={{ padding: "7px 10px 7px 10px" }}
            disabled={schedule.status === BookingRequestsStatus.APPROVED}
            onClick={handleApproveBookings}
            data-testid="schedule-item-approve-btn"
          >
            {t("Approve")}
          </Button>
        </>
      )}
    </>
  );
};
