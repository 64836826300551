import { Card, Divider, Grid, IconButton, styled, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Add, Check, Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { DeleteTimeframeOptions } from "../../../thunks/deleteTimeframe";
import useTimeFormat from "../../../../../hooks/useTimeFormat/useTimeFormat";
import { FloorInventory } from "../../../typings/floor-inventory.entity";
import { Timeframe } from "../../../typings/shared/timeframe";

export const StyledTimeframeDetailCard = styled(Card, {
  name: "StyledTimeframeDetailCard"
})(() => ({
  width: 420,
  height: 185,
  padding: "16px",
  cursor: "pointer"
}));

/** detail item of timeframe list */
export function DetailTimeframeListItem({
  isDetailOpen,
  timeframe,
  startDate,
  endDate,
  selectedFloor,
  isEditService,
  isEditTimeframe,
  isActive,
  isLast,
  ableToEditServices,
  editServices,
  clickEdit,
  setDeleteModal
}: {
  isDetailOpen: boolean;
  timeframe: Timeframe;
  startDate: string;
  endDate: string | null;
  selectedFloor: FloorInventory;
  isEditService: boolean;
  isEditTimeframe: boolean;
  isActive: boolean;
  isLast: boolean;
  ableToEditServices: boolean;
  editServices: (timeframe: Timeframe) => void;
  clickEdit: (timeframe: Timeframe) => Promise<void>;
  setShowTimeframeDetail: (detail: string) => void;
  setDeleteModal: (modal: {
    isOpen: boolean;
    initialValues: DeleteTimeframeOptions;
    isLast?: boolean;
  }) => void;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { dateFormat } = useTimeFormat();

  /** check the usage of each service items */
  const usedBookEquip = false;
  const usedTableArr = false;

  const loadingBtnText = theme.palette.mode === "dark" ? theme.palette.text.primary : null;

  const handleDeleteTimeframe = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setDeleteModal({
      isOpen: true,
      initialValues: {
        start: timeframe.start,
        end: timeframe.end,
        fillGapStrategy: "next",
        targetFloorInventoryId: selectedFloor.id
      },
      isLast: isLast
    });
  };

  const handleEditPlan = () => {
    // before loading the time frame, refetch the floor data with the current start day
    clickEdit({
      start: DateTime.fromISO(timeframe.start).toFormat("yyyy-MM-dd"),
      end: timeframe.end ? DateTime.fromISO(timeframe.end).toFormat("yyyy-MM-dd") : null
    });
  };

  const handleEditServicePlan = () => {
    editServices({
      start: DateTime.fromISO(timeframe.start).toFormat("yyyy-MM-dd"),
      end: null
    });
  };

  return (
    <>
      {isDetailOpen && (
        <Grid
          container
          data-testid="detail-timeframe-parent-grid"
          sx={{
            width: ableToEditServices ? 852 : 422,
            p: 0.5,
            height: 192,
            background: theme.palette.primary.main, // theme.palette.mode === "dark" ? theme.palette.grey[700] : theme.palette.grey[300],
            borderRadius: 1,
            my: 1,
            justifyContent: "space-between",
            alignContent: "center"
          }}
        >
          {/* floor section */}
          <StyledTimeframeDetailCard onClick={handleEditPlan}>
            <Grid container sx={{ height: "100%" }} data-testid="detail-timeframe-floor-container">
              {/* header of the selected floor */}
              <Grid item container sx={{ height: "80%", justifyContent: "space-between" }}>
                <Grid item>
                  <Typography variant={"h6"}>{selectedFloor.name}</Typography>
                  <Typography variant={"subtitle1"}>{selectedFloor.level}</Typography>
                </Grid>

                {/* edit button */}
                <Grid item>
                  <LoadingButton
                    variant={"text"}
                    data-testid={"timeline-timeframe-select-button"}
                    loading={isEditTimeframe}
                    onClick={handleEditPlan}
                    sx={{ color: loadingBtnText }}
                  >
                    {isActive ? t("Show") : t("Edit")}
                  </LoadingButton>
                </Grid>
              </Grid>

              <Divider
                flexItem
                sx={{
                  width: 1,
                  height: "1px",
                  backgroundColor: theme.palette.text.primary,
                  mb: 0.3
                }}
              />

              <Grid
                item
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <Grid item display={"flex"}>
                  <Typography fontWeight={"bold"}>{t("Version")}</Typography>
                  <Typography sx={{ ml: 1, mr: 1 }}>{t("_versionFrom")}</Typography>
                  <Typography>{startDate}</Typography>
                  {endDate && (
                    <>
                      <Typography sx={{ px: 1 }}>-</Typography>
                      <Typography>{DateTime.fromISO(endDate).toFormat(dateFormat)}</Typography>
                    </>
                  )}
                </Grid>

                {!isActive && (
                  <IconButton
                    data-testid={"timeline-timeframe-delete-button"}
                    onClick={handleDeleteTimeframe}
                  >
                    <Delete />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </StyledTimeframeDetailCard>

          {/* service section */}
          {ableToEditServices && (
            <StyledTimeframeDetailCard onClick={handleEditServicePlan}>
              <Grid container sx={{ height: "100%" }}>
                {/* header of the selected floor */}
                <Grid
                  item
                  container
                  sx={{ height: "30%", justifyContent: "space-between" }}
                  data-testid="service-section-header"
                >
                  <Grid item>
                    <Typography variant={"h6"}>{t("Services")}</Typography>
                  </Grid>

                  {/* edit button */}
                  <Grid item>
                    <LoadingButton
                      loading={isEditService}
                      variant={"text"}
                      data-testid="timeline-timeframe-editService-button"
                      onClick={handleEditServicePlan}
                      sx={{ color: loadingBtnText }}
                    >
                      {t("Assign")}
                    </LoadingButton>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  sx={{ width: "100%", flexDirection: "column" }}
                  data-testid="service-section-container"
                  gap={0.5}
                >
                  <Grid item sx={{ display: "flex", alignItems: "center" }}></Grid>
                  <Grid item sx={{ display: "flex", alignItems: "center" }}>
                    {usedBookEquip ? <Check fontSize="small" /> : <Add fontSize="small" />}
                    <Typography sx={{ ml: 0.5 }}>{t("Bookable Equipment")}</Typography>
                  </Grid>

                  <Grid item sx={{ display: "flex", alignItems: "center" }}>
                    {usedTableArr ? <Check fontSize="small" /> : <Add fontSize="small" />}
                    <Typography sx={{ ml: 0.5 }}>{t("TablePlacement")}</Typography>
                  </Grid>

                  <Grid item sx={{ display: "flex", alignItems: "center" }}></Grid>
                </Grid>
              </Grid>
            </StyledTimeframeDetailCard>
          )}
        </Grid>
      )}
    </>
  );
}
