import { FileObject } from "react-mui-dropzone";
import { LocationInventory } from "../typings/location-inventory";
import { handleLocationPicturePath } from "./handleLocationPicturePath";
import { Permission } from "../typings/permission.entity";

/**
 * Make FormData from params
 */
export function serializeLocationInventory(
  {
    uid,
    timezone,
    address1,
    address2,
    address3,
    postalCode,
    city,
    state,
    country,
    allowChangeDeskCost,
    allowForBooking,
    schedule,
    bookingAllowedAt,
    color,
    permissions,
    name,
    maxLevels,
    picturePath,
    isMobileWorking,
    checkinPeriod,
    checkinRadiusInMeters,
    accessWithGeoLocation = false,
    companyId
  }: LocationInventory,
  picture?: FileObject[]
): FormData {
  const data = new FormData();
  data.append("uid", uid);
  data.append("name", name);

  data.append("address1", address1);
  data.append("country", country);
  data.append("timezone", timezone);

  updateOtherAddressData({ data, address2, address3, postalCode, city, state });

  updatePerm({ data, permissions });
  updateOptionalProps({
    data,
    allowChangeDeskCost,
    allowForBooking,
    isMobileWorking,
    bookingAllowedAt
  });

  data.append("maxLevels", String(maxLevels));
  data.append("checkinPeriod", String(checkinPeriod));
  data.append("checkinRadiusInMeters", String(checkinRadiusInMeters));
  data.append("color", color);

  updatePicturePathOrFile({ data, picture, picturePath });

  // when creating plain companyId is existed but when updating not using plain companyId
  if (companyId) data.append("companyId", String(companyId));

  // regards of cost allocation
  if (schedule) {
    const latestIndex = schedule.findIndex(schedule => schedule.end === null);
    const {
      description,
      budget,
      defaultDeskCost,
      incentiveFactor,
      periodLength,
      vacancyFactor,
      workingHoursPerMonth,
      start,
      end
    } = schedule[latestIndex];

    data.append("schedule", JSON.stringify(schedule[latestIndex]));
    data.append("description", description);
    data.append("budget", String(budget ?? 1));
    data.append("defaultDeskCost", String(defaultDeskCost));
    data.append("incentiveFactor", String(incentiveFactor));
    data.append("periodLength", String(periodLength));
    data.append("vacancyFactor", String(vacancyFactor));
    data.append("workingHoursPerMonth", String(workingHoursPerMonth));
    data.append("start", String(start));
    data.append("end", String(end));
  }
  data.append("accessWithGeoLocation", String(accessWithGeoLocation));

  return data;
}

type OptionalAddress = string | null | undefined;
const updateOtherAddressData = ({
  data,
  address2,
  address3,
  postalCode,
  city,
  state
}: {
  data: FormData;
  address2: OptionalAddress;
  address3: OptionalAddress;
  postalCode: OptionalAddress;
  city: string | null;
  state: OptionalAddress;
}) => {
  if (address2) data.append("address2", address2);
  if (address3) data.append("address3", address3);
  if (postalCode) data.append("postalCode", postalCode);
  if (city) data.append("city", city);
  if (state) data.append("state", state);
};

const updatePerm = ({
  data,
  permissions
}: {
  data: FormData;
  permissions: Permission[] | undefined;
}) => {
  permissions &&
    permissions.length !== 0 &&
    data.append("permissions", JSON.stringify(permissions));
};

const updateOptionalProps = ({
  data,
  allowChangeDeskCost,
  allowForBooking,
  isMobileWorking,
  bookingAllowedAt
}: {
  data: FormData;
  allowChangeDeskCost: boolean;
  allowForBooking: boolean;
  isMobileWorking: boolean;
  bookingAllowedAt: string | null;
}) => {
  data.append("allowChangeDeskCost", allowChangeDeskCost ? "true" : "");
  data.append("allowForBooking", allowForBooking ? "true" : "");
  data.append("isMobileWorking", isMobileWorking ? "true" : "");

  if (bookingAllowedAt) data.append("bookingAllowedAt", bookingAllowedAt);
};

const updatePicturePathOrFile = ({
  data,
  picture,
  picturePath
}: {
  data: FormData;
  picture: FileObject[] | undefined;
  picturePath: string | null;
}) => {
  // handle location picture path whether the file is newly uploaded, remained, or deleted
  const returnedPicturePath = handleLocationPicturePath(picturePath);
  if (!picture) data.append("picturePath", returnedPicturePath);
  if (picture) data.append("file", picture[0].file);
};
