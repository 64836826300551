import { Autocomplete, Chip, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { setFilterSelected } from "../slices/report.slice";

const PlaceCategoryFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const placeCategoriesOptions = useSelector(
    (state: RootState) => state.report.filterOptions.placeCategories
  );
  const placeCategoriesSelected = useSelector(
    (state: RootState) => state.report.filterSelected.placeCategories
  );

  return (
    <Autocomplete
      disabled={!placeCategoriesOptions}
      multiple
      disableCloseOnSelect
      id="place-category-filter"
      options={placeCategoriesOptions ? placeCategoriesOptions : []}
      value={placeCategoriesSelected ? placeCategoriesSelected : []}
      onChange={(event, newValue) => {
        dispatch(
          setFilterSelected({
            placeCategories: newValue
          })
        );
      }}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={params => (
        <TextField
          data-testid={"place-category-filter"}
          {...params}
          variant="standard"
          label={t("Place Categories")}
        />
      )}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip {...getTagProps({ index })} label={option.name} key={option.id} />
        ));
      }}
    />
  );
};

export default PlaceCategoryFilter;
