import { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator
} from "@mui/lab";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useSelector } from "../../../../app/helpers";
import { RootState } from "../../../../app/rootReducer";
import { Timeframe } from "../../typings/shared/timeframe";
import useTimeFormat from "../../../../hooks/useTimeFormat/useTimeFormat";
import { useRemoteDeleteFloorTimeframe } from "../../../../hooks/Remote/FloorTimeframes/useRemoteDeleteFloorTimeframe";
import { DeleteTimeframeOptions, deleteTimeframeOptionsSchema } from "../../thunks/deleteTimeframe";
import { FloorInventory } from "../../typings/floor-inventory.entity";
import { FormDialog2 as FormDialog } from "../../../../components/FacilityManager/Legacy/formDialog2.component";
import { BasicTimeframeListItem } from "./partial/menu-timeframes-listItem-basic.partial";
import { DetailTimeframeListItem } from "./partial/menu-timeframes-listItem-detail.partial";
import { NewTimeframeItem } from "./partial/menu-timeframes-listItem-new.partial";
import { sortTimeframes } from "../menu-timeframes.functions";

type Props = {
  timeframes: Timeframe[];
  selectedFloor: FloorInventory;
  isEditService: boolean;
  isEditTimeframe: boolean;
  startDate: DateTime | null;
  newTimeframe: boolean;
  setStartDate: React.Dispatch<React.SetStateAction<DateTime | null>>;
  clickNextCreate: (startDay: string) => void;
  clickEdit: (timeframe: Timeframe) => Promise<void>;
  refetchFloorTimeframes: () => void;
  editServices: (timeframe: Timeframe) => void;
  setNewTimeframe: (tf: boolean) => void;
};

export function TimeframesList({
  timeframes,
  selectedFloor,
  isEditService,
  isEditTimeframe,
  startDate,
  newTimeframe,
  setStartDate,
  clickNextCreate,
  clickEdit,
  refetchFloorTimeframes,
  editServices,
  setNewTimeframe
}: Props) {
  const {
    userRoles: { isTenantAdmin, isCompanyAdmin, isLocationAdmin }
  } = useSelector((state: RootState) => state.login);
  // check to satisfy the required admin role for edit the floor plan services
  const ableToEditServices = isLocationAdmin && isCompanyAdmin && isTenantAdmin;

  const { t, i18n } = useTranslation();

  const { dateFormat } = useTimeFormat();
  const now = new Date();

  const [deleteParams, setDeleteParams] = useState<{
    start: string;
    end: string | null;
    fillGapStrategy: string;
    targetFloorInventoryId: number;
  }>({
    start: "",
    end: null,
    fillGapStrategy: "",
    targetFloorInventoryId: 0
  });

  const [deleteModalLoading, setDeleteModalLoading] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<
    | {
        isOpen: boolean;
        initialValues: DeleteTimeframeOptions;
        isLast?: boolean;
      }
    | undefined
  >();

  const [showTimeframeDetail, setShowTimeframeDetail] = useState<string | null>(null);

  const { mutate: deleteTimeframe, isLoading: deleteTimeframeIsLoading } =
    useRemoteDeleteFloorTimeframe(deleteParams);

  // sorting newest timeframe always at the top
  const sortedTimeframes = sortTimeframes(timeframes);

  useEffect(() => {
    // Newest version of floorplan is represented as larger tile by default, services tile is placed next to it
    if (timeframes.length > 0) {
      const initialStart = sortedTimeframes[0].start;
      setShowTimeframeDetail(DateTime.fromISO(initialStart).toFormat(dateFormat));
    }
  }, [timeframes, i18n.language]);

  useEffect(() => {
    if (!deleteParams.targetFloorInventoryId) return;

    deleteTimeframe(undefined);
  }, [deleteParams]);

  useEffect(() => {
    if (deleteTimeframeIsLoading) return;

    refetchFloorTimeframes();
    setDeleteModal(undefined);
    setDeleteModalLoading(false);
  }, [deleteTimeframeIsLoading]);

  return (
    <>
      <Grid
        sx={{ width: "100%", flexDirection: "column" }}
        container
        data-testid="menu-timeframe-list-parent-box"
      >
        <Grid item container sx={{ pt: 1, pb: 1, pr: 1, ml: 1, flexDirection: "column", gap: 1 }}>
          {/* create version button */}
          {!newTimeframe && (
            <Button
              data-testid="new-btn"
              sx={{ width: "fit-content", height: "40px" }}
              onClick={() => {
                setNewTimeframe(true);
                setShowTimeframeDetail(null);
              }}
            >
              <Add sx={{ mr: 1 }} />
              {t("Create New Version")}
            </Button>
          )}
        </Grid>

        {/* list of timeframe */}
        <Grid item sx={{ pt: 1, pb: 3 }}>
          <Timeline
            position="right"
            sx={{ padding: 0, [`& .${timelineItemClasses.root}:before`]: { flex: 0, padding: 0 } }}
          >
            {/* new time frame creator */}
            {newTimeframe && (
              <NewTimeframeItem
                selectedFloor={selectedFloor}
                floorTimeframes={timeframes}
                startDate={startDate}
                setStartDate={setStartDate}
                setNewTimeframe={setNewTimeframe}
                clickNextCreate={clickNextCreate}
                setShowTimeframeDetail={setShowTimeframeDetail}
              />
            )}

            {/* timeframes list */}
            {sortedTimeframes.map((timeframe, tfIndex) => {
              const { start, end } = timeframe;

              /** start time equals now or is in the past and end time is in the future or unlimited) */
              const isActive = !!start && new Date(start) < now && (!end || new Date(end) > now);
              const isLast = tfIndex === timeframes.length - 1;

              const startDate = DateTime.fromISO(start).toFormat(dateFormat);
              const isDetailOpen = showTimeframeDetail === startDate;

              return (
                <TimelineItem key={timeframe.start} data-testid="time-line-item">
                  <TimelineSeparator>
                    {tfIndex !== 0 && <TimelineConnector />}
                    <BasicTimeframeListItem
                      isDetailOpen={isDetailOpen}
                      startDate={startDate} // string
                      endDate={end} // iso date
                      setShowTimeframeDetail={setShowTimeframeDetail}
                      isActive={isActive}
                    />

                    <DetailTimeframeListItem
                      isDetailOpen={isDetailOpen}
                      timeframe={timeframe}
                      startDate={startDate} // string
                      endDate={end} // iso date
                      selectedFloor={selectedFloor}
                      isEditService={isEditService}
                      isEditTimeframe={isEditTimeframe}
                      isActive={isActive}
                      isLast={isLast}
                      ableToEditServices={ableToEditServices}
                      clickEdit={clickEdit}
                      editServices={editServices}
                      setDeleteModal={setDeleteModal}
                      setShowTimeframeDetail={setShowTimeframeDetail}
                    />
                    <TimelineConnector />
                  </TimelineSeparator>
                </TimelineItem>
              );
            })}
          </Timeline>
        </Grid>
      </Grid>

      {/* timeframe delete modal */}
      {deleteModal && (
        <FormDialog
          isOpen={deleteModal.isOpen}
          isLoading={deleteModalLoading}
          handleOk={params => {
            params.start = DateTime.fromISO(params.start).toFormat("yyyy-MM-dd");

            if (params.end) {
              params.end = DateTime.fromISO(params.end).toFormat("yyyy-MM-dd");
            }
            setDeleteModalLoading(true);
            setDeleteParams(params);
          }}
          title={t("Delete version?")}
          description={t("_deleteVersionWarning")}
          schema={deleteTimeframeOptionsSchema}
          initialValues={deleteModal.initialValues}
          handleCancel={() => setDeleteModal(undefined)}
        />
      )}
    </>
  );
}
