import React, { forwardRef, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import {
  LocationInputs,
  bookingInputsLocationSchema
} from "../../features/Booking-Form/typings/inputs-location";
import LocationsView from "../LocationsView/locations-view.component";

type P = {
  nextStep: (to?: number) => void;
  locationToState: (inputs: LocationInputs) => void;
};

/**
 * @description Component to display possible booking locations including mobile working and disabled or full locations.
 * @version 0.1.1
 */
export const FormLocation: React.FC<P> = forwardRef<HTMLDivElement, P>((props, ref) => {
  const { t } = useTranslation();
  const { inputs } = useSelector((state: RootState) => state.booking);

  const [sel, setSel] = useState<LocationInputs>({
    selectedLocation: inputs.selectedLocation ?? null,
    locationName: inputs.locationName ?? "",
    isMobileWorking: inputs.isMobileWorking || false,
    timezone: inputs.timezone || "",
    automatedSeating: inputs.automatedSeating || false,
    automatedSeatingObjects: inputs.automatedSeatingObjects
  });

  const inputLocationDiffers = useCallback(
    (payload: LocationInputs) => {
      if (inputs.selectedLocation !== payload.selectedLocation) props.locationToState(payload);

      // if automatedSeating or isMobileWorking, go to 4th-step(confirm) directly
      if (payload.automatedSeating || payload.isMobileWorking) return props.nextStep(4);

      props.nextStep();
    },
    [inputs.selectedLocation, props]
  );

  const locationNextStepValidate = useCallback(
    (payload: LocationInputs = sel) => {
      const location = bookingInputsLocationSchema.try(payload);

      if (location instanceof Error) {
        alert(t("There has been a serious problem "));
      } else {
        inputLocationDiffers(payload);
      }
    },
    [inputLocationDiffers, t]
  );

  const selectLocation = (inputsLocation: LocationInputs) => {
    setSel(inputsLocation);
    locationNextStepValidate(inputsLocation);
  };

  return <LocationsView ref={ref} locationToState={selectLocation} />;
});

export default FormLocation;
