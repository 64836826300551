import { Box } from "@mui/material";
import { useDispatch, useSelector } from "../../app/helpers";
import {
  SelectedZoneOrWorkplaceType,
  setIsSelected,
  setSelectedBookingType,
  setSelectedZoneOrWorkplace
} from "../../features/Reports/slices/report.slice";
import { fetchReport } from "../../features/Reports/thunks/report.thunk";
import DefaultSpinner from "../LoadingSpinner/default-spinner.component";
import { RootState } from "../../app/rootReducer";
import { IFloorPayload } from "../FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { CapacityRate } from "../FacilityManager/Domain/Types/CapacityRates.type";
import { ReportFloorPlanView } from "../FacilityManager/Components/Views/ReportFloorPlanView/ReportFloorPlanView";
import { useBackgroundImage } from "../FacilityManager/Hooks/useBackgroundImage";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";
import {
  CurrentFloorInfo,
  calDailyValue,
  calFloorBookingObj,
  generateAverageUser
} from "./functions/svg-dialog.functions";
import { IPlaceSchedule } from "../FacilityManager/Domain/Types/FloorPlan/PlaceSchedule";
import { findMostPlaceType } from "../FacilityManager/Components/Views/ReportFloorPlanView/ReportFloorPlanView.functions";

type P = {
  /** Contains information about floor plan, such as desks, zones, etc. */
  floorData: IFloorPayload;
  /** URL to floor outline image. */
  backgroundUrl?: string;

  /** Map of workplace ID to their utilization Rate. */
  utilizationRates: CapacityRate[];

  zoneBooking: boolean;

  /** Custom height prop */
  height?: number;

  /** Custom name prop */
  name?: string;

  currentFloorInfo?: CurrentFloorInfo;

  /** when selecting full mode of the selected floor, floor plan view will be enlarged */
  isFloorFullMode: { index: number | null; open: boolean };
  setIsFloorFullMode: (mode: { index: number | null; open: boolean }) => void;
};

/**
 * Renders a floor plan report.
 */
const SvgReport: React.FC<P> = ({
  floorData,
  utilizationRates,
  height,
  name = "floor",
  zoneBooking,
  currentFloorInfo,
  isFloorFullMode,
  setIsFloorFullMode
}) => {
  const {
    selectedZoneOrWorkplace,
    selectedTimeframe,
    calculateWithoutCosts,
    capacitySelectedWorkplaceOrZone,
    currentFloors,
    zoneRestrictions,
    capacitySelectedFloors,
    isSelected,
    selectedWeekdays
  } = useSelector((state: RootState) => state.report);

  const {
    userInformation: { sub }
  } = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();

  const { background } = useBackgroundImage({
    backgroundImageUrl: floorData.outlineUrl,
    viewport: floorData.viewport
  });

  const currentEntityData = selectedZoneOrWorkplace?.open
    ? [capacitySelectedWorkplaceOrZone]
    : capacitySelectedFloors?.filter(floor => {
        return currentFloors.some(activeFloor => activeFloor.id === floor.id);
      });

  const averageUsers = generateAverageUser(
    currentEntityData,
    selectedZoneOrWorkplace,
    capacitySelectedFloors,
    currentFloorInfo
  );

  const currentFloorCapacity = currentEntityData?.find(d => d?.id === currentFloorInfo?.floor.id);
  const currentFloorCapaUniqueIds =
    currentFloorCapacity?.capacity.uniqueInventoryIds ||
    capacitySelectedWorkplaceOrZone?.capacity.uniqueInventoryIds;

  const { numWorkplaces, numParkings, numElectricchargings, numConferences } = calFloorBookingObj(
    floorData,
    selectedZoneOrWorkplace,
    currentFloorCapaUniqueIds
  );

  const { dev, avg } = calDailyValue(
    currentFloorCapacity,
    selectedZoneOrWorkplace,
    capacitySelectedWorkplaceOrZone,
    currentFloorInfo
  );

  const basicReportParams = {
    cost: calculateWithoutCosts,
    sub: sub,
    zoneBooking: zoneBooking,
    companyId: zoneRestrictions?.company?.id,
    zoneBookingObject: zoneRestrictions?.object?.zoneBookingObject,
    status: true,
    update: false,
    weekdays: selectedWeekdays
  };

  const onPlaceClick = (inventoryIds: number[], names: string[], placeTypes: BookingType[]) => {
    if (selectedTimeframe) {
      dispatch(setIsSelected(true));
      dispatch(setSelectedBookingType(placeTypes[0]));
      dispatch(
        fetchReport({
          ...basicReportParams,
          id: inventoryIds,
          filterType: "workplace",
          bookingType: placeTypes,
          start: selectedTimeframe.timeframeStart,
          end: selectedTimeframe.timeframeEnd
        })
      ).then(() => {
        dispatch(
          setSelectedZoneOrWorkplace({
            id: inventoryIds,
            type: SelectedZoneOrWorkplaceType.WORKPLACE,
            name: names,
            open: true,
            floorInventoryId: floorData.floorInventoryId,
            bookingType: placeTypes[0]
          })
        );
      });
    }
  };

  const onZoneClick = (
    inventoryIds: number[],
    names: string[],
    zoneTypes: BookingType[],
    highlightedPlaces: IPlaceSchedule[]
  ) => {
    if (selectedTimeframe) {
      const mostPlaceType = findMostPlaceType(highlightedPlaces);
      const znType = zoneTypes[0] === BookingType.CONFERENCEZONE ? zoneTypes[0] : mostPlaceType;

      dispatch(setIsSelected(true));
      dispatch(setSelectedBookingType(znType));
      dispatch(
        fetchReport({
          ...basicReportParams,
          id: inventoryIds,
          filterType: "zone",
          bookingType: znType,
          start: selectedTimeframe.timeframeStart,
          end: selectedTimeframe.timeframeEnd
        })
      ).then(() => {
        dispatch(
          setSelectedZoneOrWorkplace({
            id: inventoryIds,
            type: SelectedZoneOrWorkplaceType.ZONE,
            name: names,
            open: true,
            floorInventoryId: floorData.floorInventoryId,
            bookingType: znType
          })
        );
      });
    }
  };

  if (!utilizationRates || !background) return <DefaultSpinner />;
  else
    return (
      <Box sx={{ height }} data-testid="svg-report-box">
        <ReportFloorPlanView
          background={background}
          width={800}
          height={height ?? 600}
          name={name}
          floorPlan={floorData as IFloorPayload}
          seatStatus={{ availableList: [], occupiedList: [], disableList: [], restrictedList: [] }}
          reportData={currentEntityData}
          onClickReportPlace={(inventoryIds, names, placeTypes) =>
            onPlaceClick(inventoryIds, names, placeTypes)
          }
          onClickReportZone={(inventoryIds, names, zoneTypes, highlightedPlaces) =>
            onZoneClick(inventoryIds, names, zoneTypes, highlightedPlaces)
          }
          isSelectedChip={isSelected}
          isFloorFullMode={isFloorFullMode}
          setIsFloorFullMode={setIsFloorFullMode}
          currentFloorInfo={currentFloorInfo}
          reportChipsValue={{
            name,
            averageUsers: averageUsers ?? [],
            numWorkplaces,
            numParkings,
            numElectricchargings,
            numConferences,
            avg,
            dev,
            currentFloorCapaUniqueIds
          }}
        />
      </Box>
    );
};

export default SvgReport;
