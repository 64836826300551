import { SelectedZoneOrWorkplace } from "../../../features/Reports/slices/report.slice";
import { CapacityObject } from "../../../features/Reports/typings/reports.types";
import { IFloorPayload } from "../../FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import * as _ from "lodash";

export type CurrentFloorInfo = {
  floor: { id: number; open: boolean };
  index: number;
};

/**
 * The function returns average user visited.
 * Partly, it needs to check whether zone or workplace is selected
 * and display the related data for each floor.
 * When comparing floors, it uses different data
 */
export function generateAverageUser(
  currentEntityData: (CapacityObject | undefined)[] | undefined,
  selectedZoneOrWorkplace: SelectedZoneOrWorkplace,
  capacitySelectedFloors: CapacityObject[] | undefined,
  currentFloorInfo?: { floor: { id: number; open: boolean }; index: number }
): string[] | undefined {
  const currentEntityDataAvgsUsers =
    currentEntityData && currentEntityData[0]?.capacity.distinctTotalUsers;
  const selectedFloorsAvgUsers = capacitySelectedFloors?.filter(
    floor => floor.id === currentFloorInfo?.floor.id
  )[0]?.capacity.distinctTotalUsers;

  if (
    selectedZoneOrWorkplace?.open &&
    selectedZoneOrWorkplace?.floorInventoryId === currentFloorInfo?.floor.id
  )
    return currentEntityDataAvgsUsers;

  return selectedFloorsAvgUsers;
}

export function calFloorBookingObj(
  floorData: IFloorPayload,
  selectedZoneOrWorkplace: SelectedZoneOrWorkplace,
  currentFloorCapaUniqueIds: number[] | undefined
) {
  let numWorkplaces = 0;
  let numParkings = 0;
  let numElectricchargings = 0;
  let numConferences = 0;

  const { places, zones } = floorData;

  // using currentFloorCapaUniqueIds
  const filteredPlaces = places.filter(p =>
    currentFloorCapaUniqueIds?.includes(p.inventoryId || p.inventory?.id || 0)
  );
  const filteredZones = zones.filter(z =>
    currentFloorCapaUniqueIds?.includes(z.inventoryId || z.inventory?.id || 0)
  );

  const workplaces2 = filteredPlaces.filter(p => p.inventory?.placeTypeId === 1);
  const parkings2 = filteredPlaces.filter(p => p.inventory?.placeTypeId === 2);
  const electricchargings2 = filteredPlaces.filter(p => p.inventory?.placeTypeId === 4);
  const conferences2 = filteredZones.filter(z => z.inventory?.zoneTypeId === 3);

  const workplaces = places.filter(p => {
    const znDisabled = zones.find(z => z.id === p.zoneScheduleId)?.disabled;
    return p.inventory?.placeTypeId === 1 && !p.disabled && !znDisabled;
  });
  const parkings = places.filter(p => {
    const znDisabled = zones.find(z => z.id === p.zoneScheduleId)?.disabled;
    return p.inventory?.placeTypeId === 2 && !p.disabled && !znDisabled;
  });
  const electricchargings = places.filter(p => {
    const znDisabled = zones.find(z => z.id === p.zoneScheduleId)?.disabled;
    return p.inventory?.placeTypeId === 4 && !p.disabled && !znDisabled;
  });
  const conferences = zones.filter(z => z.inventory?.zoneTypeId === 3 && !z.disabled);

  // when selectedZoneOrWorkplace is open
  if (selectedZoneOrWorkplace && selectedZoneOrWorkplace.open) {
    // selectedZoneOrWorkplace type is zone
    if (selectedZoneOrWorkplace.type === "zone") {
      const selectedZones = zones.filter(zn =>
        selectedZoneOrWorkplace.id.includes(zn.inventory?.id || 0)
      );

      numWorkplaces = workplaces.filter(w =>
        selectedZones.some(zn => w.zoneScheduleId === zn.id)
      ).length;

      numParkings = parkings.filter(p =>
        selectedZones.some(zn => p.zoneScheduleId === zn.id)
      ).length;

      numElectricchargings = electricchargings.filter(e =>
        selectedZones.some(zn => e.zoneScheduleId === zn.id)
      ).length;

      numConferences = conferences.filter(c => selectedZones.some(zn => c.id === zn.id)).length;

      return { numWorkplaces, numParkings, numElectricchargings, numConferences };
    } else {
      // selectedZoneOrWorkplace type is place
      const selectedPlaces = places.filter(pl =>
        selectedZoneOrWorkplace.id.includes(pl.inventory?.id || 0)
      );

      numWorkplaces = workplaces.filter(w => selectedPlaces.some(pl => w.id === pl.id)).length;

      numParkings = parkings.filter(p => selectedPlaces.some(pl => p.id === pl.id)).length;

      numElectricchargings = electricchargings.filter(e =>
        selectedPlaces.some(pl => e.id === pl.id)
      ).length;

      return { numWorkplaces, numParkings, numElectricchargings, numConferences };
    }
  }

  // when selectedZoneOrWorkplace not open
  numWorkplaces = workplaces2.length;
  numParkings = parkings2.length;
  numElectricchargings = electricchargings2.length;
  numConferences = conferences2.length;

  return { numWorkplaces, numParkings, numElectricchargings, numConferences };
}

export const calSharingIndex = (
  averageUsers: string[],
  currentFloorCapaUniqueIds: number[] | undefined
) => {
  // fail cases
  // const res1 = 0 / 1; // 0
  // const res2 = 1 / 0; // Infinity
  // const res3 = 0 / 0; // NaN
  if (
    !currentFloorCapaUniqueIds ||
    currentFloorCapaUniqueIds.length === 0 ||
    averageUsers.length === 0
  ) {
    return "0";
  }

  const uniqueIds = _.uniqBy(currentFloorCapaUniqueIds, u => u);
  return (averageUsers.length / uniqueIds.length).toFixed(0);
};

export function calDailyValue(
  currentFloorCapacity: CapacityObject | undefined,
  selectedZoneOrWorkplace: SelectedZoneOrWorkplace,
  capacitySelectedWorkplaceOrZone: CapacityObject | undefined,
  currentFloorInfo: CurrentFloorInfo | undefined
) {
  const currentAvg = currentFloorCapacity?.capacity.averageCapacityPerformancePerDay;
  const selectedAvg = capacitySelectedWorkplaceOrZone?.capacity.averageCapacityPerformancePerDay;

  const currentDevi = currentFloorCapacity?.capacity.dayStandardDeviation;
  const selectedDevi = capacitySelectedWorkplaceOrZone?.capacity.dayStandardDeviation;

  if (
    selectedZoneOrWorkplace &&
    selectedZoneOrWorkplace.open &&
    selectedZoneOrWorkplace.floorInventoryId === currentFloorInfo?.floor.id
  ) {
    const dev = `${((selectedDevi ?? 0) * 100).toFixed(0)} %`;
    const avg = `${((selectedAvg ?? 0) * 100).toFixed(0)} %`;

    return { dev, avg };
  }
  const dev = `${((currentDevi ?? 0) * 100).toFixed(0)} %`;
  const avg = `${((currentAvg ?? 0) * 100).toFixed(0)} %`;
  return { dev, avg };
}
