import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Popover,
  Slider,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { ColorLens } from "@mui/icons-material";
import { TwitterPicker } from "react-color";
import { v4 as uuidv4 } from "uuid";
import { FileObject } from "react-mui-dropzone";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../../app/helpers";
import { RootState } from "../../../../app/rootReducer";
import LogoUploadCompany from "../../logo-upload-company.component";
import { createCompany } from "../../../../utils/axios-requests";
import { createFormMetadata } from "./createFormMetadata.function";
import { useRemoteSetMetadata } from "../../../../hooks/Remote/Metadata/useRemoteSetMetadata";
import { CompanyCardCheckinPolicy } from "../../company-card-checkin-policy.component";
import { CheckinPolicyGroup } from "../../company-card.component";
import { CheckinAndMaxAdvancedBooking } from "../../../Login/typings/login.types";
import {
  filterEnabledPolicy,
  generateAdvancedBookingSettings
} from "../../functions/company-card.component.functions";
import { BookingType } from "../../../Booking-Form/typings/booking-inputs";
import { useRemoteFetchMetadata } from "../../../../hooks/Remote/Metadata/useRemoteFetchMetadata";
import { CompanyMaximumAdvancedBookingSettingPerType } from "../../company-card-maximumAdvancedBooking-perType.component";

interface Props {
  createCompanyModalOpen: boolean;
  setCreateCompanyModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  enableNext: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  onConfirm: () => void;
}

export function CreateCompanyDialog({
  createCompanyModalOpen,
  setCreateCompanyModalOpen,
  enableNext,
  onConfirm
}: Props) {
  const { t } = useTranslation();
  const {
    userRoles: { isTenantAdmin }
  } = useSelector((state: RootState) => state.login);

  const [newCompany, setNewCompany] = useState({ name: "", refId: uuidv4() });
  const [brandColor, setBrandColor] = useState("#006651");

  const [hidePastBookings, setHidePastBookings] = useState(false);
  const [showBookingStatistics, setShowBookingStatistics] = useState(true);
  const [allowBookingCancellations, setAllowBookingCancellations] = useState(false);
  const [teamBookingAllowed, setTeamBookingAllowed] = useState(true);
  const [bookingsOnSaturdaysAllowed, setbookingsOnSaturdaysAllowed] = useState(false);
  const [bookingsOnSundaysAllowed, setbookingsOnSundaysAllowed] = useState(false);
  const [calendarSyncPossible, setCalendarSyncPossible] = useState(false);
  const [bookingNormHours, setBookingNormHours] = useState(8);
  const [logoFile, setLogoFile] = useState<FileObject[] | undefined>(undefined);
  const [automatedWorkplaceBookingReflectionTime] = useState(2);
  const [noBookingTime, setNoBookingTime] = useState(20);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [bookingAssistenceActive, setBookingAssistenceActive] = useState(false);
  const [homeOffice, setHomeOffice] = useState<{
    enabled: boolean;
    mandatory: boolean;
    homeOfficeDays: [number, number];
  }>({
    homeOfficeDays: [0, 5],
    enabled: false,
    mandatory: false
  });

  const [checkinEnabledGrp, setCheckinEnabledGrp] = useState<CheckinPolicyGroup>({
    workplace: false,
    parkingplace: false,
    electricchargingplace: false,
    conferencezone: false
  });

  const [wpSetting, setWpSetting] = useState<CheckinAndMaxAdvancedBooking>({
    bookingType: BookingType.WORKPLACE,
    checkinBufferTime: 15,
    checkinWhenOpeningDoor: false,
    geoLocationRequired: false,
    maxWeeksInAdvance: 2
  });
  const [parkPlSetting, setParkPlSetting] = useState<CheckinAndMaxAdvancedBooking>({
    bookingType: BookingType.PARKINGPLACE,
    checkinBufferTime: 15,
    checkinWhenOpeningDoor: false,
    geoLocationRequired: false,
    maxWeeksInAdvance: 2
  });
  const [eChargePlSetting, setEChargePlSetting] = useState<CheckinAndMaxAdvancedBooking>({
    bookingType: BookingType.ELECTRICCHARGINGSTATIONPLACE,
    checkinBufferTime: 15,
    checkinWhenOpeningDoor: false,
    geoLocationRequired: false,
    maxWeeksInAdvance: 2
  });
  const [confZnSetting, setConfZnSetting] = useState<CheckinAndMaxAdvancedBooking>({
    bookingType: BookingType.CONFERENCEZONE,
    checkinBufferTime: 15,
    checkinWhenOpeningDoor: false,
    geoLocationRequired: false,
    maxWeeksInAdvance: 2
  });

  const openEl = Boolean(anchorEl);

  const { mutateAsync: setMeta } = useRemoteSetMetadata({ mode: "Create" });
  const { refetch: refetchMetadata } = useRemoteFetchMetadata();

  async function handleClickCreate() {
    const dataAxios = await createCompany(newCompany);

    const companyBookingMetaId = (await refetchMetadata()).data?.find(
      d => d.companyId === dataAxios.data.id
    )?.id;

    const data = createFormMetadata({
      automatedWorkplaceBookingReflectionTime,
      bookingNormHours,
      brandColor,
      hidePastBookings,
      showBookingStatistics,
      allowBookingCancellations,
      teamBookingAllowed,
      bookingsOnSaturdaysAllowed,
      bookingsOnSundaysAllowed,
      calendarSyncPossible,
      homeOffice,
      id: companyBookingMetaId || 1,
      isTenantAdmin,
      logoFile,
      noBookingTime,
      createdCompanyId: dataAxios.data.id,
      bookingAssistenceActive,
      checkinSettings: filterEnabledPolicy({
        checkinEnabledGrp,
        wpSetting,
        parkPlSetting,
        eChargePlSetting,
        confZnSetting
      }),
      advancedBookingSettings: generateAdvancedBookingSettings(
        wpSetting,
        parkPlSetting,
        eChargePlSetting,
        confZnSetting
      )
    });

    await setMeta(data);
    setCreateCompanyModalOpen(false);

    // for onboarding
    if (enableNext) enableNext(true);
    onConfirm();
  }

  return (
    <Dialog
      PaperProps={{ sx: { backgroundImage: "none" } }}
      data-testid={"dialog"}
      onClose={() => setCreateCompanyModalOpen(false)}
      aria-labelledby="simple-dialog-title"
      open={createCompanyModalOpen}
      scroll="body"
    >
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          gap: 0.3,
          width: 500
        }}
      >
        <Typography variant={"h5"}>{t("New company")}</Typography>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          inputProps={{ "data-testid": "name-input" }}
          label={t("Company name")}
          type="text"
          value={newCompany.name}
          onChange={event =>
            setNewCompany(prevState => ({
              ...prevState,
              name: event.target.value
            }))
          }
          fullWidth
        />
        <LogoUploadCompany logoFile={logoFile} setLogoFile={setLogoFile} />
        <Grid
          container
          sx={{ marginTop: "10px", marginBottom: "10px" }}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <ColorLens sx={{ marginRight: "10px" }} />
          <Typography sx={{ marginTop: "5px" }} variant="body2" display="block" gutterBottom>
            {t("brand color")}
          </Typography>
          <div
            onClick={e => setAnchorEl(e.currentTarget)}
            data-testid={"popoverOpen"}
            style={{
              cursor: "pointer",
              marginLeft: 10,
              width: 30,
              height: 30,
              background: brandColor,
              borderRadius: 8
            }}
          />
        </Grid>
        <Popover
          open={openEl}
          data-testid={"popover"}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Grid container sx={{ marginTop: "10px", height: 100, width: 300 }}>
            <TwitterPicker
              data-testid={"twitterPicker"}
              color={brandColor}
              onChange={props => setBrandColor(props.hex)}
            />
          </Grid>
        </Popover>

        {/* maximum advanced booking time per booking type */}
        <Divider sx={{ width: "100%", marginTop: "3%", marginBottom: "3%" }} />
        <CompanyMaximumAdvancedBookingSettingPerType
          wpSetting={wpSetting}
          setWpSetting={setWpSetting}
          parkPlSetting={parkPlSetting}
          setParkPlSetting={setParkPlSetting}
          eChargePlSetting={eChargePlSetting}
          setEChargePlSetting={setEChargePlSetting}
          confZnSetting={confZnSetting}
          setConfZnSetting={setConfZnSetting}
        />

        {/* default booking hours */}
        <Divider sx={{ width: "100%", marginTop: "3%", marginBottom: "3%" }} />
        <Typography variant="body2" display="block" sx={{ marginBottom: "15px" }} gutterBottom>
          {t("defaultBookingHours")}
        </Typography>
        <Slider
          sx={{ width: "99%", margin: "auto" }}
          data-testid={"bookingNormHours-input"}
          value={bookingNormHours}
          min={1}
          aria-labelledby="discrete-slider-always"
          accessKey={"bookingNormHours-input"}
          step={1}
          valueLabelDisplay="auto"
          max={15}
          marks
          aria-label="Default booking duration in hours"
          onChange={(e: object, v: number | number[]) => setBookingNormHours(v as number)}
        />

        {/* allowed no booking time label */}
        <Divider sx={{ width: "100%", marginTop: "3%", marginBottom: "3%" }} />
        <Typography variant="body2" display="block" sx={{ marginBottom: "15px" }} gutterBottom>
          {t("AllowedNoBookingTimeLabel")}
        </Typography>
        <Slider
          sx={{ width: "99%", margin: "auto" }}
          value={noBookingTime}
          data-testid={"noBookingTime-input"}
          min={0}
          accessKey={"noBookingTime-input"}
          aria-labelledby="discrete-slider-always"
          step={5}
          valueLabelDisplay="auto"
          max={100}
          marks
          aria-label="No Booking Time"
          onChange={(e: object, v: number | number[]) => setNoBookingTime(v as number)}
        />

        {/* enable team booking */}
        <Divider sx={{ width: "100%", marginTop: "3%", marginBottom: "3%" }} />
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                data-testid={"teamBookingAllowed-input"}
                checked={teamBookingAllowed}
                onChange={(e, c) => setTeamBookingAllowed(c)}
                name="teamBookingAllowed"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label={t("enableTeamBookingAllowed")}
          />
        </FormGroup>

        {/* allow booking cancellation by team */}
        {teamBookingAllowed && (
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  data-testid={"enableAllowBookingCancellations-input"}
                  checked={allowBookingCancellations}
                  onChange={(e, c) => setAllowBookingCancellations(c)}
                  name="allowBookingCancellations"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              }
              label={t("enableAllowBookingCancellations")}
            />
          </FormGroup>
        )}

        {/* enable bookings on saturday */}
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                data-testid={"enableBookingsOnSaturday-input"}
                checked={bookingsOnSaturdaysAllowed}
                onChange={(e, c) => {
                  setbookingsOnSaturdaysAllowed(c);
                }}
                name="enableBookingsOnSaturday"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label={t("enableBookingsOnSaturday")}
          />
        </FormGroup>

        {/* enable bookings on sunday */}
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                data-testid={"enableBookingsOnSunday-input"}
                checked={bookingsOnSundaysAllowed}
                onChange={(e, c) => {
                  setbookingsOnSundaysAllowed(c);
                }}
                name="enableBookingsOnSunday"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label={t("enableBookingsOnSunday")}
          />
        </FormGroup>

        {/* calendar sync possible */}
        <Divider sx={{ width: "100%", margin: "4px 0px 4px 0px" }} />
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                data-testid={"calendarSyncPossible-input"}
                checked={calendarSyncPossible}
                onChange={(e, c) => {
                  setCalendarSyncPossible(c);
                }}
                name="calendarSyncPossible"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label={t("calendarSyncPossible")}
          />
        </FormGroup>

        {/* hide past booking schedule */}
        <Divider sx={{ width: "100%", margin: "4px 0px 4px 0px" }} />
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                data-testid={"enableHidePastBookings-input"}
                checked={hidePastBookings}
                onChange={(e, c) => {
                  setHidePastBookings(c);
                }}
                name="enableHidePastBookings"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label={t("enableHidePastBookings")}
          />
        </FormGroup>

        {/* show user booking statistic in the calendar */}
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                data-testid={"enableShowBookingStatistics-input"}
                checked={showBookingStatistics}
                onChange={(e, c) => {
                  setShowBookingStatistics(c);
                }}
                name="enableShowBookingStatistics"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label={t("enableShowBookingStatistics")}
          />
        </FormGroup>

        {/* checkin policy by booking type */}
        <Divider sx={{ width: "100%", margin: "4px 0px 4px 0px" }} />
        <FormGroup row>
          <CompanyCardCheckinPolicy
            checkinEnabledGrp={checkinEnabledGrp}
            setCheckinEnabledGrp={setCheckinEnabledGrp}
            wpSetting={wpSetting}
            setWpSetting={setWpSetting}
            parkPlSetting={parkPlSetting}
            setParkPlSetting={setParkPlSetting}
            eChargePlSetting={eChargePlSetting}
            setEChargePlSetting={setEChargePlSetting}
            confZnSetting={confZnSetting}
            setConfZnSetting={setConfZnSetting}
            sliderStyle={{ marginLeft: "50px", width: "88%" }}
          />
        </FormGroup>

        {/* home office switch */}
        <Divider sx={{ width: "100%", margin: "4px 0px 4px 0px" }} />
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                data-testid={"homeOfficeSwitch-input"}
                checked={homeOffice.enabled}
                onChange={(e, c) => setHomeOffice(prevState => ({ ...prevState, enabled: c }))}
                name="homeOfficePolicy"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label={t("mobileWorkingPolicy")}
          />
        </FormGroup>

        {homeOffice.enabled && (
          <>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    data-testid={"homeOfficeMandatory-input"}
                    checked={homeOffice.mandatory}
                    onChange={(e, c) =>
                      setHomeOffice(prevState => ({ ...prevState, mandatory: c }))
                    }
                    name="homeOfficePolicyStrictness"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={t("Mandatory")}
              />
            </FormGroup>

            <Typography variant="body2" display="block" sx={{ marginBottom: "15px" }} gutterBottom>
              {t("home office range in days")}
            </Typography>
            <Slider
              data-testid={"homeOffice-input"}
              value={homeOffice.homeOfficeDays}
              valueLabelDisplay="auto"
              sx={{ width: "97%", marginLeft: "10px", marginBottom: "13px" }}
              marks
              min={0}
              max={5}
              step={1}
              accessKey={"homeOffice-input"}
              onChange={(e: object, v: number | number[]) =>
                setHomeOffice(prevState => ({
                  ...prevState,
                  homeOfficeDays: v as [number, number]
                }))
              }
            />
          </>
        )}

        {/* booking assistance active */}
        <Divider sx={{ width: "100%", margin: "4px 0px 4px 0px" }} />
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                data-testid={"bookingAssistenceActive-input"}
                checked={bookingAssistenceActive}
                onChange={(e, c) => setBookingAssistenceActive(c)}
                name="bookingAssistenceActive"
                inputProps={{ "aria-label": "bookingAssistenceActive" }}
              />
            }
            label={t("bookingAssistantActive")}
          />
        </FormGroup>

        {/* confirm button */}
        <Button
          sx={{ mt: 1.5 }}
          data-testid={"submitButton"}
          disabled={!newCompany.name}
          onClick={handleClickCreate}
        >
          {t("Confirm")}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
