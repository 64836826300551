import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

/**
 * @description Spinner that renders when waiting for the API response.
 * @version 0.1.0
 */

type P = {
  style?: React.CSSProperties;
  color?: string;
  size?: number;
  text?: string;
};

const DefaultSpinner: React.FC<P> = ({ style, color, size, text }) => {
  return (
    <Box
      data-testid="default-spinner"
      sx={{
        padding: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...style
      }}
    >
      <CircularProgress color="primary" sx={{ color }} size={size} />
      {!!text && <Typography sx={{ ml: 1 }}>{text}</Typography>}
    </Box>
  );
};

export default DefaultSpinner;
