import { forwardRef, useEffect, useState } from "react";
import { Box, Grid, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { IZoneSchedule, IZoneUserNumber } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { InfoSideBar } from "../InfoSidebar";
import { InfoBox } from "../InfoBox";
import { CompactTextField } from "../../../../../Common/CompactTextfield/CompactTextfield";
import { AssignBox } from "../AssignBox/AssignBox";
import { IFloorPayload } from "../../../../Domain/Types/FloorPlan/FloorPayload.type";
import { MultiInfoHeader } from "../MultiInfoHeader/MultiInfoHeader";
import { MiniTextInput } from "../../../../../Common/MiniTextInput/MiniTextInput";
import { InfoIconWithTooltip } from "../../../../../Title/InfoIcon";
import {
  handleUserNumbersInput,
  initialUserNumbers,
  isEntryAssigned
} from "./ZoneInfoSidebar.partial";
import { TablePlacementDialogContent } from "./TablePlacementDialogContent/TablePlacementDialogContent";
import { SingleTableArrangement } from "../../../../Domain/Types/FloorPlan/TableArrangement.type";
import { IEquipmentInventory } from "../../../../Domain/Types/FloorPlan/Equipment.type";
import { OptionDialogContent } from "./OptionDialogContent/OptionDialogContent";
import { useEquipmentManagementActions } from "../../../../Hooks/useEquipmentManagement";
import { useFeatures } from "../../../../Hooks/useFeatures";
import { useRemoteFetchEquipmentInventory } from "../../../../../../hooks/Remote/Devices/useRemoteFetchEquipmentInventory";
import { BookableEquipmentDialogContent } from "./BookableEquipmentDialogContent/BookableEquipmentDialogContent";
import { SingleBookableEquipmentDto } from "./BookableEquipmentDialogContent/typings/ServiceBookableEquipment.types";
import { initMoreItemName } from "../functions/initMoreItemName.function";
import { initAssignTablePlacementName } from "../functions/initAssignTablePlacementName.function";
import { findZoneBookingType } from "../../../Views/ReportFloorPlanView/ReportFloorPlanView.functions";

interface Props {
  height: number;
  zoneSchedules: IZoneSchedule[];
  floorPlan: IFloorPayload;
  equipmentManagement: useEquipmentManagementActions;
  onClose: () => void;
  onChangeUserNumber: (ids: number[], userNumber: IZoneUserNumber) => void;
  onChangeConfService: (
    id: number,
    service: {
      tableArr?: SingleTableArrangement[];
      bookableEquipments?: SingleBookableEquipmentDto[];
    }
  ) => void;
}

/** simple component for editing zone service info */
export const ZoneServiceInfoSidebar = forwardRef<HTMLDivElement, Props>(
  (
    {
      height,
      zoneSchedules,
      floorPlan,
      equipmentManagement,
      onClose,
      onChangeUserNumber,
      onChangeConfService
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { useEquipmentManageInService, useBookableEquipmentManageInService } = useFeatures();
    const { minUsers, maxUsers } = initialUserNumbers(zoneSchedules);

    const zoneName = zoneSchedules[0]?.inventory?.name ?? "";
    const zoneDesc = zoneSchedules[0]?.description ?? "";

    const [zoneUserNumber, setZoneUserNumber] = useState<IZoneUserNumber>({
      minUsers: minUsers,
      maxUsers: maxUsers
    });

    const [isTableModalOpen, setIsTableModalOpen] = useState(false);
    const [isEquipmenteModalOpen, setIsEquipmenteModalOpen] = useState(false);
    const [isBookableEquipmenteModalOpen, setIsBookableEquipmenteModalOpen] = useState(false);
    const [assignedEquipment, setAssginedEquipment] = useState<IEquipmentInventory[]>([]);
    const [bookableEquipment, setBookableEquipment] = useState<IEquipmentInventory[]>([]);

    const { data: remoteEquipments } = useRemoteFetchEquipmentInventory();

    useEffect(() => {
      setAssginedEquipment([...(zoneSchedules[0]?.equipments ?? [])]);
    }, [zoneSchedules]);

    useEffect(() => {
      if (!remoteEquipments) return;
      setBookableEquipment(
        remoteEquipments.map(eq => ({
          id: eq.id,
          name: eq.name,
          uid: eq.uid,
          assetRefId: eq.assetRefId,
          imageUrl: "", // el.imageUrl,
          equipmentCategory: { id: 0, name: "" } // el.equipmentCategory ?? { id: 0, name: "" },
        }))
      );
    }, [zoneSchedules, remoteEquipments]);

    useEffect(() => {
      setZoneUserNumber({
        minUsers: minUsers,
        maxUsers: maxUsers
      });
    }, [minUsers, maxUsers]);

    const zoneTypeId =
      zoneSchedules[0]?.zoneTypeId ||
      zoneSchedules[0]?.inventory?.zoneTypeId ||
      zoneSchedules[0]?.inventory?.zoneType?.id;

    const zoneTypeName = findZoneBookingType(zoneTypeId ?? 0);

    // length of selected zone
    const singleZoneSelected = zoneSchedules.length === 1;

    // check if multi selected zone have same type
    const isIdenticalZoneTypeSelected = zoneSchedules
      .map(z => z.zoneTypeId || z.inventory?.zoneTypeId)
      .every(v => v === (zoneSchedules[0].inventory?.zoneTypeId || zoneSchedules[0].zoneTypeId));

    // check if multi selected zone type includes conference zone
    const isConferenceOnlySelected = zoneSchedules
      .map(z => z.zoneTypeId || z.inventory?.zoneTypeId)
      .every(v => v === 3);

    const availableEquipment = floorPlan.freeInventories.equipments.filter(
      d => !isEntryAssigned(d, floorPlan.zones)
    );

    return (
      <InfoSideBar height={height} width={280} overflow="scroll" ref={ref}>
        {zoneSchedules.length > 0 && (
          <InfoBox color={"#00000088"}>
            {/* header of multi zones edit and listing the names */}
            <MultiInfoHeader
              showMultiInfoHeader={zoneSchedules.length > 1}
              type={"Zone"}
              onClose={onClose}
            />

            {/* name of the zone */}
            <CompactTextField
              placeholder={t("Name")}
              value={zoneName}
              newText={zoneName}
              rows={2}
              readOnly={true}
            />

            {/* description of the zone */}
            <CompactTextField
              rows={2}
              placeholder={t("Description")}
              value={zoneDesc}
              newText={zoneDesc}
              readOnly={true}
            />

            {/* type of the zone */}
            {isIdenticalZoneTypeSelected && (
              <AssignBox
                buttonDataTestId="type-assign-box"
                value={
                  zoneSchedules[0].zoneTypeId ||
                  zoneSchedules[0].inventory?.zoneType?.id ||
                  zoneSchedules[0].inventory?.zoneTypeId
                }
                name={zoneTypeName}
                type={"Type"}
              />
            )}

            {/** inventory */}
            {singleZoneSelected && (
              <AssignBox
                value={zoneSchedules[0].inventory?.id}
                type={"Inventory"}
                name={zoneSchedules[0].inventory?.name}
              />
            )}

            {/** category */}
            <AssignBox
              type={"Category"}
              buttonDataTestId="assign-category-btn"
              value={zoneSchedules[0].category?.id}
              name={zoneSchedules[0].category?.name}
            />

            {/** property for conference zone */}
            {isConferenceOnlySelected && singleZoneSelected && (
              <>
                {/** min and max users number */}
                <Grid
                  sx={{ pt: "0.3rem", flexWrap: "nowrap" }}
                  data-testid="zone-userNumber-input"
                  container
                >
                  <Grid item>
                    <MiniTextInput
                      fields={[
                        {
                          id: "min",
                          type: "number",
                          placeholder: "Min",
                          value: zoneUserNumber.minUsers
                        },
                        {
                          id: "max",
                          type: "number",
                          placeholder: "Max",
                          value: zoneUserNumber.maxUsers
                        }
                      ]}
                      readOnly={true}
                      onInput={(id, value) => {
                        handleUserNumbersInput(
                          id,
                          value,
                          zoneSchedules[0].id,
                          zoneUserNumber,
                          setZoneUserNumber,
                          onChangeUserNumber,
                          enqueueSnackbar
                        );
                      }}
                    />
                  </Grid>
                  <Grid item data-testid="user-number-info-desc" sx={{ pt: "2px", pl: "2px" }}>
                    <InfoIconWithTooltip tooltipText={t("EditInfoUserNumberForConferenceZone")} />
                  </Grid>
                </Grid>

                {/* equipment */}
                {/* display a list of assigned equipments */}
                {useEquipmentManageInService && (
                  <AssignBox
                    buttonDataTestId="equipment-assign-box"
                    onClickAssign={() => setIsEquipmenteModalOpen(!isEquipmenteModalOpen)}
                    type={"Equipment"}
                    value={
                      assignedEquipment.length
                        ? t(`${assignedEquipment.length} (pcs)`)
                        : t("NoEquipmentSidebarLabel")
                    }
                    valueArr={assignedEquipment.map(eq => eq.name)}
                    name={initMoreItemName(zoneSchedules[0].equipments, "Equipment", t)}
                  />
                )}

                {/* bookable equipment */}
                {useBookableEquipmentManageInService && (
                  <AssignBox
                    buttonDataTestId="bookable-equipment-assign-box"
                    onClickAssign={() =>
                      setIsBookableEquipmenteModalOpen(!isBookableEquipmenteModalOpen)
                    }
                    type={"Bookable_Equipment"}
                    value={
                      bookableEquipment.length
                        ? t(`${bookableEquipment.length} (pcs)`)
                        : t("NoEquipmentSidebarLabel")
                    }
                    valueArr={zoneSchedules[0].equipments.map(eq => eq.name)}
                    name={initMoreItemName(zoneSchedules[0].equipments, "Bookable_Equipment", t)}
                  />
                )}

                {/** table placements */}
                <AssignBox
                  onClickAssign={() => setIsTableModalOpen(true)}
                  value={zoneSchedules[0].inventory?.zoneTypeId}
                  type={"TablePlacement"}
                  buttonDataTestId="assign-tablePlacement-btn"
                  name={initMoreItemName(
                    initAssignTablePlacementName(floorPlan, zoneSchedules),
                    "TablePlacement",
                    t
                  )}
                />
              </>
            )}

            {/** bottom bar */}
            <Box display={"flex"} alignItems={"center"}>
              <Box flex={1} />
              {/* no items for zone service info */}
            </Box>

            {/* conference zone - table placement modal */}
            <Modal
              open={isTableModalOpen}
              onClose={() => setIsTableModalOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <>
                <TablePlacementDialogContent
                  tableArr={floorPlan.tableArrangementAssignments}
                  onClose={() => setIsTableModalOpen(false)}
                  zoneSchedule={zoneSchedules[0]}
                  onChangeConfService={onChangeConfService}
                />
              </>
            </Modal>

            {/* zone equipment assignment modal */}
            {useEquipmentManageInService && (
              <Modal
                open={isEquipmenteModalOpen}
                onClose={() => setIsEquipmenteModalOpen(false)}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                <>
                  <OptionDialogContent
                    pivotEntityName="Zone"
                    subjectEntityName={"Equipment"}
                    onClose={() => setIsEquipmenteModalOpen(false)}
                    displayHeaderId={zoneSchedules[0].id}
                    displayChipValue={""}
                    subjectEntity={zoneSchedules[0]}
                    subjectEntityEntries={[
                      {
                        availableEquipment: availableEquipment,
                        assignedEquipment: assignedEquipment
                      }
                    ]}
                    updateFunctionSub2={equipmentManagement.changeZoneEquipments}
                  />
                </>
              </Modal>
            )}

            {/* zone bookable equipment assignment modal */}
            {useBookableEquipmentManageInService && (
              <Modal
                open={isBookableEquipmenteModalOpen}
                onClose={() => setIsEquipmenteModalOpen(false)}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                <BookableEquipmentDialogContent
                  zoneSchedule={zoneSchedules[0]}
                  bookableEquipment={bookableEquipment}
                  onChangeConfService={onChangeConfService}
                  onClose={() => setIsBookableEquipmenteModalOpen(false)}
                />
              </Modal>
            )}
          </InfoBox>
        )}
      </InfoSideBar>
    );
  }
);
