import { Autocomplete, Chip, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

type P = {
  departmentOpt: { id: string; name: string }[];
  handleDepartmentSelect: (event: any, value: { id: string; name: string }[]) => void;
};

export function DepartmentSelector({ departmentOpt, handleDepartmentSelect }: P) {
  const { t } = useTranslation();

  return (
    <Autocomplete
      sx={{ width: 850 }}
      data-testid="select-department-for-report"
      disableCloseOnSelect
      multiple
      fullWidth
      limitTags={4}
      options={departmentOpt}
      onChange={handleDepartmentSelect}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) => option.name === value.name && option.id === value.id}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{ ...params.InputProps }}
          placeholder={t("Choose departments")}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.name}>
          <div id={option.name} data-testid={`department-opt-${option.name}`}>
            {option.name}
          </div>
        </li>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...rest } = getTagProps({ index });
          return <Chip key={key} {...rest} label={option.name} />;
        })
      }
    />
  );
}
