import { MutableRefObject, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../../app/helpers";
import { RootState } from "../../../../app/rootReducer";
import DefaultSpinner from "../../../../components/LoadingSpinner/default-spinner.component";
import { Timeframe } from "../../../../features/FloorManager/typings/shared/timeframe";
import { finishInit } from "../../../../functions/FacilityManagerInitializer";
import { useRemoteFetchFloorPlan } from "../../../../hooks/Remote/FloorPlan/useRemoteFetchFloorPlan";
import currentPlanUniqueTimeframesSelector from "../../selectors/currentPlanUniqueTimeframesSelector";
import selectedEntitiesSelector from "../../selectors/selectedEntitiesSelector";
import InitializingLoadingScreenModal from "../initializing-loadingscreen.component";
import { useRemoteFetchFloorPlanService } from "../../../../hooks/Remote/FloorPlan/useRemoteFetchFloorPlanService";
import { IFloorPayload } from "../../../../components/FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { TimeframesList } from "./menu-timeframes-list.component";

type Props = {
  floorTimeframes: Timeframe[];
  refetchFloorTimeframes: () => void;
};

const TimeframesMenu: React.FC<Props> = ({ floorTimeframes, refetchFloorTimeframes }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const selected = useSelector(selectedEntitiesSelector);
  const uniqueTimeframes = useSelector(currentPlanUniqueTimeframesSelector);
  const { isInitializing: isInitializingPlan } = useSelector(
    (state: RootState) => state.floorManager.floors
  );

  const [newTimeframe, setNewTimeframe] = useState(false);
  const [startDate, setStartDate] = useState<DateTime | null>(null);

  const [selectedTimeframe, setSelectedTimeframe] = useState<Timeframe>({ start: "", end: "" });
  const [newTimeframeData, setNewTimeframeData] = useState<Timeframe>({ start: "", end: "" });

  const [isCreateFirstTimeframe, setIsCreateFirstTimeframe] = useState(false);
  const [isEditTimeframe, setIsEditTimeframe] = useState(false);
  const [isContinueTimeframe, setIsContinueTimeframe] = useState(false);

  const [isEditService, setIsEditService] = useState(false);

  const { data: floorPlan, isFetching: floorPlanIsFetching } = useRemoteFetchFloorPlan(
    selected.floor?.id || 0,
    selectedTimeframe.start,
    selectedTimeframe.end
  );
  const { data: floorPlanForService, isFetching: floorPlanForServiceIsFetching } =
    useRemoteFetchFloorPlanService(
      selected.floor?.id || 0,
      selectedTimeframe.start,
      null,
      isEditService
    );

  const clickEdit = async (timeframe: Timeframe) => {
    setSelectedTimeframe(timeframe);
    setIsEditTimeframe(true);
  };

  const editServices = (timeframe: Timeframe) => {
    setSelectedTimeframe(timeframe);
    setIsEditService(true);
  };

  /**
   * @argument selectedTimeframe_for_floorplan
   * when timeframe is existed and create new one,
   * it used to select the latest one from the existing by setSelectedTimeframe(floorTimeframes.slice(-1)[0])
   * but now, BE returns the latest
   */
  const clickNextCreate = (startDay: string) => {
    setSelectedTimeframe({ start: startDay, end: null });
    setNewTimeframeData({ start: startDay, end: null });
    if (floorTimeframes.length) setIsContinueTimeframe(true);
    else setIsCreateFirstTimeframe(true);
  };

  useEffect(() => {
    // edit the floor plan only for services
    if (isEditService && !floorPlanForServiceIsFetching && floorPlanForService && floorPlan) {
      /** before BE implements, if normal floor plan has zone equipments attach those data for floorPlanForService */
      const zoneInclEquip = floorPlanForService.zones.map(zone => {
        const found = floorPlan.zones.find(zn => zone.id === zn.id);
        return {
          ...zone,
          equipments: found?.equipments,
          equipmentInventoryIds: found?.equipmentInventoryIds,
          zoneEquipments: found?.zoneEquipments
        };
      });

      finishInit(dispatch, selectedTimeframe, {
        ...({ ...floorPlanForService, zones: [...zoneInclEquip] } as IFloorPayload),
        freeInventories: { ...floorPlan.freeInventories }
      });
      setIsEditService(false);
    }
  }, [isEditService, floorPlanForServiceIsFetching, floorPlanIsFetching]);

  useEffect(() => {
    // show plan or edit the last time frame
    if (isEditTimeframe && !floorPlanIsFetching && floorPlan) {
      finishInit(dispatch, selectedTimeframe, floorPlan);
      setIsEditTimeframe(false);
    }
  }, [isEditTimeframe, floorPlanIsFetching]);

  useEffect(() => {
    // timeframe is existed but create new one
    if (isContinueTimeframe && !floorPlanIsFetching && floorPlan) {
      finishInit(dispatch, newTimeframeData, floorPlan);
      setIsContinueTimeframe(false);
    }
  }, [isContinueTimeframe, newTimeframeData, floorPlanIsFetching]);

  useEffect(() => {
    // initial the very first timeframe
    if (isCreateFirstTimeframe && floorPlan) {
      finishInit(dispatch, newTimeframeData, floorPlan);
      setIsCreateFirstTimeframe(false);
    }
  }, [isCreateFirstTimeframe, newTimeframeData, floorPlan]);

  if (!uniqueTimeframes || !selected.floor) return <DefaultSpinner />;
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      data-testid="menu-timeframe-box"
    >
      <InitializingLoadingScreenModal
        isOpen={isInitializingPlan}
        title={t("Calculating basis for your next plan...")}
      />

      <TimeframesList
        timeframes={floorTimeframes}
        selectedFloor={selected.floor}
        isEditService={isEditService}
        isEditTimeframe={isEditTimeframe}
        startDate={startDate}
        newTimeframe={newTimeframe}
        refetchFloorTimeframes={refetchFloorTimeframes}
        clickEdit={clickEdit}
        editServices={editServices}
        setNewTimeframe={setNewTimeframe}
        setStartDate={setStartDate}
        clickNextCreate={clickNextCreate}
      />
    </Box>
  );
};

export default TimeframesMenu;

export function checkPreviousAndSelectedTimeframe(
  previousSelectedTimeframe: MutableRefObject<Timeframe>,
  selectedTimeframe: Timeframe,
  newTimeframe: boolean,
  selectTimeframe: () => void
) {
  if (
    previousSelectedTimeframe.current.start == selectedTimeframe.start ||
    previousSelectedTimeframe.current.end == selectedTimeframe.end ||
    newTimeframe
  ) {
    selectTimeframe();
  } else {
    previousSelectedTimeframe.current = selectedTimeframe;
  }
}

export function checkStartAndReturnTargetFrame(start: DateTime | null, end: DateTime | null) {
  if (!start) throw new Error("Timeframe needs start");

  const targetFrame = {
    start: start.toISO(),
    end: end?.toISO() ?? null
  };

  return targetFrame;
}
