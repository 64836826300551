import { FunctionComponent } from "react";
import { Box, Fade, Grid, Skeleton } from "@mui/material";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { CapacityObject, FloorPlanMemo } from "../../features/Reports/typings/reports.types";
import SvgReport from "../Svg/svg-report.component";
import { IFloorPayload } from "../FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";

interface OwnProps {
  floorData: FloorPlanMemo;
  zoneBookingReport: boolean;
  entityData: (CapacityObject | undefined)[] | undefined;
  isFloorFullMode: { index: number | null; open: boolean };
  setIsFloorFullMode: (mode: { index: number | null; open: boolean }) => void;
}

type Props = OwnProps;

const FacilityReportSVG: FunctionComponent<Props> = ({
  floorData,
  zoneBookingReport,
  entityData,
  isFloorFullMode,
  setIsFloorFullMode
}) => {
  const { currentFloors, selectedZoneOrWorkplace, selectableEntities, selectedLocation } =
    useSelector((state: RootState) => state.report);

  const openFloorSvg =
    !!currentFloors[0].id &&
    floorData[currentFloors[0].id] &&
    entityData &&
    entityData[0]?.capacity?.capacityPerformancePerWorkplace;

  const isCurrentFloorFullMode = (i: number) => isFloorFullMode.index === i && isFloorFullMode.open;

  const gridOrderByFullMode = (i: number) => {
    if (isFloorFullMode.index === null) return 0;
    if (currentFloors.length === 1 && !isFloorFullMode.open) return 0;
    if (isCurrentFloorFullMode(i)) return 0;
    else return 1;
  };

  return (
    <>
      {!openFloorSvg && <Skeleton variant="rectangular" width={"100%"} height={"78vh"} />}

      {/* floors svg report component */}
      {openFloorSvg &&
        currentFloors.map((floor, i) => (
          <Grid
            order={gridOrderByFullMode(i)}
            item
            xs={12}
            md={isCurrentFloorFullMode(i) ? 12 : 6}
            sx={{ height: isCurrentFloorFullMode(i) ? "fit-content" : "100%" }}
            key={floor.id}
            data-testid={`facility-report-floor-grid-${i}`}
          >
            {floorData[floor.id]?.floorData && (
              <Fade key={floor.id} mountOnEnter in={!!currentFloors[i].id}>
                <Box
                  data-testid={`facility-report-floor-grid-box${i}`}
                  sx={{ mt: 1, mb: 1, mr: i === 0 ? 1 : 0, ml: i === 0 ? 0 : 1 }}
                >
                  <SvgReport
                    height={isCurrentFloorFullMode(i) ? 1500 : 500}
                    zoneBooking={zoneBookingReport}
                    name={
                      selectableEntities
                        ?.find(entity => entity.id === selectedLocation.id)
                        ?.floors.find(entity => entity.inventoryId === floor.id)?.name
                    }
                    floorData={floorData[floor.id]?.floorData as IFloorPayload}
                    backgroundUrl={
                      floorData[floor.id]?.floorData.outlineUrl ||
                      floorData[floor.id]?.picturePath ||
                      ""
                    }
                    utilizationRates={
                      selectedZoneOrWorkplace?.open &&
                      selectedZoneOrWorkplace?.floorInventoryId === floor.id &&
                      entityData &&
                      entityData[0]
                        ? entityData[0].capacity.capacityPerformancePerWorkplace
                        : entityData?.find(entity => entity?.id === floor.id)?.capacity
                            .capacityPerformancePerWorkplace || [
                            { bookingObject: -1, capacityRate: 0 }
                          ]
                    }
                    currentFloorInfo={{ floor: floor, index: i }}
                    isFloorFullMode={isFloorFullMode}
                    setIsFloorFullMode={setIsFloorFullMode}
                  />
                </Box>
              </Fade>
            )}
          </Grid>
        ))}
    </>
  );
};

export default FacilityReportSVG;
