import { Weekdays } from "../../components/Pickers/WeekdayPicker/weekday-picker.component";

export const initialWeekdays: Weekdays = {
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: false,
  sunday: false
};

export const initialWeekdaysUnselected: Weekdays = {
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false
};
