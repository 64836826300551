import React, { useEffect, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { Timeframe } from "../../features/FloorManager/typings/shared/timeframe";
import { setMarkers } from "../../features/Reports/slices/report.slice";
import {
  fetchFilterOptions,
  fetchMarkers,
  fetchReport
} from "../../features/Reports/thunks/report.thunk";
import { FloorPlanMemo, TimeframeString } from "../../features/Reports/typings/reports.types";
import { getColor } from "./area-chart.component";
import FacilityReportGoogleMap from "./facility-report-google-map.component";
import FacilityReportRestrictionPicker from "./facility-report-restriction-picker.component";
import FacilityReportDialog from "./facility-report-dialog.component";

type P = {
  floorData: FloorPlanMemo;
  memoizeFloorPlan: (floorInventoryId: number, date: Timeframe) => void;
};

/**
 * @description Component that displays the facility manager report.is
 * @param floorData as FloorPlanMemo which contains the floor data.
 * @param memoizeFloorPlan as function to memoize the floor plan.
 * @version 0.2.0
 */
const LocationReport: React.FC<P> = ({ memoizeFloorPlan, floorData }) => {
  const dispatch = useDispatch();
  const {
    userInformation: { sub },
    userRoles: { isCapacityResponsible }
  } = useSelector((state: RootState) => state.login);

  const {
    mapMarkerLocations,
    selectedTimeframe,
    capacitySelectedFloors,
    currentFloors,
    calculateWithoutCosts,
    selectedBookingType,
    selectedWeekdays,
    equipment
  } = useSelector((state: RootState) => state.report);

  const [zoneBookingReport, setZoneBookingReport] = useState(!isCapacityResponsible);

  const colorScale = [
    { percent: "20%", color: getColor(0.2) },
    { percent: "40%", color: getColor(0.4) },
    { percent: "60%", color: getColor(0.6) },
    { percent: "80%", color: getColor(0.8) },
    { percent: "100%", color: getColor(1) }
  ];

  const checkCurrentFloorsFloorData =
    currentFloors.filter(floor => floorData[floor.id]).length !== currentFloors.length;

  const memoized = (i: number) => {
    memoizeFloorPlan(currentFloors[i].id, {
      start: new Date(selectedTimeframe?.timeframeStart as string).toISOString(),
      end: new Date(selectedTimeframe?.timeframeEnd as string).toISOString()
    });
  };

  useEffect(() => {
    if (selectedTimeframe && currentFloors[0]) {
      dispatch(
        fetchReport({
          id: currentFloors[0].id,
          sub: sub,
          cost: calculateWithoutCosts,
          start: selectedTimeframe.timeframeStart,
          end: selectedTimeframe.timeframeEnd,
          filterType: "floor",
          status: true,
          update: false,
          zoneBooking: false,
          bookingType: selectedBookingType ?? undefined,
          weekdays: selectedWeekdays
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipment]);

  useEffect(() => {
    if (checkCurrentFloorAndTime(currentFloors, selectedTimeframe) && checkCurrentFloorsFloorData) {
      currentFloors.forEach((floor, i) => {
        if (!floorData[floor.id]) {
          memoized(i);
        }
      });
    }
  }, [capacitySelectedFloors, currentFloors, memoizeFloorPlan]);

  // when selected time frame is changed, refetch the floor plan so that the floor plan displays relevant plan during the selected timeframe
  useEffect(() => {
    if (checkCurrentFloorAndTime(currentFloors, selectedTimeframe)) {
      currentFloors.forEach((floor, i) => {
        memoized(i);
      });
    }
  }, [selectedTimeframe?.timeframeEnd, selectedTimeframe?.timeframeStart]);

  useEffect(() => {
    dispatch(fetchFilterOptions({}));
  }, [dispatch]);

  useEffect(() => {
    if (!mapMarkerLocations && !zoneBookingReport) {
      if (!zoneBookingReport) {
        dispatch(fetchMarkers({ status: true, mode: "capacity" }));
      }
    }
  }, [dispatch, isCapacityResponsible, mapMarkerLocations, zoneBookingReport]);

  //Remove markers on unmount
  useEffect(() => {
    return () => {
      dispatch(setMarkers(undefined));
    };
  }, [dispatch]);

  return (
    <div style={{ height: "calc(100vh - 70px)" }}>
      <div style={{ width: "auto", height: "100%", padding: 0 }}>
        {!mapMarkerLocations && (
          <div
            style={{
              width: "auto",
              height: "100%",
              padding: 0,
              background: "black",
              opacity: 0.2,
              zIndex: 999
            }}
          >
            <CircularProgress
              sx={{
                position: "fixed",
                bottom: "45%",
                right: "35%",
                float: "right",
                color: "white",
                display: "flex"
              }}
            />
            {zoneBookingReport && (
              <Typography
                sx={{
                  position: "fixed",
                  bottom: "40%",
                  right: "30%",
                  float: "right",
                  color: "white",
                  display: "flex"
                }}
              >
                Please select restriction
              </Typography>
            )}
          </div>
        )}
        <FacilityReportGoogleMap zoneBookingReport={zoneBookingReport} />
      </div>

      <div style={{ position: "fixed", bottom: 24, right: 70, float: "right", display: "flex" }}>
        {colorScale.map(color => (
          <div
            key={color.color}
            style={{
              background: color.color,
              width: 45,
              border: "2px solid white",
              borderRadius: 4,
              margin: "0 2px 0 2px",
              height: 40,
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {color.percent}
          </div>
        ))}
      </div>
      <FacilityReportDialog zoneBookingReport={zoneBookingReport} floorData={floorData} />
      <FacilityReportRestrictionPicker
        zoneBookingReport={zoneBookingReport}
        setZoneBookingReport={setZoneBookingReport}
      />
    </div>
  );
};

export default LocationReport;

export const checkCurrentFloorAndTime = (
  currentFloors: Array<{ id: number; open: boolean }>,
  selectedTimeframe: TimeframeString | undefined
) => {
  return currentFloors.length && selectedTimeframe?.timeframeStart && currentFloors[0].id !== -1;
};
