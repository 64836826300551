/** handle the location picture path whether it's empty or picture path url */
export function handleLocationPicturePath(picturePath: string | null) {
  if (!picturePath) return "";
  const locPath = "location-thumbnails/";
  const afterCloudPath = "?GoogleAccessId";

  if (picturePath === "temp") {
    return locPath + picturePath.split(locPath)[1];
  }
  const onlyPath = picturePath.split(locPath)[1].split(afterCloudPath)[0];

  return locPath + onlyPath;
}
