import { PropsWithChildren, forwardRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Close, Replay } from "@mui/icons-material";
import { TransitionForSlideoutPanel } from "./SlideoutPanelTransition.component";

type P = {
  children?: React.ReactNode;
  isOpen: boolean;
  dialogContents?: JSX.Element;
  dialogTitle: string;
  width?: string;
  showCloseButton: boolean;
  showDetailToggle?: boolean;
  detailGroupFilterToggle?: JSX.Element;
  primaryButtonTitle?: string;
  secondaryButtonTitle?: string;
  primaryButtonAction?: () => void;
  secondaryButtonAction?: () => void;
  onClose: () => void;
};

export const SlideoutPanelDialog: React.FC<PropsWithChildren<P>> = forwardRef<HTMLDivElement, P>(
  (props, ref) => {
    const {
      children,
      isOpen,
      dialogTitle,
      width = "35vw",
      showCloseButton,
      showDetailToggle,
      detailGroupFilterToggle,
      primaryButtonTitle,
      primaryButtonAction,
      secondaryButtonTitle,
      secondaryButtonAction,
      onClose
    } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isMobileTablet = useMediaQuery(theme.breakpoints.down("lg"));
    const dialogStyleTop = isMobile ? "60px" : "64px";

    const handlePrimaryButtonAction = () => {
      primaryButtonAction?.();
      onClose();
    };
    const handleSecondaryButtonAction = () => {
      secondaryButtonAction?.();
      onClose();
    };

    return (
      <Dialog
        ref={ref}
        open={isOpen}
        onClose={onClose}
        data-testid="slideout-panel-dialog-parent"
        fullScreen={isMobileTablet}
        transitionDuration={400}
        TransitionComponent={TransitionForSlideoutPanel}
        PaperProps={{
          ["data-testid"]: "slideout-panel-dialog-paper",
          sx: {
            height: `calc(100vh - ${dialogStyleTop})`,
            maxHeight: `calc(100vh - ${dialogStyleTop})`,
            position: "absolute",
            right: 0,
            top: dialogStyleTop,
            margin: 0,
            maxWidth: isMobileTablet ? "none" : width,
            width: isMobileTablet ? "none" : width,
            padding: isMobile ? "32px 32px 80px 32px" : 4 // because of mobile bottom bar, when mobile, padding-bottom is added
          }
        }}
      >
        {/* dialog header */}
        <DialogTitle data-testid="slideout-panel-dialog-header">
          <Grid
            container
            sx={{ alignItems: "center" }}
            data-testid="slideout-panel-dialog-header-container"
          >
            <Grid
              item
              xs={9}
              sx={{ display: "flex", alignItems: "center" }}
              data-testid="slideout-panel-dialog-header-title"
            >
              <Typography variant={"h5"} fontWeight={600}>
                {dialogTitle}
              </Typography>
            </Grid>

            {/* close the dialog */}
            {showCloseButton && (
              <Grid item xs>
                <IconButton
                  data-testid="close-slideout-panel-btn"
                  onClick={onClose}
                  sx={{ float: "right" }}
                >
                  <Close />
                </IconButton>
              </Grid>
            )}
          </Grid>

          {/* show details toggle */}
          {showDetailToggle && (
            <Grid sx={{ mt: 1 }} data-testid="slideout-panel-dialog-header-detail-toggle-item">
              {detailGroupFilterToggle}
            </Grid>
          )}
        </DialogTitle>

        {/* dialog contents */}
        <DialogContent data-testid="slideout-panel-dialog-contents" sx={{ padding: 3 }}>
          {children}
        </DialogContent>

        {/* dialog actions */}
        <DialogActions data-testid="slideout-panel-dialog-action">
          {secondaryButtonAction && (
            <Button
              color={"secondary"}
              data-testid={`${secondaryButtonTitle}-btn`}
              onClick={handleSecondaryButtonAction}
              startIcon={<Replay sx={{ transform: "rotate(270deg)" }} />}
            >
              {secondaryButtonTitle}
            </Button>
          )}
          {primaryButtonAction && (
            <Button
              color={"primary"}
              data-testid={`${primaryButtonTitle}-btn`}
              onClick={handlePrimaryButtonAction}
            >
              {primaryButtonTitle}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
);
