import { BookingInventoryCharacteristics } from "../../../features/Booking-Form/typings/booking-inventory-characteristics.types";
import { useRemoteQuery } from "../useRemote/useRemoteQuery";

export function useRemoteFetchBookingInventoryCharacteristics(
  type: "place" | "zone",
  caseDTO: { inventoryIds: number[]; start: string; end: string },
  enabled: boolean
) {
  return useRemoteQuery<BookingInventoryCharacteristics>({
    queryKey: ["booking-inventory-characteristics"],
    url: `inventory-characteristics/${type}`,
    params: caseDTO,
    enabled
  });
}
