import { Divider, Grid } from "@mui/material";
import { Topic } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ScheduleContentList } from "./ScheduleContentList.component";
import { BookingScheduleInterface } from "../../../../features/Booking-Form/typings/booking.types";
import { ScheduleEventBookingSubject } from "../../schedule-event-popover-booking-subject";
import { UserInformation } from "../../../../features/Login/typings/login.types";
import { BookingInventoryCharacteristics } from "../../../../features/Booking-Form/typings/booking-inventory-characteristics.types";
import { ShowMoreContentIcon } from "../../../FacilityManager/Components/Views/SelectFloorPlanView/SelectionPrompt/partial/ShowMoreContainerIcon.component";

type P = {
  userInformation: UserInformation;
  schedule: BookingScheduleInterface;
  bookingInventoryCharacteristics: BookingInventoryCharacteristics | undefined;
  isEditable: boolean;
  showMoreContent: boolean;
  isShowCategory: boolean;
  contentChildrenStyle: {
    flexWrap: string;
    overflowY: string;
    maxHeight: string;
  };
  setShowMoreContent: (showMore: boolean) => void;
  handleEditSubject: (
    val: string,
    schedule: BookingScheduleInterface
  ) => Promise<string | number | void>;
};

export const ScheduleSelectionConferenceList: React.FC<P> = ({
  userInformation,
  schedule,
  bookingInventoryCharacteristics,
  isEditable,
  showMoreContent,
  isShowCategory,
  contentChildrenStyle,
  setShowMoreContent,
  handleEditSubject
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* display subject */}
      {schedule.subject && (
        <ScheduleContentList
          contentIcon={<Topic fontSize="small" />}
          contentTitle={t("_ScheduleSelectConferenceSubject")}
          contents={[]}
          contentChildren={
            <ScheduleEventBookingSubject
              subject={schedule.subject}
              isEditable={schedule.userBookedFor === userInformation.sub && isEditable}
              schedule={schedule}
              onEditSubject={val => handleEditSubject(val, schedule)}
            />
          }
        />
      )}

      {/* display Zone Category */}
      {isShowCategory && (
        <ScheduleContentList
          contentIcon={
            <ShowMoreContentIcon
              type={"Category"}
              showMoreContent={showMoreContent}
              setShowMoreContent={setShowMoreContent}
            />
          }
          contentTitle={t("Zone Category")}
          contents={[]}
          contentChildren={
            <Grid container rowGap={1} flexDirection={"column"} sx={{ ...contentChildrenStyle }}>
              {bookingInventoryCharacteristics?.assignedCategories.map((cat, index) => {
                const length = bookingInventoryCharacteristics?.assignedCategories.length;
                return (
                  <div key={cat.id}>
                    <Grid item pb={1}>
                      {cat.name}
                    </Grid>
                    {showMoreContent && (
                      <>
                        <Grid item pl={2} pb={1}>
                          {cat.description}
                        </Grid>
                        {index !== length - 1 && <Divider />}
                      </>
                    )}
                  </div>
                );
              })}
            </Grid>
          }
        />
      )}
    </>
  );
};
