import { useRemoteQuery } from "../useRemote/useRemoteQuery";
import { CapacityPerformance } from "../../../features/Reports/typings/reports.types";
import { BookingType } from "../../../features/Booking-Form/typings/booking-inputs";
import { SelectedZoneOrWorkplaceType } from "../../../features/Reports/slices/report.slice";
import { Weekdays } from "../../../components/Pickers/WeekdayPicker/weekday-picker.component";

type FetchReportParams = {
  userId: string;
  calculateWithoutCosts: boolean;
  calcPerDay: boolean;
  calcPerWeekday: boolean;
  calcPerWorkplace: boolean;
  calcPerHour: boolean;
  reportType: string;
  start: string;
  end: string;
  "filter[]": Array<number>;
  filterType: "location" | "floor" | SelectedZoneOrWorkplaceType;
  companyId?: number | null;
  bookingType: BookingType | null;
  weekdays?: Weekdays;
  departmentIds?: string[];
};

export function useRemoteFetchFloorReport(filters: FetchReportParams, auto: boolean) {
  const {
    userId,
    calculateWithoutCosts,
    calcPerDay,
    calcPerWeekday,
    calcPerWorkplace,
    calcPerHour,
    reportType,
    start,
    end,
    "filter[]": filter,
    filterType,
    companyId,
    bookingType,
    weekdays,
    departmentIds
  } = filters;

  return useRemoteQuery<CapacityPerformance>({
    queryKey: [
      "getCapacityManagerReport",
      userId,
      calculateWithoutCosts,
      calcPerDay,
      calcPerWeekday,
      calcPerWorkplace,
      calcPerHour,
      reportType,
      start,
      end,
      filter,
      filterType,
      companyId,
      bookingType,
      weekdays,
      departmentIds
    ],
    url: "/report/capacity-report",
    params: filters,
    enabled: filter.every((id: number) => Number.isInteger(id)) && filter.length > 0 && auto
  });
}
