import React, { Fragment, useEffect, useState } from "react";
import { Autocomplete, Button, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { UserOrEmailMapping } from "../../features/Admin/typings/admin.types";
import { setInputs } from "../../features/Booking-Form/slices/booking.slice";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";
import {
  BR,
  Company,
  RestrictionZone,
  UserZoneBookingObject
} from "../../features/Booking-Form/typings/booking.types";
import { PricingModel } from "../../features/Login/typings/login.types";
import { useRemoteFetchUserInfoForAllUsers } from "../../hooks/Remote/User/UserInfo/useRemoteFetchUserInfoForAllUsers";
import { getOptionLabelOfUserOrEmailMapping } from "../../utils/type/type.utils";
import FormDialog, { MS } from "../Dialog/form-dialog.component";
import ConfirmZoneRestrictionInputs from "../Dialog/partials/inputs-confirm-restriction.component";
import RestrictionPicker from "../Pickers/restriction-picker.component";
import { InfoIconWithTooltip } from "../Title/InfoIcon";
import { handleActivityBasedRestriction, handleDialogRestriction } from "./utils/booking.utils";
import { checkActivityBased, handleSelectionZoneRestriction } from "./functions/form.functions";
import MsTeamsCallSwitch from "./msTeams-call-switch.component";
import {
  filterParticipantsOpt,
  handleClearRequiredOpt,
  handleSelectOptionalOpt,
  handleSelectOrRemoveRequiredOpt,
  isOptionEqualToVal,
  mergeRequiredAndOptionalUsers,
  renderParticiapantTags
} from "./functions/form-zone-restriction.functions";

/**
 * @description Component which displays the restriction picker and lets the user to fill the zone access with colleagues from the selected restriction object.
 * @version 0.1.0
 */

type P = {
  nextStep: () => void;
  title: string;
  adjustedData: any;
};

const FormZoneRestriction: React.FC<P> = ({ nextStep, adjustedData }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const { inputs } = useSelector((state: RootState) => state.booking);
  const {
    userInformation: {
      sub,
      pricingModels,
      msGraphEnabled,
      company: {
        meta: { calendarSyncPossible }
      }
    }
  } = useSelector((state: RootState) => state.login);

  const [users, setUsers] = useState<UserOrEmailMapping[]>([]);
  const [teamRestrictions, setTeamRestrictions] = useState<RestrictionZone>({
    company: inputs.restrictionObject ? inputs.restrictionObject.company : ({} as Company),
    object: inputs.restrictionObject
      ? inputs.restrictionObject.object
      : ({} as UserZoneBookingObject)
  });

  const [conflicts, setConflicts] = useState<BR[]>([]);
  const [modal, setModal] = useState<MS<BR[]>>({
    isOpen: false,
    data: undefined
  });

  const [requiredUsers, setRequiredUsers] = useState<UserOrEmailMapping[]>([]);
  const [optionalUsers, setOptionalUsers] = useState<UserOrEmailMapping[]>([]);
  const [optionalUsersOptions, setOptionalUsersOptions] = useState<UserOrEmailMapping[]>([]);
  const [showOptionalParticipants, setShowOptionalParticipants] = useState(false);

  const { data: remoteUsers, isFetching: isRemoteUsersFetching } =
    useRemoteFetchUserInfoForAllUsers();

  const handleOptionalClick = () => {
    setShowOptionalParticipants(!showOptionalParticipants);
    if (!showOptionalParticipants) {
      // Filter out users that are already in requiredUsers
      const unselectedOptionalUsers = users.filter(
        user => !requiredUsers.some(requiredUser => requiredUser.userId === user.userId)
      );
      setOptionalUsersOptions(unselectedOptionalUsers);
    }
  };

  useEffect(() => {
    if (inputs.zoneAccess && inputs.zoneAccess.length > 0) {
      const nonSelectedRequiredUsers = optionalUsersOptions.filter(user => {
        return !requiredUsers.some(requiredUser => requiredUser.userId === user.userId);
      });
      setOptionalUsersOptions(nonSelectedRequiredUsers);
    }
  }, [inputs.zoneAccess, requiredUsers, optionalUsers]);

  useEffect(() => {
    checkActivityBased(inputs.activityBasedBooking, nextStep);
  }, [inputs.activityBasedBooking, nextStep]);

  useEffect(() => {
    if (remoteUsers)
      handleActivityBasedRestriction(inputs, dispatch, setUsers, users, sub, remoteUsers);
  }, [remoteUsers, isRemoteUsersFetching]);

  useEffect(() => {
    if (adjustedData) {
      setConflicts(adjustedData);
      setModal({ isOpen: true, data: [] });
    }
  }, [adjustedData, inputs.restrictionObject?.object?.type, pricingModels]);

  useEffect(() => {
    if (teamRestrictions.object)
      dispatch(
        setInputs({
          restrictionObject: {
            company: teamRestrictions.company,
            object: teamRestrictions.object
          }
        })
      );
  }, [dispatch, teamRestrictions.company, teamRestrictions.object]);

  return (
    <Fragment>
      <Grid container direction={"column"} alignItems={"flex-start"} justifyContent={"flex-start"}>
        {inputs.mode === BookingType.PLACEZONE &&
          pricingModels.includes(PricingModel.ENTERPRISE) && (
            <>
              <Grid container direction={"row"} alignItems={"center"}>
                <Typography variant={"h5"}>{t("Set your zone restrictions")}</Typography>
                <InfoIconWithTooltip
                  tooltipText={t("Choose a suitable company unit for cost calculation")}
                />
              </Grid>
              <Grid
                sx={{ marginTop: 1 }}
                container
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <RestrictionPicker
                  userId={sub}
                  mode={true}
                  selection={teamRestrictions}
                  setSelection={setTeamRestrictions}
                  zoneBookingSelectionDto={handleSelectionZoneRestriction(inputs)}
                />
              </Grid>
              {!teamRestrictions.object.zoneBookingObject && (
                <Typography variant={"body2"} color={"error"}>
                  {t("Please select only one project, workorder or costcenter")}
                </Typography>
              )}
            </>
          )}
      </Grid>
      {inputs.mode === "conferencezone" && (
        <Grid
          container
          direction={"row"}
          justifyContent={"start"}
          alignItems={"center"}
          sx={{ marginBottom: 2, marginTop: 3, columnGap: 4 }}
        >
          <Grid item>
            <TextField
              margin="dense"
              id="name"
              sx={{ minWidth: 300, marginBottom: "10px" }}
              variant="outlined"
              label={t("Conference Subject")}
              type="text"
              placeholder={t("Add Subject")}
              data-testid="booking-conference-subject-textfield"
              value={inputs.subject || ""}
              onChange={(event: any) => dispatch(setInputs({ subject: event.target.value }))}
              error={!inputs.subject}
              helperText={!inputs.subject ? t("Subject is required") : ""}
            />
          </Grid>

          {/* when ms teams integration is set up, it shows the button */}
          {msGraphEnabled && calendarSyncPossible && (
            <Grid item sx={{ pl: "10px" }}>
              <MsTeamsCallSwitch enableEdit />
            </Grid>
          )}
        </Grid>
      )}
      <Grid container direction={"row"} alignItems={"center"}>
        <Typography variant={"h5"}>
          {inputs.mode === BookingType.PLACEZONE
            ? t("Who should have access to your zone?")
            : t("Who do you want to invite?")}
        </Typography>
        <InfoIconWithTooltip
          tooltipText={
            inputs.mode === BookingType.PLACEZONE
              ? t("_chosenColleagueAccessZone")
              : t("_chosenColleagueNoAccessZone")
          }
        />
      </Grid>
      <Grid
        sx={{ marginTop: 2, display: "flex" }}
        container
        direction={"row"}
        alignItems={"center"}
        justifyContent="space-between"
      >
        <Autocomplete
          multiple
          disableCloseOnSelect
          id="tags-outlined9"
          data-testid={"autocomplete-required"}
          options={users}
          freeSolo
          filterOptions={filterParticipantsOpt}
          handleHomeEndKeys
          filterSelectedOptions
          style={{ flex: 1 }}
          isOptionEqualToValue={isOptionEqualToVal}
          getOptionLabel={getOptionLabelOfUserOrEmailMapping}
          onChange={(event, values, reason, details) => {
            // if selected user is in optional selection, remove it from there and keep it in required only
            const valOpt = values as UserOrEmailMapping[];
            const selectedOpt = details?.option as UserOrEmailMapping;

            switch (reason) {
              // remove createOption to avoid type confusion
              case "selectOption":
              case "removeOption": {
                handleSelectOrRemoveRequiredOpt({
                  state: { valOpt, reason, details, optionalUsers, selectedOpt },
                  action: {
                    setRequiredUsers,
                    setOptionalUsers,
                    dispatch,
                    setInputs,
                    setOptionalUsersOptions,
                    enqueueSnackbar
                  }
                });
                return;
              }
              case "clear": {
                handleClearRequiredOpt({
                  state: { valOpt, optionalUsers, requiredUsers },
                  action: { setRequiredUsers, dispatch, setInputs, setOptionalUsersOptions }
                });
                return;
              }
            }
          }}
          value={requiredUsers}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              placeholder={
                showOptionalParticipants
                  ? t("Add required participants")
                  : t("Add participants or email")
              }
            />
          )}
          renderTags={renderParticiapantTags}
        />
        <Button
          onClick={handleOptionalClick}
          data-testid="show-optional-btn"
          variant={"contained"}
          color="primary"
          sx={{ marginLeft: 2, display: showOptionalParticipants ? "none" : "block" }}
        >
          {t("Optional")}
        </Button>
      </Grid>

      {showOptionalParticipants && (
        <Grid
          sx={{
            marginTop: 1
          }}
          container
          direction={"row"}
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Autocomplete
            multiple
            disableCloseOnSelect
            id="tags-outlined-optional"
            data-testid={"autocomplete-optional"}
            options={optionalUsersOptions}
            filterSelectedOptions
            freeSolo
            filterOptions={filterParticipantsOpt}
            handleHomeEndKeys
            style={{ width: "100%" }}
            isOptionEqualToValue={isOptionEqualToVal}
            getOptionLabel={getOptionLabelOfUserOrEmailMapping}
            renderTags={renderParticiapantTags}
            onChange={(event, values, reason, details) => {
              const optionalVal = values as UserOrEmailMapping[];

              switch (reason) {
                // remove createOption to avoid type confusion
                case "selectOption":
                case "removeOption": {
                  handleSelectOptionalOpt({
                    state: { reason, details, optionalVal, requiredUsers },
                    action: { setOptionalUsers, dispatch, setInputs, enqueueSnackbar }
                  });
                  return;
                }
                case "clear": {
                  setOptionalUsers(values as UserOrEmailMapping[]);
                  const updatedZoneAccess = mergeRequiredAndOptionalUsers(
                    requiredUsers,
                    optionalVal
                  );
                  dispatch(setInputs({ zoneAccess: updatedZoneAccess }));
                  return;
                }
              }
            }}
            value={optionalUsers}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                placeholder={t("Add optional participants or email")}
              />
            )}
          />
        </Grid>
      )}
      <Grid
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ marginTop: 3 }}
        data-testid="conferencezone-desc-field-container"
      >
        <TextField
          margin="dense"
          id="description"
          label={t("Description")}
          placeholder={t("Add a description and documents")}
          type="text"
          multiline
          inputProps={{ style: { height: !inputs.subject ? "38vh" : "42vh" } }}
          fullWidth
          variant="outlined"
          value={inputs.description || ""}
          onChange={(event: any) => dispatch(setInputs({ description: event.target.value }))}
        />
      </Grid>
      <FormDialog
        title={t("Confirm Multiple Zone Bookings")}
        description={t("_bookedMultipleOverlappingZones")}
        primaryButtonLabel={t("Confirm")}
        isOpen={modal.isOpen}
        handleCancel={() => setModal({ isOpen: false, data: [] })}
        handleOk={() => {
          handleDialogRestriction(
            modal,
            setModal,
            dispatch,
            inputs,
            teamRestrictions,
            nextStep,
            pricingModels
          );
        }}
      >
        <ConfirmZoneRestrictionInputs
          modalState={modal}
          setModalState={setModal}
          existingBookings={conflicts}
        />
      </FormDialog>
    </Fragment>
  );
};

export default FormZoneRestriction;
