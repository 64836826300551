import { FormHelperText, Grid, IconButton, TextFieldProps, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { DropzoneAreaBase, DropzoneAreaBaseProps, FileObject } from "react-mui-dropzone";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

type P = {
  files: FileObject[];
  /** Current uploaded image */
  current?: string;

  /** Action when File is added (through drop or click) */
  onAdd?(newFiles: FileObject[]): void;
  /** Action when File is deleted */
  onDelete?(deletedFileObject: FileObject, index: number): void;

  title?: string;
  preview?: boolean;

  /** Overwrite any props of dropzone component (except onAdd, onDelete and fileObjects) */
  dropzoneProps?: Omit<DropzoneAreaBaseProps, "onAdd" | "onDelete" | "fileObjects">;
  handleClose?: () => void;
} & Pick<TextFieldProps, "error" | "helperText">;

/**
 * Controlled Dropzone for pictures with customized snackbar notifications.
 * @description Notifications are shown in main app snackbar. Shows current file on server in preview.
 */
export const PictureDropzone: React.FC<P> = ({
  files,
  onAdd,
  onDelete,
  dropzoneProps,
  title = i18n.t("Upload an image"),
  current,
  error,
  helperText,
  preview = true,
  handleClose
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const rejectFile = (e: File[]) => {
    if (e[0].size > 2000000) {
      enqueueSnackbar(t("The file can not be bigger than 2MB"), { variant: "error" });
    } else {
      enqueueSnackbar(t("This is not a valid file"), { variant: "error" });
    }
  };

  return (
    <Grid
      data-testid="picture-dropzone-parent"
      container
      direction="column"
      sx={{ marginBottom: 2 }}
    >
      <Grid container data-testid="picture-dropzone-header">
        <Grid sx={{ textAlign: "left", alignSelf: "center" }} item xs={11}>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        {handleClose && (
          <Grid sx={{ textAlign: "right" }} item xs={1}>
            <IconButton data-testid="btn-close" onClick={handleClose} size="large">
              <Close />
            </IconButton>
          </Grid>
        )}
      </Grid>

      <Grid item data-testid="picture-dropzone-base">
        <DropzoneAreaBase
          inputProps={{ "aria-label": "inputDrop" }}
          acceptedFiles={[".jpeg", ".png", ".jpg"]}
          dropzoneText={t("Drop an image or click to upload")}
          maxFileSize={2000000}
          filesLimit={1}
          onDropRejected={e => rejectFile(e)}
          showAlerts={false}
          showPreviewsInDropzone
          previewGridProps={{ container: { spacing: 1, direction: "row" } }}
          {...dropzoneProps}
          fileObjects={files}
          onAdd={onAdd}
          onDelete={onDelete}
        />
      </Grid>

      {error && helperText && <FormHelperText error>{helperText}</FormHelperText>}
      {current && current !== "temp" && preview && (
        <Grid item>
          <Typography sx={{ padding: "10px 0" }}>{t("Current :")}</Typography>
          <img src={current} height={110} alt="" />
        </Grid>
      )}
    </Grid>
  );
};
