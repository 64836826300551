import { BookingInventoryCharacteristics } from "../../../../../features/Booking-Form/typings/booking-inventory-characteristics.types";

export const showBookingInventoryCharacteristics = (
  bookingInventoryCharacteristics: BookingInventoryCharacteristics | undefined,
  isBICharacteristicsFetching: boolean,
  useShowEquipmentInSlideOutPanel: boolean
) => {
  const isShowCategory =
    bookingInventoryCharacteristics?.assignedCategories &&
    bookingInventoryCharacteristics.assignedCategories.length > 0 &&
    !isBICharacteristicsFetching;

  const isShowEquipCategories =
    bookingInventoryCharacteristics?.totalEquipmentCategories &&
    bookingInventoryCharacteristics.totalEquipmentCategories.length > 0 &&
    !isBICharacteristicsFetching;

  const isShowEquipments =
    bookingInventoryCharacteristics?.assignedEquipments &&
    bookingInventoryCharacteristics?.assignedEquipments.length > 0 &&
    !isBICharacteristicsFetching &&
    useShowEquipmentInSlideOutPanel;

  const isShowBookProps =
    bookingInventoryCharacteristics?.assignedProperties &&
    bookingInventoryCharacteristics.assignedProperties.length > 0 &&
    !isBICharacteristicsFetching;

  return { isShowCategory, isShowEquipCategories, isShowEquipments, isShowBookProps };
};
