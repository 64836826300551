import { Box, Button } from "@mui/material";
import { Datepicker } from "@mobiscroll/react";
import { DateTime } from "luxon";
import { handleStartDateChange } from "../menu-timeframes.functions";
import { useTranslation } from "react-i18next";
import useTimeFormat from "../../../../hooks/useTimeFormat/useTimeFormat";
import { Dispatch, SetStateAction } from "react";
import { Timeframe } from "../../typings/shared/timeframe";

interface Props {
  startDate: DateTime | null;
  setStartDate: Dispatch<SetStateAction<DateTime | null>>;
  clickNextCreate: (startDay: string) => void;
  floorTimeframes: Timeframe[];
}

/** @deprecated */
export function NewTimeframeCreator({
  startDate,
  setStartDate,
  clickNextCreate,
  floorTimeframes
}: Props) {
  const { t } = useTranslation();
  const { dateFormat } = useTimeFormat();

  const clearTimeframe = (): void => {
    setStartDate(null);
  };

  return (
    <>
      <Box data-testid="new-time-creator-parent-box" sx={{ display: "flex" }}>
        <Box sx={{ margin: " 0 10px" }}>
          <Datepicker
            data-testid={"new-timeframe-input"}
            min={initialStartDate(floorTimeframes, dateFormat)}
            id="date-picker-inline"
            label={t("Start date")}
            value={startDate?.toFormat(dateFormat) ?? ""}
            onChange={ev => handleStartDateChange(ev.value as Date, setStartDate)}
            dateFormat={dateFormat.toUpperCase()}
          />
        </Box>
      </Box>

      <Box>
        <Button
          data-testid="clear-btn"
          sx={{
            margin: "40px 10px 0 10px !important",
            textTransform: "none",
            fontSize: "1.1rem"
          }}
          color="secondary"
          onClick={() => clearTimeframe()}
        >
          {t("Clear")}
        </Button>
        <Button
          data-testid="next-btn"
          sx={{
            margin: "40px 10px 0 10px !important",
            textTransform: "none",
            fontSize: "1.1rem"
          }}
          color="primary"
          onClick={() => {
            // fetch new when editing a time
            const startDay = startDate ? startDate.toFormat("yyyy-MM-dd") : "";
            clickNextCreate(startDay);
          }}
          disabled={!startDate || startDate.valueOf() < new Date().valueOf()}
        >
          {t("Next")}
        </Button>
      </Box>
    </>
  );
}

export function initialStartDate(floorTimeframes: Timeframe[], dateFormat: string) {
  const nowStartDate = DateTime.now().plus({ days: 1 }).set({ hour: 0, minute: 1 });

  // if existing timeframe is not, return now + 1
  if (!floorTimeframes.length) return nowStartDate.toFormat(dateFormat);

  // compare existing date and now date
  const existingStartDate = DateTime.fromISO(floorTimeframes[floorTimeframes.length - 1].start);
  const compareNowAndExisting = nowStartDate.valueOf() > existingStartDate.valueOf();

  // if existing is in the past :: now > existing >> now + 1
  if (compareNowAndExisting) return nowStartDate.toFormat(dateFormat);

  // if existing is in the future :: now =< existing >> existing + 1
  return existingStartDate.plus({ days: 1 }).set({ hour: 0, minute: 1 }).toFormat(dateFormat);
}
