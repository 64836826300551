import { UserOrEmailMapping } from "../../../features/Admin/typings/admin.types";

export function genZoneAccessWithExternal(zoneAccess: UserOrEmailMapping[]) {
  return zoneAccess.map(guest => {
    if (guest.external) {
      return {
        userId: guest.userId ?? "",
        isRequired: guest.isRequired ?? true,
        external: guest.external,
        email: guest.email ?? ""
      };
    } else {
      return {
        userId: guest.userId ?? "",
        isRequired: guest.isRequired ?? true,
        external: guest.external ?? false
      };
    }
  });
}
