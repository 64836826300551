import { MRT_Cell } from "material-react-table";
import { Box, Button, IconButton, Theme, Typography } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { ProviderContext } from "notistack";
import { t } from "i18next";
import { DateTime } from "luxon";
import { BookingRequestsActiveRow, BookingRequestsStatus } from "./typings/booking-requests.types";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";
import { namePerType } from "../FacilityManager/Components/Toolbars/InfoSidebar/AssignBox/AssignBox";
import { getWorkplacesOrZones } from "../Schedule/schedule.functions";
import { typeIdPerBookingMode } from "../BookingForm/functions/form.functions";
import { UserInformation } from "../../features/Login/typings/login.types";
import { Colleague } from "../../features/Connections/types/Colleague.type";
import * as _ from "lodash";

export const renderStatusData = (
  row: BookingRequestsActiveRow,
  dateFormat: string,
  timeFormat: string
) => {
  const operatorText = `${t("_bookingRequestsByOperator")} ${row.statusData.operatorName}`;
  const changedDateText = DateTime.fromISO(row.statusData.statusChangedDate).toFormat(dateFormat);
  const changedTimeText = DateTime.fromISO(row.statusData.statusChangedDate).toFormat(timeFormat);

  return (
    <Box data-testid="render-status-data-box">
      <Typography variant={"subtitle1"}>{t(row.statusData.status)}</Typography>
      <Typography variant="body2">{operatorText}</Typography>
      <Typography variant="body2">{changedDateText}</Typography>
      <Typography variant="body2">{changedTimeText}</Typography>
    </Box>
  );
};

export const renderShowBooking = (
  row: BookingRequestsActiveRow,
  setSelectedInventoryId: (selected: number) => void
) => {
  return (
    <Button
      data-testid="booking-requests-show-booking-btn"
      variant={"text"}
      onClick={() => setSelectedInventoryId(row.inventoryId)}
    >
      <Typography variant={"subtitle2"}>{t("_bookingRequestsByOperator_showBooking")}</Typography>
    </Button>
  );
};

export const renderExpandButton = ({
  params,
  users,
  userInformation,
  theme,
  enqueueSnackbar,
  setShowBookingDetail
}: {
  params: BookingRequestsActiveRow;
  users: Colleague[] | undefined;
  userInformation: UserInformation;
  theme: Theme;
  enqueueSnackbar: ProviderContext["enqueueSnackbar"];
  setShowBookingDetail: (value: { schedule: BookingScheduleInterface; open: boolean }) => void;
}) => {
  const returnErr = () =>
    enqueueSnackbar(t("There was an error retrieving booking information"), { variant: "error" });

  const bookUser = users?.filter(
    user => `${user.firstName} ${user.surname}` === params.userInfo.bookingUserName
  ) as any;

  const handleClick = async () => {
    await getWorkplacesOrZones(
      params.requestedTime.bookingStart,
      params.requestedTime.bookingEnd,
      typeIdPerBookingMode(params.bookingType)[0],
      bookUser,
      userInformation,
      undefined,
      [params.bookingType]
    )
      .then(res => {
        setShowBookingDetail({
          schedule: res.data[0],
          open: true
        });
      })
      .catch(() => returnErr());
  };

  // when it's rejected the booking data is remained in the archive so it's not possible to show details
  const showButton = params.statusData.status !== BookingRequestsStatus.REJECTED;

  return (
    <>
      {showButton && (
        <IconButton onClick={handleClick} data-testid="selected-request-expand-btn">
          <MoreVert sx={{ fill: theme.palette.text.primary }} />
        </IconButton>
      )}
    </>
  );
};

export const renderBookingType = (row: BookingRequestsActiveRow) => {
  return <Typography>{t(namePerType(row.bookingType, t))}</Typography>;
};

export const renderRequestedDate = (
  cell: MRT_Cell<BookingRequestsActiveRow, unknown>,
  dateFormat: string
) => {
  // sometimes cell value is jsDate or iso string
  const jsDate = DateTime.fromJSDate(cell.getValue() as Date);
  const isoDate = DateTime.fromISO(cell.getValue() as string);
  let cellDate: DateTime = DateTime.now();
  if (jsDate.isValid) cellDate = jsDate;
  else if (isoDate.isValid) cellDate = isoDate;

  return <Typography>{cellDate.toFormat(dateFormat)}</Typography>;
};

export const renderRequestedTime = (row: BookingRequestsActiveRow, timeFormat: string) => {
  const startTime = DateTime.fromISO(row.requestedTime.bookingStart).toFormat(timeFormat);
  const endTime = DateTime.fromISO(row.requestedTime.bookingEnd).toFormat(timeFormat);

  return (
    <Typography>
      {startTime} - {endTime}
    </Typography>
  );
};

export const renderBookingObject = (row: BookingRequestsActiveRow) => {
  const objType = row.bookingType === BookingType.CONFERENCEZONE ? t("Zone") : t("Place");
  return (
    <Typography>
      {`${objType} ID `}
      {row.bookingObject}
    </Typography>
  );
};

export const renderUserInfo = (row: BookingRequestsActiveRow) => {
  return (
    <Box>
      <Typography>{row.userInfo.bookingUserName}</Typography>
      <Typography>{row.userInfo.bookingUserEmail}</Typography>
    </Box>
  );
};

export const renderLocation = (row: BookingRequestsActiveRow) => {
  const add3 = row.locationData.address3 === "not provided" ? "" : row.locationData.address3;
  const addresses = `${row.locationData.address1} ${row.locationData.address2 ?? ""} ${add3 ?? ""}`;
  return (
    <Box>
      {/* floor name */}
      <Typography>{row.locationData.name}</Typography>
      {/* address */}
      <Typography sx={{ textWrap: "balance" }}>{addresses}</Typography>
      <Typography>
        {row.locationData.postalCode} {row.locationData.city}
      </Typography>
    </Box>
  );
};

export const generateInventoryIdPerType = (params: BookingRequestsActiveRow) => {
  const { placeInventoryId, zoneInventoryId } =
    params.bookingType === BookingType.CONFERENCEZONE ||
    params.bookingType === BookingType.PLACEZONE
      ? { placeInventoryId: null, zoneInventoryId: params.inventoryId }
      : { placeInventoryId: params.inventoryId, zoneInventoryId: null };

  return { placeInventoryId, zoneInventoryId };
};

export const statusArr = (data: BookingRequestsActiveRow[] | undefined) => {
  const arr = _.uniqBy(
    data?.map(d => d.statusData.status),
    el => el
  );
  return arr.map(d => ({ label: t(d), value: d }));
};

export const bookingTypeArr = (data: BookingRequestsActiveRow[] | undefined) => {
  const arr = _.uniqBy(
    data?.map(d => d.bookingType),
    el => el
  );
  return arr.map(d => ({ label: namePerType(d, t), value: d }));
};
