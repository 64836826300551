import { SelectedZoneOrWorkplace } from "../../../features/Reports/slices/report.slice";
import { CapacityObject, MapMarkerLocation } from "../../../features/Reports/typings/reports.types";
import { SingleSuperCluster } from "../useSupercluster";

export const latitudeLocation = (mapMarkerLocations: MapMarkerLocation[] | undefined) =>
  mapMarkerLocations && mapMarkerLocations[0]
    ? mapMarkerLocations[0]?.geoInformation[0]?.latitude
    : 50;

export const longitudeLocation = (mapMarkerLocations: MapMarkerLocation[] | undefined) =>
  mapMarkerLocations && mapMarkerLocations[0]
    ? mapMarkerLocations[0]?.geoInformation[0]?.longitude
    : 6;

export const zoomLocation = (mapMarkerLocations: MapMarkerLocation[] | undefined) =>
  mapMarkerLocations && mapMarkerLocations[0] ? (20 / mapMarkerLocations.length) * 0.8 : 5;

export const currentEntityData = (
  selectedZoneOrWorkplace: SelectedZoneOrWorkplace,
  capacitySelectedWorkplaceOrZone: CapacityObject | undefined,
  currentFloors: { id: number; open: boolean }[],
  capacitySelectedFloors?: CapacityObject[]
) =>
  selectedZoneOrWorkplace?.open
    ? [capacitySelectedWorkplaceOrZone]
    : capacitySelectedFloors?.filter(floor => {
        return currentFloors.some(activeFloor => activeFloor.id === floor.id);
      });

export function compareClustersCoordinates(superClusterClusters: SingleSuperCluster[]) {
  if (superClusterClusters.some(cluster => cluster.properties.cluster === false)) {
    const newClu = superClusterClusters.map((cluster, index) => {
      // skip the comparison for the first cluster(index = 0)
      if (!index) return cluster;

      const isSameLongitudeAsPreviousCluster =
        cluster.geometry.coordinates[0] === superClusterClusters[0].geometry.coordinates[0];
      const isSameLatitudeAsPreviousCluster =
        cluster.geometry.coordinates[1] === superClusterClusters[0].geometry.coordinates[1];

      if (isSameLongitudeAsPreviousCluster && isSameLatitudeAsPreviousCluster) {
        cluster.geometry.coordinates[0] = cluster.geometry.coordinates[0] + index * 0.00018;
      }
      return cluster;
    });
    return newClu;
  }
  return superClusterClusters;
}
