import { Box, Grid, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocationUsage } from "../../../features/Reports/typings/reports.types";

export type Props = {
  data: LocationUsage[];
  percent: boolean;
};

const MultiColorProgressBar = (props: Props) => {
  const { t } = useTranslation();

  // Use a single function to calculate width for both cases
  const calculateWidth = (percent: number) => {
    const minWidth = 3;
    const amountOfMinWidthDurations = props.data.filter(
      duration => duration.relativeDuration < 0.3
    ).length;

    if (percent < minWidth) {
      return minWidth;
    } else if (percent > 100 - amountOfMinWidthDurations) {
      return (
        percent - amountOfMinWidthDurations * (minWidth + amountOfMinWidthDurations * 2) - 0.7 + "%"
      );
    } else {
      return percent - 1 - 0.7 + "%";
    }
  };

  const getLocationBars = (item: LocationUsage, t: any, props: Props) => {
    const splitDurations = item.splitDurations;

    if (item.locationInventoryId === 0 && splitDurations) {
      const notBookedPercent = splitDurations.relativeNotBookedDuration
        ? splitDurations.relativeNotBookedDuration * 100
        : 0;
      const notCheckedInPercent = splitDurations.relativeNotCheckedInDuration
        ? splitDurations.relativeNotCheckedInDuration * 100
        : 0;

      const locationBars = [];

      if (notBookedPercent > 0) {
        locationBars.push(
          renderLocationBar("notBooked", item.locationName, notBookedPercent, t, props, item)
        );
      }

      if (notCheckedInPercent > 0) {
        locationBars.push(
          renderLocationBar("notCheckedIn", item.locationName, notCheckedInPercent, t, props, item)
        );
      }

      return locationBars;
    } else {
      const percent = item.relativeDuration * 100;
      return [renderLocationBar("default", item.locationName, percent, t, props, item)];
    }
  };

  const renderLocationBar = (
    type: string,
    locationName: string,
    percent: number,
    t: any,
    props: Props,
    item: LocationUsage
  ) => {
    const isNotCheckedIn = type === "notCheckedIn";
    let title;
    if (isNotCheckedIn) {
      title = t("NoCheckedInTimeLabel") + " " + (!props.percent ? percent.toFixed(0) + "%" : "");
    } else {
      title = t(locationName) + " " + (!props.percent ? percent.toFixed(0) + "%" : "");
    }
    const backgroundColor = type === "notCheckedIn" ? "#B92C5D" : item.locationColor;
    const key = type === "default" ? locationName : `${type}_${locationName}`;

    return (
      <Tooltip key={key} title={title}>
        <Box
          data-testid="multi-color-progress-bar"
          sx={{
            backgroundColor: backgroundColor,
            borderRadius: "5px",
            margin: "1px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: calculateWidth(percent),
            height: 15
          }}
        >
          {props.percent && percent > 30 && type === "default" ? t(locationName) : ""}
        </Box>
      </Tooltip>
    );
  };

  const bars = props.data.flatMap(item => getLocationBars(item, t, props));

  return (
    <Grid container justifyContent={"space-evenly"}>
      {bars.flat()}
    </Grid>
  );
};

export default MultiColorProgressBar;
